<template>
  <div class="case-music-list flex">
    <div class="music-item" v-for="(item, index) in data" :key="index">
      <div class="music-item-top border-bottom">
        <div class="w100 music-img-wrap ">
          <ng-image class="music-img" :src="item.cover_url"></ng-image>
          <div class="music-play-btn" @click="palyVideo(item)">
            <icon-font type="iconplay-fff" />
          </div>
          <div class="play-tip">
            可播放视频
          </div>
        </div>
        <div class="music-info">
          <a-popover placement="bottom">
            <template slot="content">
              <span class="bold ft16" style="color:#000">{{ item.title }}</span>
              <p style="width:400px"> {{item.desc}}</p>

            </template>
            <div class="music-type text-ellipsis ft14 font-bold">
              {{ item.title }}
             
            </div>
          </a-popover>

          <!-- <div>
            <span class="ft12 case-desc">{{item.desc}}</span>
          </div> -->
        </div>
      </div>
      <!-- <div class="music-item-bottom flex cen-space">
        <div class="flex cen-center ft16">
          <icon-font type="iconplay-fff" />
          <span class="music-name-author"> 苏丹｜爱的供养 </span>
        </div>
        <div class="right-case-music-item ft16">
          <icon-font type="iconangle-right" />
        </div>
      </div> -->
    </div>

    <videoDialog :videoUrl="currentUrl"></videoDialog>
  </div>
</template>
<script>
// import { Tag } from "ant-design-vue";
import { mapState, mapMutations } from "vuex";
import videoDialog from "@/components/dialog/video";
import { Popover } from "ant-design-vue";

export default {
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      currentUrl: "",
    };
  },
  components: {
    videoDialog,
    APopover: Popover,
  },
  computed: {
    ...mapState(["videoDialog"]),
  },
  created() {},

  methods: {
    ...mapMutations(["changeVideoDialog"]),
    palyVideo(item) {
      console.log("item1111: ", item);
      this.currentUrl = item.video_url;
      this.changeVideoDialog(true);
    },
    callback(key) {
      console.log(key);
    },
  },
};
</script>
<style lang="scss">
// mobile
@media screen and (max-width: $screen-width-md) {
  .case-music-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // margin-top: 3rem;
    .music-item {
      width: 14.4rem;
      // height: 310px;
      margin-right: 1rem;
      margin-bottom: 3.2rem;
      &:nth-child(2n) {
        margin-right: 0;
      }

      .music-item-top {
        padding-bottom: 0.8rem;
        .music-img-wrap {
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          .music-img {
            width: 14.4rem;
            // height: 310px;
          }
          .music-play-btn {
            border-radius: 50%;
            background-color: #fff;
            cursor: pointer;
            opacity: 0.8;
            width: 48px;
            height: 48px;
            font-size: 32px;
            color: #000;
            position: absolute;
            top: 50%;
            left: 50%;
            line-height: 48px;
            transform: translate(-50%, -50%);
            cursor: pointer;
            text-align: center;
            padding:2px 0 0 4px ;
          }
          .play-tip{
            position: absolute;
            top: 15px;
            right: 20px;
            height: 20px;
            background: #FF7B13;
            border-radius: 9px;
            opacity: 0.8;
            font-size: 12px;
            color: #fff;
            padding: 0 5px;
            line-height: 20px;
          }
        }
      }

      .music-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 1.2rem;

        .music-type {
          margin-bottom: 0.4rem;
          color: #000;
        }
        .case-desc {
          color: rgba(0, 0, 0, 0.55);
        }
      }
      .music-item-bottom {
        height: 2.2rem;
        margin-top: 0.8rem;
        .music-name-author {
          font-size: 1.4rem;
          display: inline-block;
          width: 94%;
          margin-left: 0.3rem;
          color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .right-case-music-item {
          color: #ccc;
          height: 2.2rem;
        }
      }
    }
  }
}
// pc
@media screen and (min-width: $screen-width-md) {
  .case-music-list {
    width: 100%;
   
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .music-item {
      width: 24%;
      // height: 310px;
    
      margin-right: 1.3%;

      &:nth-child(4n) {
        margin-right: 0;
      }

      .music-item-top {
        padding-bottom: 8px;
        .music-img-wrap {
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          .music-img {
            width: 100%;
            max-width: 310px;
            // height: 310px;
          }
          .music-play-btn {
            border-radius: 50%;
            background-color: #fff;
            cursor: pointer;
            opacity: 0.8;
            width: 48px;
            height: 48px;
            font-size: 32px;
            color: #000;
            position: absolute;
            top: 50%;
            left: 50%;
            line-height: 48px;
            transform: translate(-50%, -50%);
            cursor: pointer;
            text-align: center;
            padding:2px 0 0 4px ;
          }
          .play-tip{
            position: absolute;
            top: 15px;
            right: 20px;
            height: 20px;
            background: #FF7B13;
            border-radius: 9px;
            opacity: 0.8;
            font-size: 12px;
            color: #fff;
            padding: 0 5px;
            line-height: 20px;
          }
        }
      }

      .music-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 11px;
        .music-type {
          margin-bottom: 4px;
          color: #000;
          cursor: pointer;
        }
        .case-desc {
          color: rgba(0, 0, 0, 0.55);
        }
      }
      .music-item-bottom {
        margin-top: 8px;
        .music-name-author {
          margin-left: 3px;
          color: #000;
        }
        .right-case-music-item {
          color: #ccc;
        }
      }
    }
  }
}
</style>