<template>
  <div class="detail-main">
    <a-table
      class="empty-hover back-grey-gap"
      :dataSource="list"
      :columns="columns"
      :pagination="false"
      :showHeader="false"
    >
      <template v-slot:operation="text, item">
        <!-- 设为默认 -->
        <!-- <icon-font
          class="font-heading-2 m-right-base"
          type="iconedit"
          @click="onDefault(item)"
        ></icon-font>-->

        <icon-font
          class="font-heading-2 icon-font"
          type="iconedit"
          @click="handleItem($var.Handle.edit, item)"
        ></icon-font>
        <icon-font
          class="font-heading-2"
          type="icontrash"
          @click="handleItem($var.Handle.del, item)"
        ></icon-font>
      </template>
    </a-table>
  </div>
</template>
<script>
  import {Table} from 'ant-design-vue';
  import * as $col from '@/config/columns'

  const columns = [
    $col.commonKey('', 'name', {ellipsis: true}),
    $col.commonKey('', 'operation', {isSlot: true, width: '90px'})
  ]

  export default {
    data() {
      return {
        columns
      }
    },

    props: {
      list: Array
    },

    components: {
      ATable: Table,
    },
    methods: {
      handleItem(type, item) {
        let that = this;

        if (type === this.$var.Handle.del) {
          this.$confirm({
            width: '480px',
            title: this.$t('template.confirmDel', {name: this.$t('userSet.rpt_title')}),
            // content: '删除该发票抬头',
            onOk() {
              return new Promise(resolve => {
                that.onDel(item, resolve);
              });
            }
          });
        } else if (type === this.$var.Handle.edit) {
          this.$emit('onEdit', item);
        }
      },

      onDefault(item) {
        let that = this;

        this.$confirm({
          width: '480px',
          title: this.$t('userSet.rpt_confirmSetDefault'),
          // content: '删除该地址信息',
          onOk() {
            return new Promise(resolve => {
              that.setDefault(item, resolve);
            });
          }
        });
      },

      setDefault(item, next) {
        let params = {
          invoice_id: item.invoice_id
        };

        this.$axios.Recipt_setDefault(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(this.$t('userSet.rpt_setSuccess'));

            next();

            this.$emit('success', data.data);
          }
        })
      },

      onDel(item, next) {
        let params = {
          invoice_id: item.invoice_id
        };

        this.$axios.Recipt_del(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(this.$t('userSet.rpt_delSuccess'));

            next();

            this.$emit('success', data.data);
          }
        })
      },
    }
  }
</script>
<style lang="scss" scoped>
</style>