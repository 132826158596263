<template>
  <div class="list-header">
    <div class="top m-bottom-sm">
      <div class="left">
        <!-- 已支付 -->
        <template v-if="item.status == $val.UndoPayOrderType.payed">
          <icon-font class="font-color-success icon m-right-xs" type="iconcorrect"></icon-font>

          <div class="price m-right-sm">
            <b class="font-md">已支付</b>
            <b class="font-md">￥{{item.total_amount}}</b>
          </div>
        </template>
        <!-- 待支付 -->
        <template v-else-if="item.status == $val.UndoPayOrderType.wait">
          <icon-font class="font-color-primary icon m-right-xs" type="iconwait"></icon-font>

          <div class="price m-right-sm">
            <b class="font-md">待支付</b>
            <b class="font-md">￥{{item.total_amount}}</b>
          </div>
        </template>
        <!-- 支付中 -->
        <template v-else-if="item.status == $val.UndoPayOrderType.paying">
          <icon-font class="font-color-primary icon m-right-xs" type="iconpay-solid"></icon-font>

          <div class="price m-right-sm">
            <b class="font-md">支付中</b>
            <b class="font-md">￥{{item.total_amount}}</b>
          </div>
        </template>
        <!-- 未支付 已取消 -->
        <template v-else>
          <icon-font class="font-color-sub icon m-right-xs" type="iconwait"></icon-font>

          <div class="price m-right-sm">
            <b class="font-md">已取消</b>
            <b class="font-md">￥{{item.total_amount}}</b>
          </div>
        </template>
      </div>
      <div class="right">
        <template v-if="item.status !== $val.UndoPayOrderType.paying">
          <a-button class="p-empty m-right-sm" type="link" @click="$emit('lookDetail', item)">查看详情</a-button>
        </template>
        <template v-else>
          <router-link
            class="p-empty m-right-sm"
            :to="`/payresult/paying/${item.order_id}?order_type=${$val.orderType.music}`"
          >查看详情</router-link>
        </template>

        <template v-if="item.status === $val.UndoPayOrderType.wait">
          <a-button class="p-empty" type="link" @click="onCancelOrder(item)">取消支付</a-button>
        </template>
        <template v-else-if="item.status !== $val.UndoPayOrderType.paying">
          <a-button class="p-empty" type="link" @click="onDelOrder(item)">删除订单</a-button>
        </template>
      </div>
    </div>

    <div class="middle m-bottom-sm">
      <template v-if="item.status === $val.UndoPayOrderType.payed">
        <div class="flex">
          <div class="flex-1 btn-group-box">
            <template v-if="item.is_invoiced == $val.CommonCheck.correct">
              <a-button class="hidden-sm" type="link" @click="$emit('lookReceipt', item)">查看发票</a-button>
              <a-button
                class="hidden-md w-100"
                type="primary"
                @click="$emit('lookReceipt', item)"
              >查看发票</a-button>
            </template>
            <template v-else>
              <a-button class="w-100" type="primary" @click="goApplyReceipt(item)">申请发票</a-button>
            </template>
          </div>
          <div class="flex-1 btn-group-box">
            <template v-if="item.is_contracted == $val.CommonCheck.correct">
              <a-button class="hidden-sm" type="link" @click="$emit('lookContract', item)">查看合同</a-button>
              <a-button
                class="hidden-md w-100"
                type="primary"
                @click="$emit('lookContract', item)"
              >查看合同</a-button>
            </template>
            <template v-else>
              <a-button class="w-100" type="primary" @click="goApplyContract(item)">申请合同</a-button>
            </template>
          </div>
        </div>
      </template>
      <template v-else-if="item.status === $val.UndoPayOrderType.wait">
        <a-button
          class="w-100"
          type="primary"
          @click="$router.push(`/paymusic/${item.order_id}`)"
        >去支付</a-button>
      </template>
    </div>

    <div class="bottom">
      <div class="m-right-sm m-bottom-xs">
        <span class="m-right-xs">交易时间</span>
        <span>{{item.create_at}}</span>
      </div>

      <div class="order-num flex m-right-sm">
        <span class="title m-right-xs">订单编号</span>
        <span
          class="order-no cursor-pointer flex-1"
          @click="onCopyOrder(item.order_no)"
        >{{item.order_no}}</span>
        <icon-font type="iconcopy" @click="onCopyOrder(item.order_no)"></icon-font>
      </div>
    </div>
  </div>
</template>

<script>
  import OrderHeaderMixins from './_mixin';

  export default {
    data() {
      return {
      }
    },
    props: {
      item: Object
    },

    mixins: [OrderHeaderMixins],

    computed: {
      orderType() {
        return this.getOrderType(this.item);
      }
    },

    components: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .list-header {
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      justify-content: space-between;
    }

    .middle {
      .btn-group-box {
        &:first-child {
          padding-right: 10px;
        }

        &:last-child {
          padding-left: 10px;
        }
      }
    }

    .left {
      display: flex;
      align-items: center;

      .icon {
        font-size: 20px;
      }
    }

    .order-num {
      display: flex;

      > span {
        &:first-child,
        &:last-child {
          flex-shrink: 0;
        }

        &.order-no {
          flex-shrink: 1;
        }
      }
    }
  }
</style>
