<template>
  <a-select v-bind="$props" @change="triggerChange" :options="list" :loading="loading">
    <div slot="dropdownRender" slot-scope="menu">
      <template v-if="isAdd">
        <div
          class="font-color-primary"
          style="display: block; padding: 4px 8px; cursor: pointer;"
          @click="$emit('add')"
        >
          <icon-font class="m-right-xs inline-block" type="iconplus"></icon-font>
          <span>新增</span>
        </div>

        <a-divider style="margin: 4px 0;" />
      </template>

      <VNodes :vnodes="menu"></VNodes>
    </div>
  </a-select>
</template>

<script>
  import {Select} from 'ant-design-vue';

  let props = Object.assign({}, Select.props);

  delete props.open;
  delete props.loading;

  export default {
    data() {
      return {
        list: [],
        loading: false
      }
    },

    props: {
      ...props,
      isAdd: {
        type: Boolean,
        default: true
      }
    },

    components: {
      VNodes: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes,
      },
    },

    created() {
      this.getData();
    },

    methods: {
      getData() {
        this.loading = true;

        this.$axios.Recipt_list({}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let list = data.data ? data.data.list : [];

            this.list = list.map(item => ({...item, label: item.name, value: item.invoice_id}));
          }

          this.loading = false;
        })
      },

      triggerChange(changedValue) {
        this.$emit('change', changedValue);
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
