
<template>
  <div class="music-detail-box">
    <a-row class="music-detail-top" :gutter="{xl: 40, md: 20}">
      <a-col :xs="24" :sm="24" :md="8" :xl="6">
        <div class="image-box">
          <div class="img-all-box">
            <ng-image
              class="w-100 music-logo m-bottom-base"
              :src="supplierInfo.profile_photo_url"
              alt="thumbnail"
            ></ng-image>
          </div>
        </div>
      </a-col>

      <a-col :xs="24" :sm="24" :md="16" :xl="18">
        <b class="font-heading-1 m-bottom-sm block">{{$root.getShowName(supplierInfo)}}</b>

        <p>{{supplierInfo.address}}</p>

        <div class="m-bottom-lg">{{supplierInfo.desc}}</div>

        <!-- <a-space class="terrace-wrap">
          <svg class="iconfont">
            <use xlink:href="#iconheadset" />
          </svg>
          <svg class="iconfont">
            <use xlink:href="#iconheadset" />
          </svg>
          <svg class="iconfont">
            <use xlink:href="#iconheadset" />
          </svg>
          <svg class="iconfont">
            <use xlink:href="#iconheadset" />
          </svg>
        </a-space>-->
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import NgImage from '~/components/NgImage.vue';

  export default {
    data() {
      return {
        supplierInfo: {},
      };
    },
    components: {NgImage},

    computed: {
      music_id() {
        return this.$route.params && this.$route.params.order_id;
      },
    },

    props: {
      user_id: String,
    },

    watch: {
      user_id: {
        handler(newVal) {
          newVal && this.getSupplierInfo({user_id: newVal})
        },
        immediate: true
      }
    },

    mounted() {
    },

    methods: {
      getSupplierInfo(params) {
        this.$axios.Sup_info(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.supplierInfo = data.data ? data.data : {};
          }
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
  .music-detail-box {
    margin-top: 32px;
  }

  @media screen and (max-width: $screen-width-md) {
    .music-detail-top {
      .ant-col {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .image-box {
      width: 45%;
      margin-bottom: 24px;
    }
  }
</style>
