<template>
  <ng-dialog
    :type="statusDir.dialogStatus"
    :visible.sync="show"
    :bodyStyle="bodyStyle"
    :footer="null"
    @close="$emit('update:visible', false)"
  >
    <template v-slot:title>
      <dialog-header
        :status="statusDir.headerStatus"
        :title="statusDir.headerTitle"
        :subTitle="statusDir.headerSubTitle"
        :subText="statusDir.headerSubText"
      ></dialog-header>
    </template>

    <main>
      <a-descriptions bordered :column="1" class="border-none table-layout-fixed m-bottom-md">
        <template v-if="receiptStatus === Status.wait">
          <a-descriptions-item label="发票申请编号">{{dataInfo.number}}</a-descriptions-item>
        </template>
        <a-descriptions-item label="申请时间">{{dataInfo.create_at}}</a-descriptions-item>
      </a-descriptions>

      <a-descriptions
        bordered
        :column="1"
        title="基本信息"
        class="border-none table-layout-fixed m-bottom-md"
      >
        <a-descriptions-item label="订单编号">{{dataInfo.order_no}}</a-descriptions-item>
        <a-descriptions-item label="发票金额">￥{{dataInfo.total_amount | toPrice}}</a-descriptions-item>
        <a-descriptions-item label="抬头类型">{{dataInfo.title_type | toReceiptHeaderType}}</a-descriptions-item>
        <a-descriptions-item label="发票类型">{{dataInfo.invoice_type | toReceiptType}}</a-descriptions-item>
        <a-descriptions-item label="发票内容">{{'服务费'}}</a-descriptions-item>
      </a-descriptions>

      <a-descriptions
        bordered
        :column="1"
        title="开票信息"
        class="border-none table-layout-fixed m-bottom-md"
      >
        <template v-if="dataInfo.title_type === $val.ReceiptTitleType.company">
          <a-descriptions-item label="公司名称">{{dataInfo.title ? dataInfo.title.name: ''}}</a-descriptions-item>
          <a-descriptions-item label="纳税人识别号">{{dataInfo.title ? dataInfo.title.taxer_number: ''}}</a-descriptions-item>
          <a-descriptions-item label="注册电话">{{dataInfo.title ? dataInfo.title.phone: ''}}</a-descriptions-item>
          <a-descriptions-item label="注册地址">{{dataInfo.title ? dataInfo.title.address: ''}}</a-descriptions-item>
          <a-descriptions-item label="开户银行">{{dataInfo.title ? dataInfo.title.bank: ''}}</a-descriptions-item>
          <a-descriptions-item label="银行账号">{{dataInfo.title ? dataInfo.title.account: ''}}</a-descriptions-item>
        </template>
        <template>
          <a-descriptions-item label="个人抬头">{{dataInfo.title ? dataInfo.title.name : ''}}</a-descriptions-item>
        </template>
      </a-descriptions>

      <a-descriptions
        bordered
        :column="1"
        title="收件人信息"
        class="border-none table-layout-fixed m-bottom-md"
      >
        <template v-if="dataInfo.invoice_type === $val.ReceiptType.commonEelec">
          <a-descriptions-item label="邮箱地址">{{dataInfo.express ? dataInfo.express.email : ''}}</a-descriptions-item>
        </template>
        <template v-else>
          <a-descriptions-item label="邮寄地址">{{dataInfo.express ? dataInfo.express.address : ''}}</a-descriptions-item>
          <a-descriptions-item label="收件人姓名">{{dataInfo.express ? dataInfo.express.name : ''}}</a-descriptions-item>
          <a-descriptions-item label="收件人手机号">{{dataInfo.express ? dataInfo.express.phone : ''}}</a-descriptions-item>
        </template>
      </a-descriptions>
    </main>
  </ng-dialog>
</template>

<script>
  import {Descriptions} from 'ant-design-vue';
  import DialogHeader from './DialogHeader.vue'
  import NgDialog from '@/components/NgDialog.vue';
  import MusicDetailItem from '@/pages/components/music/MusicDetailItem.vue';
  import {dialogMixins} from '@/mixins/index';
  import OrderHeaderMixins from '../_mixin';

  import {ReceiptHeaderTypeOptions, ReceiptTypeOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  // import OrderTypeMixins from '../_orderTypeMixins';

  const Status = {
    success: 'success',
    wait: 'wait',
    init: 'init'
  }

  export default {
    data() {
      return {
        show: false,
        Status,
        dataInfo: {}
        // showDialog: false
      }
    },

    props: {
      visible: Boolean,
      data: Object,
      invoice_id: String
    },

    filters: {
      toReceiptHeaderType(value) {
        return toOptionName(ReceiptHeaderTypeOptions, value);
      },

      toReceiptType(value) {
        return toOptionName(ReceiptTypeOptions, value);
      },
    },

    mixins: [dialogMixins, OrderHeaderMixins],

    watch: {
      visible: {
        handler(newVal) {
          this.show = newVal;
        },
        immediate: true
      },
      invoice_id: {
        handler(newVal) {
          newVal && this.getReceiptInfo({invoice_id: newVal})
        },
        immediate: true
      }
    },

    computed: {
      receiptStatus() {
        let status = this.dataInfo.is_operated;

        return status === this.$val.CommonCheck.fail ? Status.wait : status === this.$val.CommonCheck.correct ? Status.success : Status.init;
      },

      statusDir() {
        let ot = this.receiptStatus;
        let headerStatus = 'wait';
        let dialogStatus = 'primary';
        let headerTitle = '发票申请中';
        let headerSubTitle = '';
        let headerSubText = '管理员会在 15 个工作日内向你邮寄发票，请注意查收';

        if (ot === Status.success) {
          headerStatus = 'success';
          dialogStatus = 'success';
          headerTitle = '发票申请成功';
          headerSubTitle = '订单编号';
          headerSubText = this.data.order_no;
        }

        return {headerStatus, dialogStatus, headerTitle, headerSubTitle, headerSubText}
      },
    },

    components: {
      NgDialog,
      DialogHeader,
      ADescriptions: Descriptions,
      ADescriptionsItem: Descriptions.Item,
      MusicDetailItem
    },
    methods: {
      getReceiptInfo(params) {
        this.$axios.Order_receiptInfo(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            // something
            this.dataInfo = data.data ? data.data : {}
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pay-success {
    .header {
      height: 200px;

      .icon-font {
        /deep/ {
          svg {
            width: 72px;
            height: 72px;
          }
        }
      }
    }
  }
</style>
