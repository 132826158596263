<template>
  <div class="secure-item">
    <!-- 已经关联 -->
    <template v-if="data.email">
      <div class="detail-item">
        <div class="text">{{$t('userSet.ac_bindEmail')}}：{{data.email || '--' | omitAccount}}</div>

        <div class="btn-outer">
          <a-button
            class="w-100"
            type="primary"
            @click="handleItem($var.Handle.edit)"
          >{{$t('common.change')}}</a-button>
        </div>
      </div>
    </template>
    <!-- 没有关联 -->
    <template v-else>
      <div class="detail-item">
        <div class="text">{{$t('userSet.ac_noConnectEmailText')}}</div>

        <div class="btn-outer">
          <a-button
            class="w-100"
            type="primary"
            @click="handleItem($var.Handle.add)"
          >{{$t('common.connect')}}</a-button>
        </div>
      </div>
    </template>

    <dialog-add
      :type="type"
      :handle="handleType"
      @success="$emit('success')"
      :visible.sync="showAddDialog"
    ></dialog-add>

    <dialog-verify
      :type="type"
      :number="data.email"
      :handle="handleType"
      @change="onVerifyChange"
      @success="$emit('success')"
      :visible.sync="showVerifyDialog"
    ></dialog-verify>
  </div>
</template>

<script>
  import DialogAdd from './DialogAdd.vue';
  import DialogVerify from './DialogVerify.vue';

  export default {
    data() {
      return {
        type: 'email',
        showAddDialog: false,
        showVerifyDialog: false,
        handleType: this.$var.Handle.add,
      }
    },

    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },

    components: {
      DialogAdd, DialogVerify
    },

    methods: {
      onVerifyChange() {
        this.showAddDialog = true;
      },

      handleItem(type) {
        this.handleType = type;

        if (type === this.$var.Handle.add) {
          this.showAddDialog = true;
        }
        else if (type === this.$var.Handle.edit) {
          this.showVerifyDialog = true;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
