<template>
  <a-config-provider :locale="locale">
    <router-view class="app"></router-view>
  </a-config-provider>
</template>

<script>


  import {ConfigProvider as AConfigProvider} from 'ant-design-vue';
  // import {Observe, addEvent, removeEvent} from '@/utils/from-common'

  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
  import En from 'ant-design-vue/lib/locale-provider/en_US';

  const langDir = {
    zh_CN: zhCN,
    en: En
  }

  // import formatTime from '@/utils/function'

  export default {
    name: 'App',
    data() {
      return {
        // locale: zhCN,
        // departmentList: [], // 缓存
      }
    },
   
    computed: {
      locale() {
        return langDir[this.$i18n.locale] || zhCN;
      }
    },

    created() {
      this.$store.dispatch('checkLogin');
      this.$store.dispatch('getUserInfo', true);

      this.setLang();
    },

    // mounted() {
    //   this.watchBodyScroll();
    // },

    // beforeDestroy() {
    //   this.unWatchBodyScroll();
    // },

    components: {
      AConfigProvider
    },

    methods: {
      setLang() {
      }
    }
  }
</script>

<style lang="scss">
  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 14px;
    color: $black-1;
    font-family: SF Pro Display, PingFang SC, sans-serif, Microsoft YaHei;
  }
#chatra:not(.chatra--expanded) {visibility: hidden !important;opacity: 0 !important;pointer-events: none;}

  .iconfont {
    width: 2em;
    height: 2em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;

    &:focus {
      outline: none;
    }
  }
</style>
