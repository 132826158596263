<template>
  <a-form
    :form="form"
    layout="horizontal"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    class="ng-form"
  >
    <a-form-item :label="$t('form.companyName')">
      <a-input
        size="large"
        :placeholder="$t('template.typeSth', {name: $t('form.companyName')})"
        v-decorator="['name', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>

    <a-form-item :label="$t('userSet.rpt_taxPayerCode')">
      <a-input
        size="large"
        :placeholder="$t('template.typeSth', {name: $t('userSet.rpt_taxPayerCode')})"
        v-decorator="['taxer_number', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>

    <a-form-item :label="$t('userSet.rpt_regPhone')">
      <a-input
        size="large"
        :placeholder="$t('template.typeSth', {name: $t('userSet.rpt_regPhone')})"
        v-decorator="['phone', { rules: [$validate.required, $validate.phone], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>

    <a-form-item :label="$t('userSet.rpt_regAddr')">
      <a-input
        size="large"
        :placeholder="$t('template.typeSth', {name: $t('userSet.rpt_regAddr')})"
        v-decorator="['address', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>

    <a-form-item :label="$t('userSet.rpt_bank')">
      <a-input
        size="large"
        :placeholder="$t('template.typeSth', {name: $t('userSet.rpt_bank')})"
        v-decorator="['bank', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>

    <a-form-item :label="$t('userSet.rpt_bankAccount')">
      <a-input
        size="large"
        :placeholder="$t('template.typeSth', {name: $t('userSet.rpt_bankAccount')})"
        v-decorator="['account', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>

    <template v-if="isSpecial">
      <a-form-item class="l-h-1" :label="$t('userSet.rpt_taxerAuth')" required>
        <form-upload-files
          :text="$t('common.upload')"
          accept="image/jpg, image/jpeg, image/png"
          @uploading="onUploading"
          :multiple="false"
          :default-value="uploadFilesDefaultValue"
          v-decorator="['taxer_auth', { rules: [$validate.required], initialValue: '' }]"
        >
          <a class="upload-tip-text" href="javascript:;">{{$t('userSet.rpt_taxerAuthSub')}}</a>
        </form-upload-files>
      </a-form-item>
    </template>
  </a-form>
</template>

<script>
  import {FormUploadFiles} from '@/components/index';
  import {uploadMixins} from '@/mixins/index.js'

  export default {
    data() {
      return {
        uploading: false,
        uploadFilesDefaultValue: []
      }
    },

    props: {
      defaultData: {
        type: Object,
        default() {
          return {}
        }
      },
      wrapperCol: Object,
      labelCol: Object,

      isSpecial: Boolean
    },

    components: {
      FormUploadFiles
    },

    mixins: [uploadMixins],

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'reptCompany'
      })
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .upload-tip-text {
    display: block;
    margin-top: 5px;
  }
</style>
