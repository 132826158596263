// import * as $var from "@/utils/variables";
import { valueLang } from './valueLang'
import * as $val from './options.value.c';
const optionsVal = process.env.VUE_APP_TYPE === 'supplier_en' ? valueLang.en.options : valueLang.zh_CN.options

export const AddDefaultToOptions = function (options, text = optionsVal['select'], id = '', textname = 'label', idname = 'value', isAfter = false) {
  let extra = {
    [textname]: text,
    [idname]: id
  };

  if (isAfter) {
    return [...options, extra]
  } else {
    return [extra, ...options]
  }
}

// 开启关闭状态
export const OpenAndCloseStatus = [{
    name: optionsVal['OpenAndCloseStatus'][1],
    id: '1'
  },
  {
    name: optionsVal['OpenAndCloseStatus'][0],
    id: '0'
  },
]

// 申请
export const ApplyOptions = [{
    label: optionsVal['ApplyOptions'][1],
    value: '1'
  },
  {
    label: optionsVal['ApplyOptions'][0],
    value: '0'
  }
]

// 售卖操作
export const SellOperateOptions = [{
    label: optionsVal['SellOperateOptions'][0],
    value: $val.SaleStatus.wait,
    class: 'warn'
  },
  {
    label: optionsVal['SellOperateOptions'][1],
    value: $val.SaleStatus.online,
    class: 'open'
  },
  {
    label: optionsVal['SellOperateOptions'][2],
    value: $val.SaleStatus.offline,
    class: 'close'
  },
]

// 售卖状态
export const SellStatusOptions = [{
    label: optionsVal['SellStatusOptions'][0],
    value: $val.SaleStatus.wait,
  },
  {
    label: optionsVal['SellStatusOptions'][1],
    value: $val.SaleStatus.online,
  },
  {
    label: optionsVal['SellStatusOptions'][2],
    value: $val.SaleStatus.offline,
  },
]

// 审核状态
export const VerifyStatusOptions = [{
    label: optionsVal['VerifyStatusOptions'][0],
    value: '0'
  },
  {
    label: optionsVal['VerifyStatusOptions'][1],
    value: '1'
  }
]

// 审核结果
export const VerifyResultOptions = [{
    label: optionsVal['VerifyResultOptions'][1],
    value: '1'
  },
  {
    label: optionsVal['VerifyResultOptions'][0],
    value: '0'
  }
]

// 审核状态
export const CheckStatusOptions = [{
    label: optionsVal['CheckStatusOptions'][0],
    value: $val.CheckStatus.checking
  },
  {
    label: optionsVal['CheckStatusOptions'][1],
    value: $val.CheckStatus.reject
  },
  {
    label: optionsVal['CheckStatusOptions'][2],
    value: $val.CheckStatus.agree
  },
]

// 标注结果
export const MarkResultOptions = [{
    label: optionsVal['MarkResultOptions'][1],
    value: '1'
  },
  {
    label: optionsVal['MarkResultOptions'][0],
    value: '0'
  }
]

// 性别
export const SexOptions = [{
    label: optionsVal['SexOptions'][1],
    value: '1'
  },
  {
    label: optionsVal['SexOptions'][2],
    value: '2'
  }
]

// 证件类型
export const CertificateType = [{
    label: optionsVal['CertificateType'][1],
    value: '1'
  },
  {
    label: optionsVal['CertificateType'][2],
    value: '2'
  },
  // {
  //   label: '驾照',
  //   value: '3'
  // }
]

// 证件类型 全部
export const CertificateTypeAll = [
  ...CertificateType,
  {
    label: optionsVal['CertificateType'][3],
    value: '3'
  },
]

// 授权类型
export const AuthTypeOptions = [{
    label: optionsVal['AuthTypeOptions'][1],
    value: '1'
  },
  {
    label: optionsVal['AuthTypeOptions'][2],
    value: '2'
  }
]

// 签约状态
export const SignStatusOptions = [{
    label: optionsVal['SignStatusOptions'][0],
    value: $val.SignStatus.undo
  },
  {
    label: optionsVal['SignStatusOptions'][1],
    value: $val.SignStatus.done
  },
  {
    label: optionsVal['SignStatusOptions'][2],
    value: $val.SignStatus.expire
  }
]

// 授权年限
export const AuthTimeOptions = [{
    label: optionsVal['AuthTimeOptions'][0],
    value: $val.AuthPeriod.year
  },
  {
    label: optionsVal['AuthTimeOptions'][1],
    value: $val.AuthPeriod.forever
  }
]

// 授权地域
// export const AuthAreaOptions = [{
//     label: '中国-大陆',
//     value: $val.AuthArea.chinaLand
//   },
//   {
//     label: '中国（含港澳台）',
//     value: $val.AuthArea.chinaAll
//   },
//   {
//     label: '全球',
//     value: $val.AuthArea.global
//   }
// ]

// 音乐授权地域
export const AuthAreaOfMusicOptions = [{
    label: optionsVal['AuthAreaOfMusicOptions'][0],
    value: $val.AuthArea.chinaLand
  },
  {
    label: optionsVal['AuthAreaOfMusicOptions'][1],
    value: $val.AuthArea.global
  }
]

export const AuthAreaOptions = AuthAreaOfMusicOptions;

// 是否
export const IsOrNotOptions = [{
    label: optionsVal['IsOrNotOptions'][1],
    value: '1'
  },
  {
    label: optionsVal['IsOrNotOptions'][0],
    value: '0'
  },
]

// 发票抬头 类型
export const ReceiptHeaderTypeOptions = [{
    label: optionsVal['ReceiptHeaderTypeOptions'][0],
    value: $val.ReceiptTitleType.company
  },
  {
    label: optionsVal['ReceiptHeaderTypeOptions'][1],
    value: $val.ReceiptTitleType.person
  },
]

// 发票类型
export const ReceiptTypeOptions = [{
    label: optionsVal['ReceiptTypeOptions'][0],
    value: $val.ReceiptType.common
  },
  {
    label: optionsVal['ReceiptTypeOptions'][1],
    value: $val.ReceiptType.special
  },
  /*{
    label: optionsVal['ReceiptTypeOptions'][2],
    value: $val.ReceiptType.commonEelec
  },*/
]

// 激活音乐授权类型
export const ActiviateMusicAuthTypeOptions = [{
    label: optionsVal['ActiviateMusicAuthTypeOptions'][0],
    value: $val.ActiviateMusicAuthType.company
  },
  {
    label: optionsVal['ActiviateMusicAuthTypeOptions'][1],
    value: $val.ActiviateMusicAuthType.person
  },
]

// 行为目标
export const TargetOfDeedsOptions = [{
    label: optionsVal['TargetOfDeedsOptions'][0],
    value: $val.ShareAndCollectType.music
  },
  {
    label: optionsVal['TargetOfDeedsOptions'][1],
    value: $val.ShareAndCollectType.songlist
  },
  // {
  //   label: '音乐人',
  //   value: $val.ShareAndCollectType.supplier
  // },
]

// 音乐类型
export const MusicTypeOptions = [{
    label: optionsVal['MusicTypeOptions'][0],
    value: $val.MusicType.BGM
  },
  {
    label: optionsVal['MusicTypeOptions'][1],
    value: $val.MusicType.RS
  },
  {
    label: optionsVal['MusicTypeOptions'][2],
    value: $val.MusicType.YX
  },
]

// 音效类型
export const MusicEffectOptions = [{
    value: $val.MusicEffect.ACTION,
    label: optionsVal['MusicEffectOptions'][0]
  },
  {
    value: $val.MusicEffect.ENVIROMENT,
    label: optionsVal['MusicEffectOptions'][1]
  },
  {
    value: $val.MusicEffect.SOUNDHUMAN,
    label: optionsVal['MusicEffectOptions'][2]
  },
  {
    value: $val.MusicEffect.WHITE,
    label: optionsVal['MusicEffectOptions'][3]
  },
]

// 提现状态
export const DrawingStatusOptions = [{
    value: $val.DrawingStatus.waitAudit,
    label: optionsVal['DrawingStatusOptions'][0]
  },
  {
    value: $val.DrawingStatus.waitTransfer,
    label: optionsVal['DrawingStatusOptions'][1]
  },
  {
    value: $val.DrawingStatus.didDraw,
    label: optionsVal['DrawingStatusOptions'][2]
  },
  {
    value: $val.DrawingStatus.cancelDraw,
    label: optionsVal['DrawingStatusOptions'][3]
  },
]

// 已支付订单 类型
export const DidPayOrderOptions = [{
    value: $val.DidPayOrderType.undoAuth,
    label: optionsVal['DidPayOrderOptions'][0]
  },
  {
    value: $val.DidPayOrderType.didAuth,
    label: optionsVal['DidPayOrderOptions'][1]
  },
]

// 未支付订单 类型
export const UndoPayOrderOptions = [{
    value: $val.UndoPayOrderType.wait,
    label: optionsVal['UndoPayOrderOptions'][0]
  },
  {
    value: $val.UndoPayOrderType.error,
    label: optionsVal['UndoPayOrderOptions'][1]
  },
  {
    value: $val.UndoPayOrderType.overtime,
    label: optionsVal['UndoPayOrderOptions'][2]
  },
  {
    value: $val.UndoPayOrderType.drop,
    label: optionsVal['UndoPayOrderOptions'][3]
  },
]

// 音乐搜索
export const FilterMusicOptions = [{
    label: optionsVal['FilterMusicOptions'][0],
    value: $val.SearchFilterType.recommend
  },
  {
    label: optionsVal['FilterMusicOptions'][1],
    value: $val.SearchFilterType.hot
  }
]

// 歌单搜索
export const FilterSonglistOptions = [{
    label: optionsVal['FilterSonglistOptions'][0],
    value: $val.SearchFilterType.hot
  },
  {
    label: optionsVal['FilterSonglistOptions'][1],
    value: $val.SearchFilterType.last
  }
]

// 排序
export const SortOptions = [{
    label: optionsVal['SortOptions'][5],
    value: '5'
  },
  {
    label: optionsVal['SortOptions'][1],
    value: '1'
  },
  {
    label: optionsVal['SortOptions'][2],
    value: '2'
  },
  {
    label: optionsVal['SortOptions'][3],
    value: '3'
  },
  {
    label: optionsVal['SortOptions'][4],
    value: '4'
  },
]

// 订单渠道名称
export const OrderChannelOptions = [{
  value: $val.OrderChannel.spark,
  label: 'Spark'
},
{
  value: $val.OrderChannel.paixin,
  label: '拍信'
},
{
  value: $val.OrderChannel.offline,
  label: '线下项目'
}
]

// 业务场景 AuthScene
export const BusinessSceneOptions = [{
    value: $val.AuthScene.scene1,
    label: optionsVal['BusinessSceneOptions'][0]
  },
  {
    value: $val.AuthScene.scene2,
    label: optionsVal['BusinessSceneOptions'][1]
  },
  {
    value: $val.AuthScene.scene3,
    label: optionsVal['BusinessSceneOptions'][2]
  },
  {
    value: $val.AuthScene.scene4,
    label: optionsVal['BusinessSceneOptions'][3]
  },
  {
    value: $val.AuthScene.scene5,
    label: optionsVal['BusinessSceneOptions'][4]
  },
  {
    value: $val.AuthScene.scene6,
    label: optionsVal['BusinessSceneOptions'][5]
  },
  {
    value: $val.AuthScene.scene7,
    label: optionsVal['BusinessSceneOptions'][6]
  },
  {
    value: $val.AuthScene.scene8,
    label: optionsVal['BusinessSceneOptions'][7]
  },
  {
    value: $val.AuthScene.scene9,
    label: optionsVal['BusinessSceneOptions'][8]
  },
  {
    value: $val.AuthScene.scene10,
    label: optionsVal['BusinessSceneOptions'][9]
  },
  {
    value: $val.AuthScene.scene11,
    label: optionsVal['BusinessSceneOptions'][10]
  },
]

// 支付方式 支付方式
export const PayWaysOptions = [{
    value: $val.PayType.zfb,
    label: optionsVal['PayWaysOptions'][0]
  },
  {
    value: $val.PayType.wx,
    label: optionsVal['PayWaysOptions'][1]
  },
  {
    value: $val.PayType.gdg,
    label: optionsVal['PayWaysOptions'][2]
  }
]

// 结算方式 给音乐人结算
export const SettleWays = [{
    value: $val.SettleWayValues.Alipay,
    label: optionsVal['SettleWays'][0]
  },
  {
    value: $val.SettleWayValues.PayPal,
    label: optionsVal['SettleWays'][1]
  },
  {
    value: $val.SettleWayValues.Payoneer,
    label: optionsVal['SettleWays'][2]
  },
  {
    value: $val.SettleWayValues.CToC,
    label: optionsVal['SettleWays'][3]
  }
]

// 音乐网站
export const LinksOptions = [{
    value: $val.MusicianWebs.Person,
    label: optionsVal['LinksOptions'][0]
  },
  {
    value: $val.MusicianWebs.Xiami,
    label: optionsVal['LinksOptions'][1]
  },
  {
    value: $val.MusicianWebs.AudioJungle,
    label: optionsVal['LinksOptions'][2]
  },
  {
    value: $val.MusicianWebs.Douban,
    label: optionsVal['LinksOptions'][3]
  },
  {
    value: $val.MusicianWebs.Bandcamp,
    label: optionsVal['LinksOptions'][4]
  },
  {
    value: $val.MusicianWebs.Spotify,
    label: optionsVal['LinksOptions'][5]
  },
  {
    value: $val.MusicianWebs.SoundCloud,
    label: optionsVal['LinksOptions'][6]
  },
  {
    value: $val.MusicianWebs.AppleMusic,
    label: optionsVal['LinksOptions'][7]
  },
  {
    value: $val.MusicianWebs.Tencent,
    label: optionsVal['LinksOptions'][8]
  },
  {
    value: $val.MusicianWebs.NetEase,
    label: optionsVal['LinksOptions'][9]
  },
]
