<template>
  <div class="user-info">
    <div class="left mobile-block">
      <ng-image class="img" :src="detailData.profile_photo_url"></ng-image>
    </div>
    <div class="right flex-1">
      <div class="name-pos flex cen-space">
        <b class="name font-heading-2">{{detailData.name}}</b>

        <span class="font-color-sub">
          <icon-font class="m-right-xs" type="iconposition"></icon-font>
          <span>{{detailData.phone_code ? detailData.phone_code.cn : ''}}</span>
        </span>
      </div>
      <div class="rout-list-icon flex cen-space-around">
        <router-link class="icon-item" to="/user/detail">
          <icon-font class="m-bottom-xs" type="iconcatelog"></icon-font>
          <span>个人资料</span>
        </router-link>
        <router-link class="icon-item" to="/user/account">
          <icon-font class="m-bottom-xs" type="iconaccount"></icon-font>
          <span>账号与安全</span>
        </router-link>
        <router-link class="icon-item" to="/authCenter">
          <icon-font class="m-bottom-xs" type="iconsecure"></icon-font>
          <span>授权查询</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import NgImage from '~/components/NgImage.vue';
  export default {
    data() {
      return {
        detailData: {}
      }
    },

    created() {
      this.getData();
    },

    components: {
      NgImage

    },

    methods: {
      getData() {
        this.$axios.Base_info().then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.detailData = data.data;
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user-info {
    display: flex;
    min-height: 200px;
    border: 1px solid $border-color;

    .left {
      flex-shrink: 0;
      width: 200px;
      height: 200px;
    }

    .img {
      width: 100%;
      height: 100%;
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .rout-list-icon {
    .icon-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      color: inherit;

      .anticon {
        font-size: 24px;
      }
    }
  }

  @media screen and (min-width: $screen-width-md) {
    .right {
      padding: 24px 10px;
    }
  }

  @media screen and (min-width: $screen-width-xl) {
    .right {
      padding: 24px 20px;
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .user-info {
      flex-direction: column;
      align-items: center;
      border: none;
    }

    .right {
      width: 100%;
    }

    .name-pos {
      flex-direction: column;
      margin-bottom: 32px;

      .name {
        margin-bottom: 8px;
      }
    }

    .icon-item {
      &::before {
        content: " ";
        position: absolute;
        width: 1px;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $border-color;
      }

      &:last-child::before {
        content: none;
      }
    }
  }
</style>
