
<template>
  <div class="genre-music-list flex" :class="{'not-around':notAround}">
    <div class="music-item" v-for="(item, index) in newList" :key="index">
      <router-link
        :to="isPlaylist ? `/playListDetail/${item && item.playlist_id}` : `/allMusic?labels=${item && item.label_id}`"
      >
        <div class="music-img-wrap">
          <div class="img-all-box radius-6">
            <ng-image
              class="music-img w-100"
              :src="item && item.logo_url || item && item.label_pic"
            ></ng-image>
          </div>

          <div
            v-if="isShowCollect"
            class="music-collect"
            :class="{'yellow-color': item.is_collected === 1}"
            @click.prevent="handleCollect(item, index)"
          >
            <icon-font v-if="item.is_collected === 1" type="iconheart" />
            <icon-font v-else type="iconheart-empty" />
          </div>
        </div>
        <div class="music-info">
          <div class="music-type text-ellipsis ft14 font-bold">{{item && item.name}}</div>
          <!-- <div>
          <a-tag v-for="(item, index) in item.labels" :key="index">{{
            item.name
          }}</a-tag>
          </div>-->
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
  // import {Tag} from "ant-design-vue";
  import musicHandle from '@/mixins/musicHandle'

  export default {
    props: {
      data: {
        type: Array,
        default() {
          return []
        }
      },
      isShowCollect: {
        type: Boolean,
        default: false,
      },
      notAround: {
        type: Boolean,
        default: false,
      },
      isPlaylist: {
        type: Boolean,
        default: false,
      },
      num: {
        type: Number,
        default: 0
      }
    },

    mixins: [musicHandle],

    data() {
      return {
        isCollect: false,
        tags: [
          {
            name: "可爱的",
            id: 0,
          },
          {
            name: "科技的",
            id: 1,
          },
          {
            name: "可爱",
            id: 2,
          },
        ],
      };
    },

    computed: {
      newList() {
        let data = this.data;
        let num = this.num;

        return data && data.length > 0 ? data : this.$root.getDefaultArray(num);
      }
    },

    components: {
      // ATag: Tag,
    },

    created() {
    },

    methods: {
      handlePalylist(item) {

        console.log('this.isPlaylist: ', this.isPlaylist);
        if (this.isPlaylist) {
          this.$router.push(`/playListDetail/${item.playlist_id}`);
        } else {


          this.$router.push(`/allMusic`);
        }
      },
      handleCollect(item, index) {
        const params = {
          deed_type: 1,
          obj_type: 2,
          obj_id: item.playlist_id,
          op_type: item.is_collected === 1 ? 0 : 1,
        }
        this.$axios.Static_deed(params).then(() => {
          let is_collected = item.is_collected || '0';
          let message = is_collected == 1 ? this.$t('allMusic.collectSuccessTip') : this.$t('allMusic.cancelSuccessTip');
          this.$message.success(message);
        })
        this.$emit('handleCollect', index)
        return
        // this.goCollectMusic(music);
        // .is_collected = music.is_collected?0:1

      },

    },
  };
</script>
<style lang="scss">
  // mobile
  @media screen and (max-width: $screen-width-md) {
    .genre-music-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .music-item {
        width: 14.4rem;
        // height: 310px;
        margin-right: 10;
        margin-bottom: 2.4rem;
        &:nth-child(2n) {
          margin-right: 0;
        }
        // padding-bottom: 8px;
        .music-img-wrap {
          width: 100%;
          position: relative;
          .music-img {
            width: 100%;
            max-width: 200px;
          }
          .music-collect {
            position: absolute;
            top: 1.2rem;
            right: 1.1rem;
            width: 2.4rem;
            height: 2.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2.4rem;
            color: #fff;
            cursor: pointer;
          }
          .yellow-color {
            color: $warning-color;
          }
        }

        .music-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 1.1rem;
          .music-type {
            color: #000;
          }
          .ant-tag {
            margin-top: 0.4rem;
          }
          .case-desc {
            color: rgba(0, 0, 0, 0.55);
          }
        }
      }
    }
  }
  // pc
  @media screen and (min-width: $screen-width-md) {
    .genre-music-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .music-item {
        width: 15.4%;
        // height: 310px;
        margin-right: 1.3%;

        &:nth-child(6n) {
          margin-right: 0;
        }
        padding-bottom: 8px;
        .music-img-wrap {
          width: 100%;
          position: relative;
          .music-img {
            cursor: pointer;

            width: 100%;
            max-width: 200px;
          }
          .music-collect {
            position: absolute;
            top: 12px;
            right: 11px;
            font-size: 24px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
          }
          .yellow-color {
            color: $warning-color;
          }
        }

        .music-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 11px;
          .music-type {
            color: #000;
            cursor: pointer;
          }
          .ant-tag {
            margin-top: 4px;
          }
          .case-desc {
            color: rgba(0, 0, 0, 0.55);
          }
        }
      }
    }
    .not-around {
      justify-content: flex-start;
      .music-item {
        // height: 310px;
        margin-right: 1.5%;
      }
    }
  }
</style>