<template>
  <div class="my-collect pos-relative">
    <div class="tab-btns">
      <a-select
        class="w-100"
        :options="AuthOptions"
        :defaultValue="$val.AuthStatus.undo"
        size="large"
        @change="onChange"
      ></a-select>
    </div>

    <List :request="$axios.Static_deedList" :params="musicTableParams" :isAuth="isAuth"></List>
  </div>
</template>

<script>
  import List from './components/List.vue'

  import {AuthOptions} from '@/config/options';

  // let AllAuthOptions = [{label: '全部音乐', value: ''}, ...AuthOptions]

  export default {
    data() {
      return {
        musicTableParams: null,
        AuthOptions,
        isAuth: false
      }
    },
    components: {
      // MusicTable,
      List,
    },

    created() {
      let params = {};

      params.is_licensed = this.$val.AuthStatus.undo;
      params.goods_type = this.$val.orderType.package;
      params.page_size = '100'

      this.musicTableParams = params;
    },

    methods: {
      onChange(status) {
        this.musicTableParams = Object.assign({}, this.musicTableParams, {is_licensed: status});
        this.isAuth = status === this.$val.AuthStatus.did;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .tab-btns {
    width: 120px;
  }
</style>
