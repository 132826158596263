<template>
  <router-link
    class="song-list-item w-100 inline-block"
    :to="`/playListDetail/${item && item.playlist_id}`"
  >
    <div class="music-img-wrap">
      <div class="img-all-box m-bottom-sm radius-6">
        <ng-image class="music-img w-100" :src="item && item.logo_url || item && item.label_pic"></ng-image>
      </div>

      <div
        v-if="isShowCollect"
        class="collect-icon"
        :class="{'active': item.is_collected == '1'}"
        @click.prevent="handleCollect(item)"
      >
        <icon-font v-if="item.is_collected == '1'" type="iconheart" />
        <icon-font v-else type="iconheart-empty" />
      </div>
    </div>

    <div class="music-info">
      <div class="music-type text-ellipsis ft14 font-bold">{{item && item.name}}</div>
    </div>
  </router-link>
</template>

<script>
  import musicHandle from '@/mixins/musicHandle'


  export default {
    data() {
      return {

      }
    },

    props: {
      item: {
        type: Object,
        default() {
          return {}
        }
      },
      isShowCollect: {
        type: Boolean,
        default: false,
      },
    },

    mixins: [musicHandle],

    created() {
      this.goodType = 'songlist';
    },

    components: {

    },

    methods: {
      handleCollect(scope) {
        typeof this.goCollectMusic === 'function' && this.goCollectMusic(scope, scope.playlist_id, false);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .song-list-item {
    position: relative;
    color: $font-base-color;

    .collect-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 28px;
      line-height: 1;
      color: #fff;

      &.active {
        color: $primary-color;
      }
    }
  }
</style>
