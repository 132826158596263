import {
  SexOptions,
  CertificateType
} from "../config/options.c";
import {
  DownloadTimeout
} from '@/config/config'

import axios from 'axios'

export * from './color.c'

export * from './calc.c'

export const dataType = (obj) => {
  return Object.prototype.toString.call(obj);
}

export const isObject = (obj) => {
  return dataType(obj) === '[object Object]'
}

let _toString = Object.prototype.toString;

/**
 * 替换对象的关键字
 * 
 * @param {*} item 
 * @param {*} format {url: music_url} url 替换后的字段  music_url 数据原有的字段
 */
export const changeDataItemKey = (item, format) => {
  item = Object.assign({}, item);

  Object.keys(format).forEach(cItem => {
    item[cItem] = item[format[cItem]];
  })

  return item;
}
/**
 * 获取数组对象某个属性值等于给定值的相应对象值
 * 
 * eg：
 * arr = [{name: 1, children: [{name: 2}]}]
 * key = name
 * value = 2 
 * 
 * return {name: 2}
 * 
 * 当value为null表示只要存在此key值且属性值不能为 falsily 就可以
 * 如：
 * key = name
 * value = null
 * 
 * return
 * {name: 1, ....}
 */
export const getItemWithValue = function (arr, key, value, childName = 'children') {
  let final = null;

  if (!Array.isArray(arr)) {
    throw new Error('arr must be a Array');
  }

  one(arr, value);

  return final;

  function one(arr, value) {
    return arr.some(item => {
      let children = childName ? (item[childName] || []) : [];

      if (children.length > 0) {
        return one(children, value);
      }

      /**
       * 当value = null 时判断属性值是否存在，如果存在就返回
       * 当value 不等于 null 时属性值与给定的value值做判断
       */
      if ((value === null && typeof item[key] !== 'undefined') || (item[key] !== null && item[key] === value)) {
        final = item;
        return true;
      }

      return false;
    })
  }
}

/**
 * 获取某个属性存在的第一个item
 * 
 * eg：
 * arr = [{name: 1, children: [{name: 2}]}]
 * key = name
 * value = 2 
 * 
 * return {name: 2}
 */
export const getFirstItemWithKey = function (arr, key, childName = 'children') {
  let final = null;

  if (!Array.isArray(arr)) {
    throw new Error('arr must be a Array');
  }

  one(arr);

  return final;

  function one(arr, value) {
    return arr.some(item => {
      let children = item[childName] || [];

      if (children.length > 0) {
        return one(children, value);
      }

      if (item[key] === value) {
        final = item;
        return true;
      }

      return false;
    })
  }
}

/**
 * 删除对象上的某个属性值
 * 返回新对象
 */
export const deleteItem = function (obj, key) {
  return Object.keys(obj).filter(item => item !== key).reduce((a, b) => {
    a[b] = obj[b];
    return a;
  }, {})
}

/**
 * 根据给到id，获取对应结构树上的所有父级和自身的id集合
 * 
 * @reItem 是否返回对应的item
 * 
 * data = [
 * {
 *  id: 1,
 *  child:[
 *    {id: 2}
 *  ]
 * }
 * ]
 * value = 2
 * return [1, 2]
 */
export const getAllValueAccrodingFinal = function (data = [], value, key = 'id', cName = 'child') {
  let record = [];

  typeof value !== 'undefined' && one(data, value);

  return record;

  function one(data, value) {
    return data.some(function (item) {
      if (item[key] === value) {
        record.push(item[key]);
        return true;
      }

      if (item[cName] && item[cName].length > 0) {
        record.push(item[key]);

        if (one(item[cName], value)) return true;

        record.pop();
      }
    })
  }
}

/**
 * 修改数据项，更具key值判断，替换新数据
 * 
 * data 数据
 * value 判断的字段值
 * @function itemFunc 返回需要替换的新对象
 * key 需要判断的字段
 */
export const changeDataItem = function (data, value, itemFunc, key = 'uid', cName = 'child') {
  if (!Array.isArray(data)) {
    throw new Error('data must be a array.');
  }

  one(data, value, itemFunc, key, cName);

  return data;

  function one(cData, cValue, itemFunc, key, cName) {
    let bool = cData.some(function (item, index) {
      if (item[key] === cValue) {
        // data[index] = itemFunc(item);
        cData.splice(index, 1, itemFunc(item))
        return true;
      }

      if (item[cName] && item[cName].length > 0) {
        if (one(item[cName], cValue, itemFunc, key, cName)) {
          return true;
        }
      }

      return false
    })

    return bool;
  }
}

/**
 * 转化请求参数
 * 
 * @param {*} value 
 * 
 * {
 *    name: [{name: 12, age: 23}, {name: 56, age: 21}]
 * }
 * 
 * ===>
 * 
 * {
 *   "name[0][name]": 12,
 *   "name[0][age]": 23,
 *   "name[1][name]": 56,
 *   "name[1][age]": 21,
 * }
 */
export const transferParamFromObjToStr = function (value, func) {
  let param = {};

  if (typeof value !== 'object') {
    console.error('value must be a object');
    return param;
  }

  one(value, '')

  return param;

  function one(item, key) {
    item && Object.keys(item).length > 0 && Object.keys(item).forEach(k => {
      let ckey = key ? `${key}[${k}]` : k;

      if (typeof item[k] === 'object' && item[k] !== null) {
        one(item[k], ckey);
      } else {
        param[ckey] = typeof func === 'function' ? func(item[k]) : item[k];
      }
    })
  }
}

/**
 * 获取没有children属性值的第一个对象
 * 
 * eg: 
 * arr = [{name: 1, children: [{name: 2}]}]
 * 
 * return {name: 1, ...}
 */
export const getFristItemNoChildren = function (arr) {
  let final = null;

  if (!Array.isArray(arr)) {
    throw new Error('arr must be a Array');
  }

  one(arr);

  return final;

  function one(arr, value) {
    return arr.some(item => {
      let children = item.children || [];

      if (children.length > 0) {
        return one(children, value);
      }

      final = item;

      return true;
    })
  }
}

/**
 * 获取数组对象某个属性值等于给定值的相应对象值
 * 
 * arr = [{name: 1}, {name: 2}]
 * key = name
 * value = 2 
 * @return {name: 2}
 */
export const getItemWithKey = function (arr, key, value) {
  let final = {};

  arr.some(item => {
    if (item[key] === value) {
      final = item;
      return true;
    }
  })

  return final;
}


/**
 * 深度递归遍历对象
 * 
 * @param {*} arr 
 * @param {*} func 对每一项做的操作
 * @param {*} childrenName 
 */
export const loopDeepArr = function (arr, func, childrenName = 'children') {
  return loop(arr);

  function loop(data) {
    return data.map(item => {
      if (item[childrenName] && item[childrenName].length) {
        item[childrenName] = loop(item[childrenName])
      }

      return typeof func === 'function' ? func(item) : item;
    })
  }
}

/**
 * 铺平数据
 * 
 * @param {*} array 
 * @param {*} childName 
 */
export const flatArray = (array, childName = 'child') => {

  return loop(array, childName)

  function loop(arr, cname) {
    return arr.reduce((prev, next) => {
      let child = next[cname];

      prev.push(next);

      if (child && child.length > 0) {
        prev = prev.concat(loop(child, cname))
      }

      return prev;
    }, [])
  }
}

/**
 * 省略账号
 * 
 * @param {*} value 
 * 
 * 大于7位的返回值 只保留前4位后2位
 * 小于7位的返回值 只保留前2位后1位
 * 
 * 123456789123 => 1234******23
 * 12345 => 12**5
 */
export const omitAccount = function (value) {
  let suffix = '';
  let key = '';

  if (typeof value !== 'string') {
    console.error('value must be a string');
    return '';
  }

  // 有@表示邮箱
  if (/@/.test(value)) {
    key = '@';

    let arr = value.split(key);

    value = arr[0];
    suffix = arr[1];
  }

  let start = 4,
    end = 2;

  if (value.length <= 6) {
    start = 2;
    end = 1;
  }

  let reg = new RegExp(`(^.{${start}})(.+)(.{${end}}$)`)

  return value.replace(reg, (match, p1, p2, p3) => {
    return p1 + new Array(p2.length).fill('*').join('') + p3;
  }) + key + suffix;
}

/**
 * 省略银行卡号
 * @param {*} value 
 * 
 * 1234567812349456 => 1234 **** **** 9456
 */
export const omitBank = function (value) {
  if (typeof value !== 'string') {
    console.error('value must be a string');
    return '';
  }

  // 含有非数字的直接返回原数据
  if (/[^\d]/.test(value)) {
    return value;
  }

  return value.replace(/(\d{1,4})/g, '$1 ').trim().replace(/(\s)([^\s]+)(?=\s)/g, (match, p1, p2) => {
    return p1 + new Array(p2.length).fill('*').join('');
  });
}

/**
 * 转换成性别名称
 * 
 * @param {*} value 
 */
export const toSexName = function (value) {
  let final = SexOptions.filter(item => value == item.value);

  return final && final.length > 0 ? final[0].label : '';
}

/**
 * 转换成证书名称
 * 
 * @param {*} value 
 */
export const toLicenseName = function (value) {
  if (!value) return '';

  value = value.toString();

  let final = CertificateType.filter(item => item.value === value);

  return final && final.length > 0 ? final[0].label : '';
}

/**
 * 将options根据value转成对应的name
 * 
 * @param {*} value 
 */
export const toOptionName = function (options, value, name = 'label', valueName = 'value', defaultText = '--') {
  let final = options.filter(item => value == item[valueName]);

  return final && final.length > 0 ? final[0][name] : defaultText;
}

/**
 * 复制 js 字符串
 * 
 * @param {string} str
 */
export const copyStr = function (str) {
  const el = document.createElement('textarea');

  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';

  document.body.appendChild(el);

  const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;

  el.select();

  document.execCommand('copy');
  document.body.removeChild(el);

  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}

/**
 * 选中标签内的文本
 * 
 * @param {*} element DOM 对象
 */
export const selectText = function (element) {
  let range = null;

  if (!(element instanceof HTMLElement)) {
    console.log('element must be a element');
    return;
  }

  if (document.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(element);
    range.select();
  } else if (window.getSelection) {
    let selection = window.getSelection();
    range = document.createRange();

    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.error("none");
  }
}

// 之前的下载文件方法  保留
const normalDownloadFileByIframe = (url, filename, resolve) => {

  let eleLink = document.createElement('iframe');
  eleLink.download = filename;
  eleLink.style.display = 'none';
  eleLink.src = url;
  document.body.appendChild(eleLink);
  resolve();
  setTimeout(() => {
    document.body.removeChild(eleLink);
  }, 1000);
}

// 将音乐数据转成 blob
const getBlob = (url, cb,reject) => {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response);
    }else{
      reject();
    }
  };
  xhr.send();
}

// 将音乐文件重命名
const saveAs = (blob, filename, resolve) => {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    var body = document.querySelector('body');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.style.display = 'none';
    body.appendChild(link);
    link.click();
    resolve();
    body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }
}

// 下载音乐文件 修改因为名称 解决部分音乐下载没有后缀名
const downloadFileByIframeFileName = (url, filename, resolve) => {

  getBlob(url,function(){
    normalDownloadFileByIframe(url, filename, resolve);
  }, function (blob) {
    saveAs(blob, filename, resolve);
  });

}

// 下载文件 iframe
export const DownloadFileByIframe = (url, filename) => {
  return new Promise((resolve, reject) => {
    if (!url) return;

    try {
      if (filename) {
        downloadFileByIframeFileName(url, filename, resolve,reject);
      } else {
        normalDownloadFileByIframe(url, filename, resolve)
      }
    } catch (error) {
      reject(error);
    }
  })
}


// 下载文件
export const DownloadFile = (content, filename) => {
  var eleLink = document.createElement('a');
  eleLink.download = filename;
  eleLink.style.display = 'none';
  var blob;

  if (!content) return false;

  // 字符内容转变成blob地址
  if (_toString.call(content) === '[object Blob]') {
    blob = content;
  } else {
    blob = new Blob([content]);
  }

  eleLink.href = URL.createObjectURL(blob);

  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
}

export const RequestFile = (url, fileName) => {
  return new Promise((resolve, reject) => {
    axios.request({
      url: url,
      method: 'get',
      responseType: 'blob',
      timeout: DownloadTimeout,
    }).then(res => {
      if (res && res.status == '200') {
        try {
          DownloadFile(res.data, fileName)
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        reject(res.data);
      }
    }, reject)
  })
}

// 根据路径取得文件名字
export const getFilenameByPath = (path = '') => {
  path = path.toString();

  let final = path.split(/[/\\]/g);

  return final && final.length > 0 ? final[final.length - 1] : '';
}

// 根据文件路径拿到文件后缀名
export const getFileSuffixByPath = (path = '') => {
  if (typeof path !== 'string') return '';

  var index = path.lastIndexOf(".");

  return path.substring(index + 1).toLocaleLowerCase();
}

// 格式化时间
export const formatTime = (time, fmt = 'YYYY-MM-DD') => {
  if (typeof time === 'string' || typeof time === 'number') {
    time = new Date(time);
  } else if (_toString.call(time) !== '[object Date]') {
    return false;
  }

  let dir = {
    M: time.getMonth() + 1,
    D: time.getDate(),
    h: time.getHours(),
    m: time.getMinutes(),
    s: time.getSeconds()
  }

  fmt = fmt.replace(/(M+|D+|h+|m+|s+)/g, item => dir[item.slice(-1)].toString().padStart(2, '0'));

  return fmt.replace(/(Y+)/g, item => time.getFullYear().toString().slice(-item.length));
}

// 将秒数转化为分钟数
// 120 => '02:00'
export const formatTimeBySecond = (value) => {
  let h = 60 * 60;
  let m = 60;
  let arr = [h, m];
  let final = [];

  arr.forEach(item => {
    let divi = Math.floor(value / item);

    value = Math.round(value % item);

    divi && final.push(`${divi}`.padStart(2, '0'));
  })

  final.push(`${value ? value : '0'}`.padStart(2, '0'));

  final.length <= 1 && final.unshift('00')

  return final.join(':');
}

// 防抖延迟执行
export const debounce = (callback, delay) => {
  var timer = null;

  return function () {
    var arg = arguments;

    if (timer !== null) clearTimeout(timer);

    timer = setTimeout(function () {
      callback.apply(this, arg);
    }, delay)
  }
}