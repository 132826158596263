<template>
  <ng-dialog
    class="receipt-add"
    :title="title"
    :visible.sync="show"
    :bodyStyle="bodyStyle"
    @close="$emit('update:visible', false)"
  >
    <a-form
      :form="form"
      layout="horizontal"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      class="ng-form"
    >
      <a-form-item :label="$t('userSet.rpt_type')" class="lh-unset-control">
        <a-radio-group
          :options="ReceiptHeaderTypeOptions"
          @change="onChangeRecp"
          v-decorator="['type', { rules: [$validate.required]}]"
        ></a-radio-group>
      </a-form-item>

      <div v-if="ReceiptTitleType === $val.ReceiptTitleType.company">
        <a-form-item
          class="lh-unset-control item-between m-bottom-0"
          :label="$t('userSet.rpt_specTicket')"
        >
          <a-switch
            @change="onChangeSpecial"
            v-decorator="['is_special', {valuePropName: 'checked', initialValue: false}]"
          ></a-switch>
        </a-form-item>
        <span class="ant-item-extra-self">{{$t('userSet.rpt_specTicketExtra')}}</span>
      </div>

      <component
        :is="receiptTypeComp"
        :wrapper-col="wrapperCol"
        :label-col="labelCol"
        :default-data="defaultData"
        :key="receiptTypeComp"
        :is-special="isSpecial"
        ref="dynamicComp"
      ></component>

      <a-form-item
        :label="$t('userSet.rpt_setDefault')"
        class="lh-unset-control item-between"
        required
      >
        <a-switch v-decorator="['is_default', {valuePropName: 'checked', initialValue: false}]"></a-switch>
      </a-form-item>

      <a-form-item
        v-if="!isEdit && ReceiptTitleType === $val.ReceiptTitleType.company"
        class="lh-unset-control"
      >
        <a-checkbox v-decorator="['agreement', { valuePropName: 'checked' }]">
          已阅读并同意
          <a href class="font-color-base">《申请开具增值税专用发票确认书》</a>
        </a-checkbox>
      </a-form-item>
    </a-form>

    <footer class="modal-footer text-center" slot="footer">
      <template v-if="isSpecial">
        <a-popconfirm
          :title="$t('userSet.rpt_submitPopConfirm')"
          :ok-text="$t('common.confirm')"
          :cancel-text="$t('common.cancel')"
          @confirm="handleOk"
        >
          <a-button
            type="primary"
            class="w-100"
            size="large"
            :loading="confirmLoading"
          >{{modalOkText}}</a-button>
        </a-popconfirm>
      </template>
      <template v-else>
        <a-button
          type="primary"
          class="w-100"
          size="large"
          @click="handleOk"
          :loading="confirmLoading"
        >{{modalOkText}}</a-button>
      </template>
    </footer>
  </ng-dialog>
</template>

<script>
  import {Popconfirm, Switch} from 'ant-design-vue';
  import NgDialog from '@/components/NgDialog.vue'
  import {dialogMixins} from '@/mixins/index';
  import {ReceiptHeaderTypeOptions} from "@/config/options";
  import ReptPerson from './ReptPerson.vue';
  import ReptCompany from './ReptCompany.vue';

  const labelCol = {span: 24};
  const wrapperCol = {span: 24};

  export default {
    data() {
      return {
        show: false,

        ReceiptHeaderTypeOptions,

        confirmLoading: false,

        defaultData: {},

        labelCol,
        wrapperCol,

        ReceiptTitleType: '',
        openSpecial: false, // 是否开启专票
      }
    },

    props: {
      handle: String,
      options: Object,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    computed: {
      title() {
        let title = this.handle === 'edit'
          ? this.$t('template.changeSth', {name: this.$t('userSet.rpt_title')})
          : this.$t('template.addSth', {name: this.$t('userSet.rpt_title')});

        return title;
      },

      isEdit() {
        return this.handle === this.$var.Handle.edit;
      },

      isSpecial() {
        return !!(this.ReceiptTitleType === this.$val.ReceiptTitleType.company && this.openSpecial)
      },

      receiptTypeComp() {
        let {person} = this.$val.ReceiptTitleType;

        return this.ReceiptTitleType === person ? 'ReptPerson' : 'ReptCompany'
      },

      modalOkText() {
        return this.isSpecial ? this.$t('userSet.rpt_saveAndVer') : this.$t('common.save');
      },

      request() {
        return this.isEdit ? this.$axios.Recipt_edit : this.$axios.Recipt_add;
      }
    },

    watch: {
      visible: {
        handler(newVal) {
          if (newVal && this.form) {
            this.$nextTick(() => {
              let {type, is_special, is_default, ...extra} = this.defaultValue;
              this.form.resetFields();
              this.$refs.dynamicComp && this.$refs.dynamicComp.form.resetFields();

              if (this.isEdit) {
                this.form.setFieldsValue({type});

                this.$nextTick(() => {
                  this.form.setFieldsValue({is_special, is_default});

                  this.$nextTick(() => {
                    if (extra.taxer_auth) {
                      let fileName = extra.taxer_auth.replace(/.*\//g, '');

                      extra.taxer_auth = [
                        {uid: '1', name: fileName, path: extra.taxer_auth, url: extra.taxer_auth_url, status: 'done', }
                      ]
                    }

                    this.$refs.dynamicComp.form.setFieldsValue(extra);
                    this.$refs.dynamicComp.uploadFilesDefaultValue = extra.taxer_auth;
                  })
                })
              } else {
                this.form.setFieldsValue({type: this.$val.ReceiptTitleType.company});
                this.$nextTick(() => {
                  this.form.setFieldsValue({is_special: false});
                })
              }
            })
          }
        },
        immediate: true
      }
    },

    mixins: [dialogMixins],

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'add',
        onValuesChange: (props, value) => {
          if (typeof value.type !== 'undefined') {
            this.ReceiptTitleType = value.type
          } else if (typeof value.is_special !== 'undefined') {
            this.openSpecial = value.is_special
          }
        }
      })
    },

    components: {
      ReptPerson, ReptCompany, APopconfirm: Popconfirm,
      NgDialog, ASwitch: Switch
    },

    methods: {

      handleOk() {

        this.form.validateFieldsAndScroll((errors, values) => {

          this.$refs.dynamicComp.form.validateFieldsAndScroll((innererrors, innervalues) => {

            if (!innererrors && !errors) {
              if ('agreement' in values && !values.agreement) {
                this.$message.warning('已阅读并同意《申请开具增值税专用发票确认书》')
                return false;
              }

              values = Object.assign(values, innervalues);

              this.onSubmit(values).then((data) => {
                this.$emit('update:visible', false);
                this.$emit('success', data);
              })
            }
          })
        })
      },

      // Address_add  Address_edit
      onSubmit(param) {
        this.confirmLoading = true;

        param = {...param};

        param.is_default = +(param.is_default || 0) + '';

        if (this.ReceiptTitleType === this.$val.ReceiptTitleType.company) {
          param.is_special = +(param.is_special || 0) + '';
        }

        if (this.isSpecial) {
          param.taxer_auth = param.taxer_auth[0] ? param.taxer_auth[0].path : ''
        }

        if (this.isEdit) {
          param = Object.assign(param, {invoice_id: this.defaultValue.invoice_id})
        }

        return new Promise(resolve => {
          this.request && this.request(param).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              resolve(data);
              this.$message.success(this.handle === this.$var.Handle.edit
                ? this.$t('result.editSuccess')
                : this.$t('result.addSuccess'));
            }

            this.confirmLoading = false;
          }).catch(() => {
            this.confirmLoading = false;
          })
        })
      },

      onChangeSpecial(value) {
        this.is_special = value;
      },

      onChangeRecp(value) {
        this.receipt_id = value;
      },

      handleCancel() {

      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
