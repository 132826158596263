<template>
  <div>
    <a-divider class="m-empty"></a-divider>

    <ng-list
      class="m-top-base"
      id-key="music_id"
      :load.sync="loadData"
      :reload.sync="reloadData"
      :url-func="$axios.AuthList"
      :params="params"
      :changeData="changeItem"
      :empty-text="$t('userOrder.tableEmtpy')"
    >
      <template v-slot="{item: cItem}">
        <!-- <ng-list-item> -->
        <!-- <div v-for="cItem in item.detail" :key="cItem.detail_id"> -->
        <div class="good-item pos-relative operation-item">
          <music-detail-item :data="cItem">
            <template v-if="cItem.is_licensed != $val.CommonCheck.fail && cItem.code">
              <div class="m-right-sm auth-code">
                <span class="font-color-sub">授权代码</span>&nbsp;
                <span>{{cItem.code}}</span>
              </div>
            </template>

            <template v-slot:right>
              <div class="good-item-right f-r">
                <!-- <div class="price">
                  <b class="font-md">￥{{cItem.price}}</b>
                </div>-->

                <div class="operation">
                  <a-button
                    v-if="cItem.is_licensed === $val.CommonCheck.correct"
                    class="p-empty m-right-sm lh-1"
                    type="link"
                    @click="donwloadHighMusic(cItem, true)"
                  >下载无损音乐</a-button>
                  <a-button
                    v-else
                    class="p-empty m-right-sm lh-1"
                    type="link"
                    @click="downloadTryMusic(cItem)"
                  >下载试用音乐</a-button>

                  <a-button
                    v-if="cItem.is_licensed == $val.CommonCheck.fail"
                    class="p-empty lh-1"
                    type="link"
                    @click="goToActivate(cItem)"
                  >激活音乐授权</a-button>
                  <router-link
                    v-else
                    :to="`/authCenter?license_id=${cItem.code}`"
                  >查看授权信息</router-link>
                  <!-- <a-button
                    v-else
                    class="p-empty lh-1"
                    type="link"
                    @click="downloadAuthCert(cItem)"
                  >查看授权信息</a-button>-->
                </div>
              </div>
            </template>
          </music-detail-item>
        </div>
        <!-- </div> -->
        <!-- </ng-list-item> -->
      </template>
    </ng-list>

    <dialog-active-auth
      :visible.sync="showDialogActive"
      :data="handleData"
      @success="onSuccess"
      @loadlist="onLoadList"
      :type="$val.orderType.music"
    ></dialog-active-auth>
  </div>
</template>

<script>
  import NgList from '@/components/NgList.vue';
  import NgListItem from '@/components/NgListItem.vue';
  import MusicDetailItem from '@/pages/components/music/MusicDetailItem.vue';
  import DialogActiveAuth from "@/pages/components/order/DialogActiveAuth.vue";

  import {DidPayOrderOptions} from '@/config/options';

  import musicHandle from '@/mixins/musicHandle'

  export default {
    data() {
      return {
        DidPayOrderOptions,
        radioActiveValue: this.$val.DidPayOrderType.undoAuth,
        // params: null,
        loadData: false,
        reloadData: false,
        showDialogActive: false,
        handleData: {},
        changeItem: [],
      }
    },

    props: {
      type: String,
      params: Object,
    },

    mixins: [musicHandle],

    components: {
      NgList,
      NgListItem,
      MusicDetailItem,
      DialogActiveAuth
    },

    methods: {
      onSuccess(scope) {
        let data = scope.data;

        this.changeItem = [data];

        setTimeout(() => {
          this.changeItem = [];
        }, 1000);
      },

      onLoadList() {
        this.reloadData = true;
      },

      goToActivate(scope) {
        this.handleData = scope;
        this.showDialogActive = true;
      },
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .item-right {
      .right {
        width: 400px;
      }
    }
  }

  .auth-code {
    margin-top: $space-ver-sm;
  }

  // .good-item-right {
  //   display: flex;
  //   justify-content: space-between;
  // }

  .operation {
    button {
      height: auto;
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .good-item-right {
      width: 100%;
      flex-direction: column;
      margin-top: $space-ver-sm;

      .price {
        margin-bottom: $space-ver-sm;
      }
    }
  }
</style>
