<template>
    <a-drawer
      title="播放历史"
      placement="bottom"
      :mask='false'
      :closable="true"
      @close='onClose'
      :visible="historyMusicDialog"
      wrapClassName="history-music-drawer"
    >
         <music-table :request="$axios.Static_deedList" :params="musicTableParams" :pagination="false"></music-table>

    </a-drawer>
</template>

<script>
  import MusicTable from '@/pages/components/music/MusicHistoryTable.vue';
  import {DeedType} from '@/config/options.value';

  import {Dropdown, Menu} from 'ant-design-vue';
  import {mapState ,mapMutations} from 'vuex'
  export default {
    data() {
      return {
        list: [],
        loading: false,
        isOpen: false,
        musicTableParams:{
          obj_type: 1,
          deed_type: 3,
          page: 1
        }
      }
    },
  computed: {
    ...mapState(["historyMusicDialog"]),
   
  },
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },

    components: {
      ADropdown: Dropdown,
      AMenu: Menu,
      AMenuItem: Menu.Item,
      MusicTable
    },

    methods: {
      ...mapMutations(['changeHistoryMusicDialog']),
      onClose(){
        this.changeHistoryMusicDialog(false)
      },
      getMusicVersion(music_id) {
        let params = {music_id};

        this.loading = true;

        this.$axios.Music_Info(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.list = data.data ? data.data.filter(item => item.music_id !== this.item.music_id) : [];
          }

          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },

      // onVisibleChange(bool) {
      //   if (bool) {
      //     this.getMusicVersion(this.item.music_id || '');
      //   }
      // },

      onClickItem(item) {
        let {audition_url, origin_url} = item;

        let url = origin_url ? origin_url : audition_url;
        let {suplr_name} = this.item;

        // music_id 切换
        this.$store.commit('changeVideoInfo', Object.assign({}, this.item, {audition_url: url, suplr_name}));
      },

      onChangeVisible(visiable) {
        this.isOpen = visiable;

        if (visiable) {
          this.getMusicVersion(this.item.music_id || '');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
 .history-music-drawer{
      z-index: 1;
    }
  /deep/{
    .ant-drawer {
      z-index: -1 !important;
     
     
    }
    .ant-drawer-bottom{
      z-index: -1 !important;
    }
   
   .ant-drawer-content-wrapper{
      bottom: 0;
      right: 0;
    }
    .ant-drawer-content-wrapper{
        width: 500px;
        height: 620px !important;
       
      }
      .ant-drawer-header{
        position: fixed;
        z-index: 99;
        width: 500px;
      }
        .ant-drawer-body{
        padding:55px 0 90px 0!important;
      } 
  }
</style>
