<template>
  <ng-dialog :visible.sync="show" mHeight="auto">
    <template v-slot:title>
      <h1 class="ant-drawer-title m-bottom-xs">申请合同</h1>
      <div class="font-base lh-1-5">该合同可用于申报预算、申请预付款等公司流程</div>
    </template>

    <div class="progress-wrapper">
      <template v-for="(item, index) in progressList">
        <div
          :class="['progress-item flex cen-center m-right', proStep == item.key ? 'active' : '']"
          :key="index"
        >
          <b class="font-heading-0 m-right-sm">{{item.step}}</b>
          <span>{{item.content}}</span>
        </div>

        <div v-if="index < progressList.length - 1" class="step-icon" :key="`icon_${index}`">
          <icon-font type="iconplay-fff"></icon-font>
        </div>
      </template>
    </div>

    <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <template v-if="proStep == $var.Common.first">
        <a-form-item label="使用项目">
          <a-input
            size="large"
            placeholder="例如：XXX个人毕业设计；XXX个人账号系列视频"
            v-decorator="['project', { rules: [$validate.required], validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>

        <a-form-item label="项目投放">
          <a-input
            size="large"
            placeholder="例如：XXX学院毕业设计展； B站、微博、小红书等社交平台"
            v-decorator="['put_path', { rules: [$validate.required], validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>

        <a-form-item label="项目描述">
          <a-input
            size="large"
            placeholder="项目描述"
            v-decorator="['desc', { rules: [$validate.required], validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>
      </template>
      <template v-else>
        <a-form-item label="公司信息">
          <form-company
            size="large"
            placeholder="请选择公司信息"
            @add="$router.push(`/user/detail?menukey=${$val.UserMenu.company}`)"
            v-decorator="['company_id', { rules: [$validate.required]}]"
          ></form-company>
        </a-form-item>

        <a-form-item label="收件人信息">
          <form-address
            size="large"
            placeholder="请选择收件人信息"
            @add="$router.push(`/user/detail?menukey=${$val.UserMenu.address}`)"
            v-decorator="['express_id', { rules: [$validate.required]}]"
          ></form-address>
        </a-form-item>
      </template>
    </a-form>

    <template v-slot:footer>
      <a-button
        type="primary"
        class="w-100"
        size="large"
        @click="handleOk"
        :loading="confirmLoading"
      >{{btnText}}</a-button>
    </template>
  </ng-dialog>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import FormCompany from '@/components/FormCompany.vue'
  import FormAddress from '@/components/FormAddress.vue'
  import NgDialog from '@/components/NgDialog.vue'

  const labelCol = {span: 24};
  const wrapperCol = {span: 24};

  const Progress = (type, order) => {
    return [
      {
        step: '01',
        content: `授权项目信息`,
        key: order.first
      },
      {
        step: '02',
        content: `合同的必要信息`,
        key: order.second
      }
    ]
  }

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        labelCol,
        wrapperCol,

        proStep: this.$var.Common.first, // '1' 第一步  '2' 第二步
      }
    },

    props: {
      data: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
      });
    },

    computed: {
      btnText() {
        return this.proStep == this.$var.Common.first ? '下一步' : '确认'
      },

      progressList() {
        return Progress(this.type, this.$var.Common)
      },
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.proStep = this.$var.Common.first;
        }
      }
    },

    mixins: [dialogMixins],

    components: {
      FormCompany,
      FormAddress,
      NgDialog,
    },

    methods: {

      handleOk() {
        if (this.proStep == this.$var.Common.first) {
          this.submitFirstStep();
        }
        else if (this.proStep == this.$var.Common.second) {
          this.submitSecondStep();
        }
      },

      submitFirstStep() {
        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            this.proStep = this.$var.Common.second;
            this.firstValues = values;
          }
        })
      },

      submitSecondStep() {
        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            this.confirmLoading = true;

            values.order_id = this.data.order_id;

            values = Object.assign(values, this.firstValues);

            this.$axios.Order_contractAdd(values).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.$message.success('申请成功');
                this.$emit('update:visible', false);
                this.$emit('success', data);
                this.$emit('loadlist', data)
              }

              this.confirmLoading = false;
            }).catch(() => {
              this.confirmLoading = false;
            })
          }
        })
      },

      handleCancel() {

      },
    }
  }
</script>

<style lang="scss" scoped>
  .progress-wrapper {
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 20px;
    color: rgba($color: #000000, $alpha: 0.25);

    .progress-item {
      padding: 10px 0;

      &.active {
        color: $primary-color;
      }
    }

    .step-icon {
      width: 16px;
      height: 16px;
    }
  }

  @media screen and(min-width: $screen-width-md) {
    .progress-wrapper {
      justify-content: space-around;
    }
  }

  @media screen and(max-width: $screen-width-md) {
    .progress-wrapper {
      justify-content: space-between;
    }
  }
</style>
