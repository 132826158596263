<template>
  <ng-dialog :visible.sync="show" :bodyStyle="bodyStyle" @close="$emit('update:visible', false)">
    <template v-slot:title>
      <h1 class="ant-drawer-title">{{$t('userOrder.activeDiaTitle')}}</h1>
      <div class="font-base lh-1-5">请填写授权信息以完成激活，我们将为你出具授权证书并提供可下载的音频</div>
    </template>

    <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <h2 class="font-lg m-bottom-md">基本授权信息</h2>

      <a-form-item label="授权套餐">
        <a-select
          size="large"
          :options="AuthPackageOptions"
          :placeholder="$t('form.pleaseSelect')"
          v-decorator="['goods_id', { }]"
        ></a-select>
      </a-form-item>

      <a-form-item label="授权用途">
        <a-select
          size="large"
          :options="BusinessSceneOptions"
          :placeholder="$t('form.pleaseSelect')"
          v-decorator="['auth_scene', { }]"
        ></a-select>
      </a-form-item>

      <a-form-item label="授权渠道">
        <a-select
          size="large"
          :options="AuthChannelOptions"
          :placeholder="$t('form.pleaseSelect')"
          v-decorator="['use_mode', { }]"
        ></a-select>
      </a-form-item>

      <a-form-item label="授权区域">
        <a-radio-group
          button-style="solid"
          :default-value="$val.AuthArea.chinaLand"
          v-decorator="['auth_area', { }]"
        >
          <template v-for="item in AuthAreaOfMusicOptions">
            <a-radio-button :value="item.value" :key="item.value">{{item.label}}</a-radio-button>
          </template>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="授权期限">
        <a-radio-group
          button-style="solid"
          :default-value="$val.AuthPeriod.year"
          v-decorator="['auth_time', { }]"
        >
          <template v-for="item in AuthTimeOptions">
            <a-radio-button :value="item.value" :key="item.value">{{item.label}}</a-radio-button>
          </template>
        </a-radio-group>
      </a-form-item>

      <a-form-item :label="$t('userOrder.labelAuthType')" class="lh-unset-control">
        <a-radio-group
          :options="ActiviateMusicAuthTypeOptions.map(item => ({...item, label: `${item.label}使用`}))"
          v-decorator="['type', { initialValue: $val.ActiviateMusicAuthType.company}]"
        ></a-radio-group>
      </a-form-item>

      <h2 class="font-lg m-bottom-md">完善联系人信息</h2>

      <a-form-item :label="$t('userOrder.labelContact')">
        <a-input
          size="large"
          :placeholder="$t('userOrder.pdContactName')"
          v-decorator="['contacter', { rules: [$root.$validate.required], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>

      <a-form-item :label="$t('userOrder.labelContactMethod')">
        <a-input
          size="large"
          :placeholder="$t('userOrder.pdContactMethodOfAuth')"
          v-decorator="['contact', { rules: [$root.$validate.required, $root.$validate.phone], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>

      <h2 class="font-lg m-bottom-md">完善授权信息</h2>

      <a-form-item :label="$t('userOrder.labelAuthMain')">
        <a-input
          size="large"
          :placeholder="$t('userOrder.pdAuthMain')"
          v-decorator="['main', { rules: [$root.$validate.required], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>

      <a-form-item :label="$t('userOrder.labelAuthProject')">
        <a-input
          size="large"
          :placeholder="$t('userOrder.pdAuthProject')"
          v-decorator="['project', { rules: [$root.$validate.required], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>

      <a-form-item :label="$t('userOrder.labelAuthChannel')">
        <a-input
          size="large"
          :placeholder="$t('userOrder.labelAuthChannelPlaceholder')"
          v-decorator="['put_path', { rules: [$root.$validate.required], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>
    </a-form>

    <p class="font-sm">授权项目信息填写不清晰可能导致授权书无效，请根据项目实际情况如实且详尽的填写授权信息以保证您的合法权益。</p>

    <template slot="footer">
      <a-button
        type="primary"
        class="w-100"
        size="large"
        @click="handleOk()"
        :loading="confirmLoading"
      >{{$t('common.submit')}}</a-button>
    </template>
  </ng-dialog>
</template>

<script>
  import NgDialog from '@/components/NgDialog.vue';
  import {dialogMixins} from '@/mixins/index';
  import {ActiviateMusicAuthTypeOptions, AuthAreaOfMusicOptions, AuthTimeOptions, BusinessSceneOptions} from '@/config/options';
  import {formatTime} from '@/utils/function';
  import {Handle} from '@/utils/variables'

  const labelCol = {span: 24};
  const wrapperCol = {span: 24};

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        AuthPackageOptions: [],

        labelCol,
        wrapperCol,

        BusinessSceneOptions: [],
        AuthChannelOptions: [],
        AuthAreaOfMusicOptions: [],
        AuthTimeOptions: [],

        ActiviateMusicAuthTypeOptions,

        packageList: [],

        curAuthInfo: []
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          let goods_id = values.goods_id;

          if (goods_id) {
            // let id = goods_id.split('_');

            // id = id && id.length > 0 ? id[0] : ''

            this.setAuthInfo(goods_id)
          }
        }
      });
    },

    created() {
      this.getPackageList();
    },

    components: {
      NgDialog
    },

    computed: {
      isEdit() {
        return this.type === this.$var.Handle.edit;
      },

      requestFunc() {
        return this.isEdit ? this.$axios.AuthEdit : this.$axios.AuthAdd;
      }
    },

    props: {
      data: Object,
      ids: Array,
      type: {
        type: String,
        default: Handle.add
      }
    },

    watch: {
      curAuthInfo: {
        handler(newVal) {
          if (newVal && Object.keys(newVal).length > 0) {
            let {auth_area, auth_time, use_mode, auth_scene} = newVal;

            this.AuthChannelOptions = [{label: use_mode, value: use_mode}];
            this.BusinessSceneOptions = BusinessSceneOptions.filter(item => item.value === auth_scene);
            this.AuthAreaOfMusicOptions = AuthAreaOfMusicOptions.filter(item => item.value === auth_area);
            this.AuthTimeOptions = AuthTimeOptions.filter(item => item.value == auth_time);

            this.$nextTick(() => {
              this.form.setFieldsValue({auth_area, auth_time, use_mode, auth_scene})
            })
          }
        },
        immediate: true
      }
    },

    mixins: [dialogMixins],

    methods: {

      getPackageList() {
        this.$axios.Package_listInUser({}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let list = data.data ? data.data.list : [];

            this.list = list;

            this.AuthPackageOptions = list.map(({id, goods_name}) => ({label: goods_name, value: `${id}`}));
          }
        })
      },

      setAuthInfo(value) {
        let list = this.list;

        let finalItem = list.filter(item => item.id === value);

        if (finalItem && finalItem.length > 0) {
          this.curAuthInfo = Object.assign({}, finalItem[0] || {});
        }
      },

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            let {goods_type, license_id, music_id} = this.data;
            let goods_id = values.goods_id;
            // let id = goods_id.split('_');

            // id = id && id.length > 0 ? id[1] : ''

            values.license_ids = this.ids && this.ids.length > 0 ? this.ids : [license_id];

            if (!this.isEdit) {
              values.music_id = music_id || '';
            }

            values.goods_id = goods_id;
            values.goods_type = goods_type;

            this.ActiviateOrder(values);
          }
        });
      },

      ActiviateOrder(params) {
        this.confirmLoading = true;

        this.requestFunc(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(this.$t('userOrder.activeSuccess'));
            this.showCartDialog(false);
            this.$emit('success', data)
          }

          this.confirmLoading = false;
        })
      },

      preview() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            let scope = Object.assign({}, this.data, values);

            scope.create_at = formatTime(new Date());

            let routeUrl = this.$router.resolve({
              path: '/authbook/-1',
              query: scope,
            });

            window.open(routeUrl.href, '_blank');
          }
        });
      },

      showCartDialog(bool) {
        this.$emit('update:visible', bool);
      },

      // handleCancel() {
      //   this.showCartDialog(false);
      // },

      // closeModal() {
      //   this.showCartDialog(false);
      // }
    }
  }
</script>

<style lang="scss" scoped>
  .dialog-shop-cart {
    /deep/ {
      .ant-modal-body {
        padding-left: 50px;
        padding-right: 50px;
        min-height: 390px;
      }

      .ant-radio-group {
        margin-top: -10px;
      }

      .ant-radio-button-wrapper {
        margin-right: 15px;
        margin-top: 10px;
      }

      .ant-form-item-label {
        font-weight: bold;
      }

      .ant-form-item-control-wrapper {
        padding-left: 10px;
      }
    }
  }
</style>
