<template>
  <div class="my-collect pos-relative">
    <div class="tab-btns">
      <a-select
        class="w-100"
        :options="AllAuthOptions"
        defaultValue
        size="large"
        @change="onChange"
      ></a-select>
    </div>

    <buy-list :params="musicTableParams"></buy-list>
  </div>
</template>

<script>
  import BuyList from './components/BuyList.vue'

  import {AuthOptions} from '@/config/options';

  let AllAuthOptions = [{label: '全部音乐', value: ''}, ...AuthOptions]

  export default {
    data() {
      return {
        musicTableParams: {},
        AllAuthOptions,
      }
    },
    components: {
      // MusicTable,
      BuyList,
    },

    created() {
      let params = {};

      params.is_licensed = ''
      params.goods_type = this.$val.orderType.music;
      params.page_size = '100'

      this.musicTableParams = params;
    },

    methods: {
      onChange(status) {
        this.musicTableParams = Object.assign({}, this.musicTableParams, {is_licensed: status});
      },
    }
  }
</script>

<style lang="scss" scoped>
  .tab-btns {
    width: 120px;
  }
</style>
