<template>
  <div class="tool-wrap" >
      <span @click="toTop">
           <icon-font class="iconfont" type="iconupload"></icon-font>
           <span class="h-text">返回顶部</span>
      </span>
      <span @click="Chatra">
            
           <icon-font class="iconfont"  type="iconheadset"></icon-font>
           <span class="h-text">联系客服</span>
      </span>
      <span @click="toHlepCVenter">
           <icon-font class="iconfont help-icon" type="iconbangzhuzhongxin1"></icon-font>
           <span class="h-text">帮助中心</span>
      </span>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    components: {},
    created(){
      let vm =this;
      window.onscroll=function(){
        if (document.documentElement.scrollTop>60) {
          vm.isActive=true;
        }else {
          vm.isActive=false;
        }
      }
    },
    methods: {
       toTopAimate(){ // 动画
        let timer = setInterval(function () {
          let osTop = document.documentElement.scrollTop || document.body.scrollTop
          let ispeed = Math.floor(-osTop / 5)
          document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
          this.isTop = true
          if (osTop === 0) {
            clearInterval(timer)
          }
        },30)

      },

      toTop() {
        console.log('toTop')
        this.toTopAimate();
      },
      Chatra(){
        window.Chatra('openChat', true)
      },
      toHlepCVenter(){
        this.$router.push({
          path:'/helpCenter',
          query:{
            tab:'kehu'
          }
        })
      }

    },
  };
</script>

<style lang="scss" scoped>
  .tool-wrap{
    display: flex;
    flex-direction: column;
      .iconfont {
        font-size: 24px;
        width: 24px;
        height: 24px;
      }
      // .help-icon{
      //   font-size: px;
      //   width: 24px;
      //   height: 24px;
      // }
    span{
      // background-color: #fff;
      cursor: pointer;
      width: 48px;
      height: 40px;
      display: flex;
      padding-left: 7px;
      justify-content: center;
      align-items: center;
      &:hover{
        .iconfont{
          display: none;
        }
        .h-text{
        display: inline-block;
      }
      }
      .h-text{
        display: none;
        font-size: 14px;
      }
    }
  }
</style>
