import {
  Axios
} from '@/utils/from-common';

// 标签列表
export const Music_TagList = (param) => Axios('POST', '/web/music/labels', param);

// 全部音乐列表
export const Music_List = (param) => Axios('POST', '/web/music/list', param);

// 相似音乐列表
export const Music_ListSimilars = (param) => Axios('POST', '/web/music/similars', param);

// 音乐详情
export const Music_Info = (param) => Axios('POST', '/web/music/info', param);

// 统计用户行为，分享、下载、收藏   且区分音乐，歌单、供应商
export const Static_deed = (param) => Axios('POST', '/web/deed/save', param);

// 用户行为列表 分享、下载、收藏   且区分音乐，歌单、供应商
export const Static_deedList = (param) => Axios('POST', '/web/deed/list', param);

// 音乐售价信息
export const Music_piceInfo = (param) => Axios('POST', '/web/price/info', param);


/** **************  歌单  *************** */
// 歌单列表
export const Music_Play_List = (param) => Axios('POST', '/web/playlist/list', param);

// 歌单详情
export const Music_Play_Info = (param) => Axios('POST', '/web/playlist/info', param);

// 歌单详情
export const Play_Music_List = (param) => Axios('POST', '/web/playlist/musicList', param);

// 歌单折扣列表
export const Songlist_DiscountList = (param) => Axios('POST', '/web/playlistdct/list', param);