
<template>
  <div class="music-detail-box container">
    <a-row class="music-detail-top m-bottom-lg" :gutter="{xl: 40, md: 20}">
      <a-col :xs="24" :sm="24" :md="8" :xl="6">
        <div class="image-box">
          <div class="img-all-box">
            <ng-image class="w-100 music-logo" :src="musicInfo.logo_url" alt="thumbnail"></ng-image>
          </div>
        </div>
      </a-col>

      <a-col :xs="24" :sm="24" :md="16" :xl="18">
        <div class="font-heading-1 font-bold m-bottom-md ellipsis">{{ musicInfo.name }}</div>

        <a-space class="m-bottom-base" align="center">
          <router-link
            class="supply-name font-color-base"
            :to="`/supplierInfo/${musicInfo.suplr_id}`"
          >
            <ng-image
              class="header-thumbnail m-right-sm"
              :src="musicInfo.logo_url"
              alt="thumbnail"
            />
            <span>{{musicInfo.suplr_name}}</span>
          </router-link>
        </a-space>

        <div class="tags-wrap m-bottom-lg">
          <a-tag class="m-bottom-xs" v-for="(item, index) in tags" :key="index">{{ item.name }}</a-tag>
        </div>

        <div class="operation-btn-box flex">
          <div class="operation-btn-item left-btn">
            <a-button class="play-btn" type="primary" size="large" @click="onClickIcon">
              <icon-font :type="isPlaying?'iconroll':'iconplay'" />
              <span>{{isPlaying ? '暂停' : '播放'}}</span>
            </a-button>
          </div>
          <div class="operation-btn-item">
            <a-button class="zhuanfa-btn" size="large" @click="goShareMusic(musicInfo)">
              <icon-font type="iconshare" />
              <span>转发</span>
            </a-button>
            <a-button class="add-cart-btn" size="large" @click="goAddMusicToShopCart(musicInfo)">
              <icon-font type="iconcart-empty" />
              <span>加购</span>
            </a-button>
          </div>
          <div class="operation-btn-item">
            <a-button class="collect-btn" size="large" @click="collectMusic">
              <template v-if="musicInfo.is_collected == '0'">
                <icon-font type="iconheart-empty" />
                <span>收藏</span>
              </template>
              <template v-else>
                <icon-font type="iconheart" class="font-color-primary" />
                <span>已收藏</span>
              </template>
            </a-button>
            <a-button class="download-btn" size="large" @click="goDownloadMusic(musicInfo)">
              <icon-font type="icondownload" />
              <span>下载</span>
            </a-button>
          </div>
        </div>

        <!-- <a-space class="terrace-wrap">
          <svg class="iconfont">
            <use xlink:href="#iconheadset" />
          </svg>
          <svg class="iconfont">
            <use xlink:href="#iconheadset" />
          </svg>
          <svg class="iconfont">
            <use xlink:href="#iconheadset" />
          </svg>
          <svg class="iconfont">
            <use xlink:href="#iconheadset" />
          </svg>
        </a-space>-->
      </a-col>
    </a-row>

    <a-space class="similar-music m-bottom-lg">
      <div class="similar-music-title">
        <page-title-icon title="相似音乐"></page-title-icon>
      </div>
    </a-space>

    <music-table :request="$axios.Music_ListSimilars" :params="musicParams" is-more :pagination="false"></music-table>
  </div>
</template>

<script>
  import {Tag} from "ant-design-vue";
  import NgImage from "~/components/NgImage.vue";
  import musicHandle from '@/mixins/musicHandle'
  import PageTitleIcon from "@/pages/components/common/PageTitleIcon.vue";
  import MusicTable from '@/pages/components/music/MusicTable.vue'

  import {UpdateMusicList} from '@/mixins/music'

  export default {
    data() {
      return {
        musicInfo: {},
        tags: [],
        music_id: '',
        musicParams: {}
      };
    },
    mixins: [musicHandle, UpdateMusicList],

    components: {NgImage, ATag: Tag, PageTitleIcon, MusicTable},

    computed: {

      isPlaying() {
        let state = this.$store.state;

        if (!state.globalVideoInfo) return false;

        let {music_id, url} = state.globalVideoInfo;

        return state.globalVideoPlaying && music_id && url && this.musicInfo.music_id === music_id;
      }
    },

    watch: {
      $route(n, o) {
        if (n.params.music_id != o.params.music_id) {
          this.music_id = n.params.music_id
          this.getMusicInfo();
        }
      }
    },

    created() {
      this.music_id = this.$route.params.music_id || '';
      this.getMusicInfo();

      this.musicParams = Object.assign({}, this.musicParams, {music_id: this.music_id});
    },

    mounted() { },

    methods: {

      collectMusic() {
        this.goCollectMusic(this.musicInfo).then((scope) => {
          this.musicInfo = Object.assign({}, this.musicInfo, scope);
        });
      },

      getMusicInfo() {
        this.$axios
          .Music_Info({music_id: this.music_id})
          .then((res) => {
            const data = res.data;

            if (data && data.code == 0) {
              this.musicInfo = data.data[0] || {};

              let labels = this.getAllLabel(this.musicInfo.labels || []);

              this.tags = labels;
            }

            this.$store.commit("changeContentLoading", false);
          })
          .catch(() => {
            this.$store.commit("changeContentLoading", false);
          });
      },

      getAllLabel(data) {
        let arr = [];

        data.forEach(item => {
          arr = arr.concat(one(item.child));
        });

        return arr;

        function one(list) {
          return list.reduce((prev, next) => {
            if (next.child && next.child.length > 0) {
              prev.concat(one(next.child))
            }

            prev.push(next)

            return prev;
          }, [])
        }
      },


      onClickIcon() {
        this.isPlaying ? this.onPause() : this.onPlay();
      },

      onPlay() {
        let musicInfo = this.musicInfo;

        musicInfo.url = musicInfo.audition_url;

        this.$store.commit('changeVideoInfo', this.musicInfo);

        this.$nextTick(() => {
          this.$store.commit('changeVideoPlaying', true);
        })
      },

      onPause() {
        this.$store.commit('changeVideoPlaying', false);
      }
    }

  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .operation-btn-box {
      .anticon {
        font-size: 18px;
      }
    }
  }

  .music-detail-box {
    margin-top: 32px;
  }

  @media screen and (max-width: $screen-width-md) {
    .music-detail-top {
      .ant-col {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .image-box {
      width: 45%;
      margin-bottom: 24px;
    }

    .operation-btn-box {
      width: 100%;
      height: 100px;
      justify-content: space-between;

      .left-btn {
        height: 100%;
      }

      .ant-btn {
        margin-bottom: 20px;
        width: 100%;
      }

      .operation-btn-item {
        width: 32%;

        &:first-child {
          .ant-btn {
            height: 100%;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: $screen-width-md) {
    .operation-btn-box {
      .ant-btn {
        margin-right: $margin-space-sm;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  @media screen and (min-width: $screen-width-xl) {
    .operation-btn-box {
      .ant-btn {
        margin-right: $margin-space-md;
        padding-left: $padding-space-lg;
        padding-right: $padding-space-lg;
      }
    }
  }
</style>
