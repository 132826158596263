<template>
  <div class="pos-relative">
    <a-button
      class="add-btn"
      size="large"
      type="primary"
      @click="onHandle('add')"
    >{{$t('common.add')}}</a-button>

    <a-spin :spinning="spinning" class="w-100">
      <Detail :list="list" @onEdit="onEditHandle" @success="onSuccess"></Detail>
    </a-spin>

    <dialog-add
      :default-value="dialogFormDefault"
      :visible.sync="showHandleDialog"
      :handle="handleType"
      @success="onSuccess"
    ></dialog-add>
  </div>
</template>

<script>
  import {Spin} from 'ant-design-vue';
  import Detail from "./components/Detail";
  import DialogAdd from './components/DialogAdd.vue'

  export default {
    data() {
      return {
        showHandleDialog: false,
        dialogFormDefault: {},
        handleType: 'add',

        list: [],

        spinning: false,
      };
    },
    components: {
      Detail,
      DialogAdd,
      ASpin: Spin
    },

    created() {
      this.getData();
    },

    methods: {
      onSuccess() {
        this.getData();
      },

      onEditHandle(item) {
        item = Object.assign({}, item, {
          is_default: !!+item.is_default,
          is_special: !!+item.is_special
        })

        this.dialogFormDefault = item;

        this.onHandle(this.$var.Handle.edit);
      },

      getData() {
        this.spinning = true;

        this.$axios.Company_list().then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            // something
            this.list = data.data.list;
          }

          this.spinning = false;
        })
      },

      onHandle(type) {
        this.handleType = type;
        this.showHandleDialog = true;
      },
    }
  };
</script>

<style lang="scss" scoped>
</style>
