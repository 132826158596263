import Vue from 'vue';
import VueRouter from 'vue-router';

import UserRoute from './user';
import MainRoute from './main';
import CartRoute from './cart';
// import OrderRoute from './order';
import PayRoute from './pay';
import MusicRoute from './music';
import HelpCenter from './helpCenter';
import Auth from './auth';
import AboutUs from './aboutUs';
import MyNews from './myNews';
import termsOfService from './termsOfService'; // 服务条款
import bestWorks from './bestWorks'; // footer 的精彩案例
import search from './search'; // 
import industryInformation from './industryInformation'; // 

import activity from './activity'; // 活动页面

// 解决跳转路由报不能跳转同一路由的错误
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

Vue.use(VueRouter);

const Index = () => import('@/pages/index.vue');
// const Login = () => import('@/pages/login.vue');
const Common403 = () => import('@/pages/common/403.vue');
const Common404 = () => import('@/pages/common/404.vue');
const AuthBook = () => import('@/pages/common/AuthBook.vue');

let routes = [{
    path: '/',
    component: Index,
    redirect: '/musicFine',
    children: [
      ...MainRoute,
      // {
      //   path: '/login',
      //   name: 'login',
      //   component: Login,
      // },
      {
        path: '/404',
        component: Common404
      },
      ...UserRoute,
      ...CartRoute,
      // ...OrderRoute,
      ...PayRoute,
      ...MusicRoute,
      ...HelpCenter,
      ...MyNews,
      ...Auth,
      ...AboutUs,
      ...termsOfService,
      ...bestWorks,
      ...search,
      ...industryInformation,
      ...activity
    ]
  },
  {
    path: '/403',
    component: Common403
  },

  {
    path: '/authbook/:auth_id',
    meta: {
      isLogin: true
    },
    component: AuthBook
  },

]

const router = new VueRouter({
  routes,
  // 如果修改模式，同时需要修改分享链接和后台生成的pdf对应链接
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    from.name ? next({
      name: from.name
    }) : next('/404');
  } else {
    next(); //如果匹配到正确跳转
  }
})


export default router