<template>
  <div class="my-collect pos-relative">
    <a-radio-group class="tab-btns" v-model="curRadio" button-style="solid" @change="onChange">
      <a-radio-button
        v-for="item in RadioOptions"
        :value="item.value"
        :key="item.value"
      >{{item.label}}</a-radio-button>
    </a-radio-group>

    <music-table :request="$axios.Static_deedList" :params="musicTableParams" :pagination="false"></music-table>
  </div>
</template>

<script>
  import MusicTable from '@/pages/components/music/MusicTable.vue';
  import {DeedType} from '@/config/options.value';

  const RadioOptions = [
    {
      label: '试用下载记录',
      value: DeedType.download
    },
    {
      label: '试听记录',
      value: DeedType.tryListen
    }
  ]

  export default {
    data() {
      return {
        text: {},
        curRadio: RadioOptions[0].value,
        musicTableParams: {},

        RadioOptions
      }
    },
    components: {
      MusicTable,
      // CollectSongList,
    },

    created() {
      this.musicTableParams = {obj_type: this.$val.ShareAndCollectType.music, deed_type: this.curRadio};
    },

    methods: {
      onChange(event) {
        let target = event.target;
        let value = target.value;

        this.musicTableParams.deed_type = value;
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
