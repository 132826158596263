import {
  Axios
} from '@/utils/from-common';
import {
  DownloadTimeout
} from '@/config/config'
import axios from 'axios';

const CancelToken = axios.CancelToken;

// 订单列表
export const OrderList = (param) => Axios('POST', '/web/order/list', param);

// 订单详情
export const OrderInfo = (param) => Axios('POST', '/web/order/info', param);

// 添加订单
export const AddToOrder = (param) => Axios('POST', '/web/order/add', param);

// 重新下单
export const ReAddToOrder = (param) => Axios('POST', '/web/order/readd', param);

// 取消订单
export const CancelOrder = (param) => Axios('POST', '/web/order/cancel', param);

// 删除订单
export const DelSingleOrderDetail = (param) => Axios('POST', '/web/order/del', param);

// 支付订单
export const PayOrder = (param) => Axios('POST', '/web/order/prepay', param);

// 公对公支付 单曲
export const PayOrder_Com = (param) => Axios('POST', '/web/order/orgPay', param);

// 支付验证
export const PayCheck = (param, callback) => Axios('POST', '/web/order/payCheck', param, {
  timeout: 30 * 1000,
  cancelToken: new CancelToken(function (c) {
    typeof callback === 'function' && callback(c);
  })
});

// 支付套餐
export const PayPackage = (param) => Axios('POST', '/web/goodsorder/prepay', param);

// 公对公支付 套餐
export const PayPackage_Com = (param) => Axios('POST', '/web/goodsorder/orgPay', param);

// 支付套餐验证
export const PayPackageCheck = (param, callback) => Axios('POST', '/web/goodsorder/payCheck', param, {
  timeout: 30 * 1000,
  cancelToken: new CancelToken(function (c) {
    typeof callback === 'function' && callback(c);
  })
});

// 下载用户授权
export const DownloadAuth = (param) => Axios('POST', '/web/license/download', param, {
  timeout: DownloadTimeout,
  responseType: 'blob'
});

// 添加授权
export const AddAuth = (param) => Axios('POST', '/web/license/add', param);

// 授权信息
export const AuthInfo = (param) => Axios('POST', '/web/license/info', param);



// 申请发票
export const Order_receiptAdd = (param) => Axios('POST', '/web/orderinv/add', param);

// 发票 信息
export const Order_receiptInfo = (param) => Axios('POST', '/web/orderinv/info', param);

// 申请 合同
export const Order_contractAdd = (param) => Axios('POST', '/web/ordercontr/add', param);

// 合同 信息
export const Order_contractInfo = (param) => Axios('POST', '/web/ordercontr/info', param);