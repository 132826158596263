<template>
  <!-- 
    带标题
    右侧带各种操作
    鼠标移动上去显示操作
  -->
  <div class="ng-list-item">
    <div class="header w-100 pos-relative operation-item">
      <div class="header-left">
        <slot name="header"></slot>
      </div>
      <div class="operation-name pos-center-right">
        <slot name="topRight"></slot>
      </div>
    </div>
    <div class="body pos-relative">
      <div class="body-left">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    components: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .ng-list-item {
    .header {
      line-height: 40px;

      &::after {
        content: " ";
        position: absolute;
        bottom: 0;
        left: -20px;
        right: -20px;
        height: 1px;
        background-color: $border-color;
      }
    }

    .body {
      padding: 10px 0;
    }
  }
</style>
