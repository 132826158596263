<template>
  <div class="user-detail">
    <div class="top-title back-grey p-bottom-md p-top-md m-bottom-base">
      <div class="container-md">
        <h1>账号与安全</h1>
      </div>
    </div>

    <div class="main">
      <div class="container-md">
        <a-tabs :default-active-key="defaultKey" :animated="false" @change="onChangeTab">
          <a-tab-pane :key="$val.UserMenu.basic" tab="基本信息">
            <user-basic></user-basic>
          </a-tab-pane>
          <a-tab-pane :key="$val.UserMenu.secure" tab="安全绑定">
            <user-secure></user-secure>
          </a-tab-pane>
          <a-tab-pane :key="$val.UserMenu.password" tab="修改密码">
            <user-password></user-password>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import UserBasic from './basic/Index.vue';
  import UserSecure from './secure/Index.vue';
  import UserPassword from './password/Index.vue';

  export default {
    data() {
      return {
        defaultKey: ''
      }
    },

    created() {
      let menukey = this.$route.query.menukey;

      this.defaultKey = menukey ? menukey : this.$val.UserMenu.basic;
    },
    components: {
      UserBasic,
      UserSecure,
      UserPassword
    },
    methods: {
      onChangeTab(newVal) {
        this.$root.$goSearch({
          menukey: newVal
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user-detail {
    .main {
      padding-bottom: 40px;
    }
  }
</style>
