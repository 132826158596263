import {
  Axios
} from '@/utils/from-common';
import {
  $storage
} from '@/utils/from-common'
import * as $var from '@/utils/variables'
import $axios from 'axios';
import * as $config from '~/config/config.c';

let baseURL = $config.ServeBaseHost;

export * from '~/api/api.c';
export * from './supplier'
export * from './user'
export * from './setting'
export * from './music'
export * from './cart'
export * from './order'
export * from './auth'
export * from './package'
export * from './packageOrder'
export * from './musicFine'
export * from './coupon'

// 公共音乐标签
export const CommonLabels = (param) => Axios('POST', '/web/common/labels', param);

// 标签音乐数量
export const CommonMusicNumber = (param) => Axios('POST', '/web/common/labelMusicNum', param);

// 获取用户登录信息
export const UserInfo = (params) => {
  let token = $storage.get($var.STORAGE.TOKEN);

  return $axios.post(`${baseURL}/web/user/info`, params, {
    headers: {
      Token: token
    },
  });
}