<template>
  <div class="look-more-box flex cen-center" @click="handleMore">
    <span class="hidden-sm">查看全部</span>
    <div class="more-icon-box">
       <icon-font  type="iconbtnnormalchakangengduo" />
     
      
      
      <!-- <svg class="iconfont iconfont-more">
        <use xlink:href="#iconbtnnormalchakangengduo" />
      </svg> -->
    </div>
    <div class="more-icon-box1">
       <icon-font  type="iconbtnnormalchakangengduo-copy" />
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    components: {},
    methods: {
      handleMore() {
        this.$emit('handleMore')
      }

    },
  };
</script>

<style lang="scss" scoped>
  .look-more-box {
    cursor: pointer;
     &:hover{
       .more-icon-box{
         display: none;

       }
       .more-icon-box1{
          display: flex;
       }
      }
      
    .more-icon-box {
      font-size: 1.4rem;
      // color: #fff;
      // width: 3.6rem;
      // height: 3.6rem;
      // background: rgba(0, 0, 0, 0.85);
      // border-radius: 50% 50%;
      // color:  #F06B03;
      //  fill:#F06B03;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.8rem;
     
    }
    .more-icon-box1 {
      font-size: 1.4rem;
      // color: #fff;
      // width: 3.6rem;
      // height: 3.6rem;
      // background: rgba(0, 0, 0, 0.85);
      // border-radius: 50% 50%;
      // color:  #F06B03;
      //  fill:#F06B03;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.8rem;
      display: none;
     
    }
  }
</style>
