<template>
  <div class="my-collect pos-relative">
    <div class="tab-btns">
      <a-select class="w-100" :options="OrderOptions" defaultValue size="large" @change="onChange"></a-select>
    </div>

    <order-list :request="$axios.Static_deedList" :params="musicTableParams" :isAuth="isAuth"></order-list>
  </div>
</template>

<script>
  import OrderList from './components/OrderList.vue'
  import {OrderStatus} from '@/config/options.value'

  const OrderOptions = function () {
    return [
      {
        label: this.$t('userOrder.allOrder'),
        value: ''
      },
      {
        label: this.$t('userOrder.menuDone'),
        value: OrderStatus.didPay
      },
      {
        label: this.$t('userOrder.menuDoningPay'),
        value: OrderStatus.doingPay
      },
      {
        label: this.$t('userOrder.menuUndoPay'),
        value: OrderStatus.undoPay
      }
    ]
  }

  export default {
    data() {
      return {
        musicTableParams: {},
        OrderOptions: OrderOptions.call(this),
        isAuth: true
      }
    },
    components: {
      // MusicTable,
      OrderList,
    },

    created() {
      let params = {};

      params.type = '';
      // params.status = '';

      this.musicTableParams = params;
    },

    methods: {
      onChange(type) {
        this.musicTableParams = Object.assign({}, this.musicTableParams, {type});
      },
    }
  }
</script>

<style lang="scss" scoped>
  .tab-btns {
    width: 120px;
  }
</style>
