<template>
  <div class="nodify-form-wrap">
    <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol" class="ng-form">
      <!-- 输入框 -->
      <a-form-item :label="$t('userSet.pass_oldPasswrod')">
        <a-input-password
          size="large"
          :placeholder="$t('template.selectSth', {name: $t('userSet.pass_oldPasswrod')})"
          v-decorator="['name', { rules: [$validate.required], initialValue: '' }]"
        />
      </a-form-item>

      <!-- 输入框 -->
      <a-form-item :label="$t('userSet.pass_newPasswrod')">
        <a-input-password
          size="large"
          :placeholder="$t('template.selectSth', {name: $t('userSet.pass_newPasswrod')})"
          v-decorator="['password', { rules: [$root.$validate.required] }]"
        />
      </a-form-item>

      <!-- 输入框 -->
      <a-form-item :label="$t('userSet.pass_confirmNewPasswrod')">
        <a-input-password
          size="large"
          :placeholder="$t('template.typeAgainSth', {name: $t('userSet.pass_newPasswrod')})"
          v-decorator="['repassword', { rules: [$root.$validate.required, {validator: compareToFirstPassword}] }]"
        />
      </a-form-item>
    </a-form>
  </div>
</template>
<script>

  const labelCol = {span: 24};
  const wrapperCol = {span: 24};

  export default {
    data() {
      return {
        form: this.$form.createForm(this, "nodify"),
        labelCol,
        wrapperCol,
      };
    },

    components: {
    },

    methods: {
      compareToFirstPassword() {
        this.$root.$validate.confirm_pass_to_first(this.form, ...arguments);
      },
    }
  };
</script>
<style lang="scss" scoped>
  .nodify-form-wrap {
    width: 100%;
    .ant-form-item-label {
      margin-right: 20px !important;
    }
    .ant-form-item-control-wrapper {
      margin-left: 20px;
    }
  }

  .upload-box {
    // display: block;
    // width: 80px;
    // height: 80px;
    // border-radius: 100%;
    // overflow: hidden;

    /deep/ .ant-upload {
      border: 0;
      width: 80px;
      height: 80px;
      padding: 0;
      background-color: #ddd;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

      img[alt="avatar"] {
        width: 100%;
        height: 100%;
      }

      .close-icon {
        transform: translate(10px, 0px);
      }
    }

    .iconfont {
      width: 60%;
      height: 60%;
    }
  }

  .ant-upload-picture-card-wrapper {
    width: auto;
  }
</style>