<template>
  <ng-dialog :visible.sync="show" :bodyStyle="bodyStyle" @close="$emit('update:visible', false)">
    <template v-slot:title>
      <h1 class="ant-drawer-title m-bottom-xs">填写发票信息</h1>
      <div class="font-base lh-1-5">请先填写开具发票所必需的信息</div>
    </template>

    <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <h2 class="font-lg m-bottom-md">订单信息</h2>

      <!-- <a-form-item :label="$t('userOrder.orderNumber')">
        <a-input
          size="large"
          :placeholder="$t('userOrder.orderNumberPlaceholder')"
          v-decorator="['invoice_id', { validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item> -->

      <a-form-item label="抬头类型" class="lh-unset-control">
        <a-radio-group
          :options="ReceiptHeaderTypeOptions"
          v-decorator="['title_type', { rules: [$validate.required]}]"
        ></a-radio-group>
      </a-form-item>

      <a-form-item label="发票类型" class="lh-unset-control">
        <a-radio-group
          :options="ReceiptTypeOptions"
          v-decorator="['invoice_type', { rules: [$validate.required]}]"
        ></a-radio-group>
      </a-form-item>

      <a-form-item label="发票抬头">
        <form-receipt
          size="large"
          placeholder="请选择发票抬头"
          @add="$router.push(`/user/detail?menukey=${$val.UserMenu.receiptHeader}`)"
          v-decorator="['invoice_id', { rules: [$validate.required]}]"
        ></form-receipt>
      </a-form-item>

      <a-form-item label="收件人信息">
        <form-address
          size="large"
          placeholder="请选择收件人信息"
          @add="$router.push(`/user/detail?menukey=${$val.UserMenu.address}`)"
          v-decorator="['express_id', { rules: [$validate.required]}]"
        ></form-address>
      </a-form-item>
    </a-form>

    <template slot="footer">
      <a-button
        type="primary"
        class="w-100"
        size="large"
        @click="handleOk()"
        :loading="confirmLoading"
      >提交发票信息</a-button>
    </template>
  </ng-dialog>
</template>

<script>
  import NgDialog from '@/components/NgDialog.vue';
  import FormReceipt from '@/components/FormReceipt.vue'
  import FormAddress from '@/components/FormAddress.vue'
  import {dialogMixins} from '@/mixins/index';
  import {ReceiptHeaderTypeOptions, ReceiptTypeOptions} from '@/config/options';

  const labelCol = {span: 24};
  const wrapperCol = {span: 24};

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        labelCol,
        wrapperCol,

        ReceiptHeaderTypeOptions,
        ReceiptTypeOptions
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
      });
    },

    components: {
      NgDialog,
      FormReceipt,
      FormAddress
      // VNodes: {
      //   functional: true,
      //   render: (h, ctx) => ctx.props.vnodes,
      // },
    },

    props: {
      data: Object,
    },

    mixins: [dialogMixins],

    methods: {

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            values.order_id = this.data.order_id

            this.applyReceipt(values);
          }
        });
      },

      applyReceipt(params) {
        this.confirmLoading = true;

        this.$axios.Order_receiptAdd(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('申请成功');
            this.showCartDialog(false);
            this.$emit('success', data)
            this.$emit('loadlist', data)
          }

          this.confirmLoading = false;
        })
      },

      showCartDialog(bool) {
        this.$emit('update:visible', bool);
      },
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .ant-radio-group {
      .ant-radio-wrapper {
        margin-right: 6px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
</style>
