<template>
  <main class="container-xs">
    <div class="price-info m-bottom-sm">
      <b class="font-color-primary font-heading-2">¥{{scope.total_amount | toPrice}}</b>
      <div>实付金额</div>
    </div>

    <a-divider></a-divider>

    <a-descriptions :title="`${scope.goods_name}·${scope.auth_num}首`" bordered :column="1" class="border-none table-layout-fixed">
      <a-descriptions-item label="使用渠道">{{scope.use_mode}}</a-descriptions-item>
      <a-descriptions-item label="使用次数">{{scope.auth_num}}次</a-descriptions-item>
      <a-descriptions-item label="使用地域">{{scope.auth_area | toArea}}</a-descriptions-item>
      <a-descriptions-item label="使用期限">{{scope.auth_time | toAuthPeriod}}</a-descriptions-item>
    </a-descriptions>
  </main>
</template>

<script>
  import {Descriptions, Divider} from 'ant-design-vue';
  export default {
    data() {
      return {

      }
    },

    props: {
      scope: {
        type: Object,
        default() {
          return {}
        }
      },
      status: String
    },
    components: {
      ADescriptions: Descriptions,
      ADescriptionsItem: Descriptions.Item,
      ADivider: Divider,

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
</style>
