const User = () => import('@/pages/user/User.vue');
const UserDetail = () => import('@/pages/user/UserDetail.vue');
const UserAccount = () => import('@/pages/user/UserAccount.vue');

export default [{
    path: '/user',
    name: 'user',
    component: User,
    meta: {
      isLogin: true
    },
  },
  {
    path: '/user/detail',
    name: 'userdetail',
    meta: {
      isLogin: true
    },
    component: UserDetail,
  },
  {
    path: '/user/account',
    name: 'useraccount',
    meta: {
      isLogin: true
    },
    component: UserAccount,
  }
]