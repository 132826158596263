<template>
  <span
    :class="['music-play-icon', isPlaying ? 'playing' : '', isMask ? 'mask-type' : 'default-type']"
    @click="onClickIcon"
  >
    <icon-font v-if="!isPlaying" class="icon" type="iconplay"></icon-font>
    <!-- <icon-font v-else-if="!isMask" class="icon" type="iconroll"></icon-font> -->
    <icon-font v-else class="icon" type="iconpause"></icon-font>
  </span>
</template>

<script>
  import musicHandle from '@/mixins/musicHandle'

  export default {
    data() {
      return {

      }
    },

    mixins: [musicHandle],

    props: {
      scope: {
        type: Object,
        default() {
          return {}
        }
      },
      list: {
        type: Array,
        default() {
          return []
        }
      },
      isMask: Boolean
    },

    computed: {
      isPlaying() {
        let state = this.$store.state;

        if (!state.globalVideoInfo) return false;

        let {music_id, url} = state.globalVideoInfo;

        return state.globalVideoPlaying && music_id && url && this.scope.music_id === music_id;
      }
    },

    methods: {
      onClickIcon() {
        this.isPlaying ? this.onPause() : this.onPlay();

        this.$store.commit('changeVideoList', this.list || []);
      },

      onPlay() {
        let musicInfo = this.scope;

        musicInfo.url = musicInfo.audition_url;

        this.$store.commit('changeVideoInfo', this.scope);

        this.$nextTick(() => {
          this.$store.commit('changeVideoPlaying', true);
        })

        this.goPlayMusic(musicInfo);
      },

      onPause() {
        this.$store.commit('changeVideoPlaying', false);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .music-play-icon {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .music-play-icon.default-type {
    border: 1px solid rgba(0, 0, 0, 0.55);
    border-radius: 100%;
    font-size: 100%;
    cursor: pointer;

    &.playing {
      background-color: $primary-color;
      color: #fff;
      border-color: darken($color: $primary-color, $amount: 1);
    }
  }

  .music-play-icon.mask-type {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    text-align: center;
    z-index: 3;
    transition: opacity linear 0.2s;
    font-size: 34px;

    &:hover {
      opacity: 1;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.55);
    }

    &.playing {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.55);
      color: #fff;
    }
  }
</style>
