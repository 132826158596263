<template>
  <div class="detail-main">
    <a-table
      class="empty-hover back-grey-gap"
      :dataSource="list"
      :columns="columns"
      :pagination="false"
      :showHeader="false"
    >
      <template v-slot:operation="text, item">
        <!-- 设为默认 -->
        <!-- <icon-font
          class="font-heading-2 m-right-base"
          type="iconedit"
          @click="onDefault(item)"
        ></icon-font>-->

        <icon-font
          class="font-heading-2 icon-font"
          type="iconedit"
          @click="handleItem($var.Handle.edit, item)"
        ></icon-font>
        <icon-font
          class="font-heading-2"
          type="icontrash"
          @click="handleItem($var.Handle.del, item)"
        ></icon-font>
      </template>
    </a-table>
  </div>
  <!-- <div class="detail-main flex flex-column cen-start">
    <template v-if="list && list.length > 0">
      <template v-for="(item, index) in list">
        <ng-card :key="index">
          <div class="detail-content m-bottom-ms">
            <h3>
              <span class="title">{{item.name || '-'}}</span>
              <span
                class="sub-title font-xs"
              >{{`${item.phone_code_item ? item.phone_code_item.prefix : ''} - ${item.phone}` || '-'}}</span>

              <a-tag v-if="item.is_default == '1'" color="black">{{$t('common.default')}}</a-tag>
            </h3>
            <div class="m-bottom-ms font-xs">{{item.address || '-'}} {{item.post_code || '-'}}</div>
          </div>

          <div class="handle-btns flex" slot="right">
            <a-button
              v-if="item.is_default != '1'"
              type="link"
              @click="onDefault(item)"
            >{{$t('userSet.addr_setDefaultAddr')}}</a-button>
            <a-button
              type="link"
              @click="handleItem($var.Handle.edit, item)"
            >{{$t('common.change1')}}</a-button>
            <a-button
              type="link"
              class="ant-btn-error"
              @click="handleItem($var.Handle.del, item)"
            >{{$t('common.del')}}</a-button>
          </div>
        </ng-card>
      </template>
    </template>
    <template v-else>
      <div class="empty flex cen-center">{{$t('userSet.addr_tipEmpty')}}</div>
    </template>
  </div>-->
</template>
<script>
  import {Table} from 'ant-design-vue';
  import * as $col from '@/config/columns'

  const columns = [
    $col.commonKey('', 'name', {ellipsis: true}),
    $col.commonKey('', 'operation', {isSlot: true, width: '90px'})
  ]

  export default {
    data() {
      return {
        columns
      }
    },

    props: {
      list: Array
    },

    components: {
      ATable: Table
    },

    created() {
      // this.getData();
    },
    methods: {

      handleItem(type, item) {
        let vm = this;

        if (type === this.$var.Handle.del) {
          this.$confirm({
            width: '480px',
            title: this.$t('template.confirmDel', {name: this.$t('userSet.addr_addrInfo')}),
            onOk() {
              return new Promise(resolve => {
                vm.onDel(item, resolve);
              });
            }
          });
        } else if (type === this.$var.Handle.edit) {
          this.$emit('onEdit', item);
        }
      },

      onDefault(item) {
        let that = this;

        this.$confirm({
          width: '480px',
          title: this.$t('userSet.addr_confirmSetDefAddr'),
          onOk() {
            return new Promise(resolve => {
              that.setDefault(item, resolve);
            });
          }
        });
      },

      setDefault(item, next) {
        let params = {
          express_id: item.express_id
        };

        this.$axios.Address_setDefault(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(this.$t('userSet.addr_setSuccess'));

            next();

            this.$emit('success', data.data);
          }
        })
      },

      onDel(item, next) {
        let params = {
          express_id: item.express_id
        };

        this.$axios.Address_del(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(this.$t('userSet.addr_delSuccess'));

            next();

            this.$emit('success', data.data);
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .detail-main {
    .title,
    .sub-title {
      margin-right: 20px;
    }
  }
</style>