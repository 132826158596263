<template>
  <ng-dialog :visible.sync="show" :bodyStyle="bodyStyle" :footer="null">
    <template v-slot:title>
      <h1 class="ant-drawer-title m-bottom-xs">Spark Music 音乐购买须知</h1>
    </template>

    <p>请仔细阅读下列使用条款。凡是在Spark版权平台（以下简称“本平台”）存在购买行为或将所购买作品投入使用的，将被视作已接受以下全部条款。</p>
    <p class="m-bottom-lg">
     1. 一旦您的订单支付完成，您将在授权期限内享有所购买的音频文件及其他产品的非独家使用权。
    </p>
    <p class="m-bottom-lg">
     2. 本平台的免责事项
    </p>
    <p class="m-bottom-lg">
     请您在购买后及时下载所购买作品并获取对应作品的授权书，音乐人或版权方有权随时对其享有著作权的作品进行下架，若因您未及时下载而产生损失的，本平台将不负任何责任，但本平台会协助您尽可能的降低您的损失。
    </p>
    <p class="m-bottom-lg">
     3.退换货原则
    </p>
    <p class="m-bottom-lg">
     因本平台产品是以电子文件形式销售的，所以在您订单完成并获取音频文件后，本平台将不予退换，但存在以下特殊情况的，您可以联系本平台，本平台会协助您尽可能的降低您的损失：
    </p>
    <p class="m-bottom-lg">
     a)如果是由于您误操作，购买了错误的音乐文件，且并未开始使用，原则上是可以联系本平台进行换货服务，换货的时间应在购买行为发生后十二小时内发起，否则本平台有权不予受理。因此，在购买本平台产品前，请确保您和您的合作伙伴能够达成购买意见一致，以避免不必要的纠纷。
    </p>
    <p class="m-bottom-lg">
     b）如果由于您误操作，导致同一订单重复付款的，您可在购买行为发生后十二小时内联系本平台，本平台在核实确认您的确实存在重复付款的情况后，将在5个工作日内退还您重复支付的款项。
    </p>
    <p class="m-bottom-lg">
    4. 关于知识产权
    </p>
    <p class="m-bottom-lg">
     a)您购买的音乐作品的知识产权并不因您的购买行为发生任何变化。
    </p>
    <p class="m-bottom-lg">
    b)您购买的音乐作品，只能使用在该购买用途和授权范围的单一项目中，您不能向第三方出售该音乐作品的使用权。
    </p>
    <p class="m-bottom-lg">
     c)您购买的音乐作品，须符合您项目的实际用途和使用范围，我们会对该音乐作品的使用情况进行审查与追踪。
    </p>
    <p class="m-bottom-lg">
    d)如果您发现在本平台上有任何侵犯第三者知识产权的行为，请随时与我们联络，我们会立即以法律手段追究其责任。
    </p>

    
  </ng-dialog>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  // import FormCompany from '@/components/FormCompany.vue'
  // import FormAddress from '@/components/FormAddress.vue'
  import NgDialog from '@/components/NgDialog.vue'

  const labelCol = {span: 24};
  const wrapperCol = {span: 24};

  const Progress = (type, order) => {
    return [
      {
        step: '01',
        content: `授权项目信息`,
        key: order.first
      },
      {
        step: '02',
        content: `合同的必要信息`,
        key: order.second
      }
    ]
  }

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        labelCol,
        wrapperCol,

        proStep: this.$var.Common.first, // '1' 第一步  '2' 第二步
      }
    },

    props: {
      data: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
      });
    },

    computed: {
      btnText() {
        return this.proStep == this.$var.Common.first ? '下一步' : '确认'
      },

      progressList() {
        return Progress(this.type, this.$var.Common)
      },
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.proStep = this.$var.Common.first;
        }
      }
    },

    mixins: [dialogMixins],

    components: {
      // FormCompany,
      // FormAddress,
      NgDialog,
    },

    methods: {

      handleOk() {
        if (this.proStep == this.$var.Common.first) {
          this.submitFirstStep();
        }
        else if (this.proStep == this.$var.Common.second) {
          this.submitSecondStep();
        }
      },

      submitFirstStep() {
        this.form.validateFieldsAndScroll((errors) => {

          if (!errors) {
            this.proStep = this.$var.Common.second;
          }
        })
      },

      submitSecondStep() {
        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            this.confirmLoading = true;

            this.$axios.Account_bindEmail(values).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.$message.success(this.handle === this.$var.Handle.add ? this.$t('userSet.ac_tipBindSuccess') : this.$t('userSet.ac_tipChangeSuccess'));
                this.$emit('update:visible', false);
                this.$emit('success');
              }

              this.confirmLoading = false;
            }).catch(() => {
              this.confirmLoading = false;
            })
          }
        })
      },

      handleCancel() {

      },
    }
  }
</script>

<style lang="scss" scoped>
  .progress-wrapper {
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 20px;
    color: rgba($color: #000000, $alpha: 0.25);

    .progress-item {
      padding: 10px 0;

      &.active {
        color: $primary-color;
      }
    }

    .step-icon {
      width: 16px;
      height: 16px;
    }
  }

  @media screen and(min-width: $screen-width-md) {
    .progress-wrapper {
      justify-content: space-around;
    }
  }

  @media screen and(max-width: $screen-width-md) {
    .progress-wrapper {
      justify-content: space-between;
    }
  }
</style>
