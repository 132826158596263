<template>
  <ng-dialog
    :type="statusDir.dialogStatus"
    :visible.sync="show"
    :bodyStyle="bodyStyle"
    :footer="null"
    @close="$emit('update:visible', false)"
  >
    <template v-slot:title>
      <dialog-header
        :status="statusDir.headerStatus"
        :title="statusDir.headerTitle"
        :subTitle="statusDir.headerSubTitle"
        :subText="statusDir.headerSubText"
      ></dialog-header>
    </template>

    <main>
      <a-descriptions bordered :column="1" class="border-none table-layout-fixed m-bottom-md">
        <template v-if="contractStatus === Status.wait">
          <a-descriptions-item label="合同申请编号">{{dataInfo.number}}</a-descriptions-item>
        </template>
        <a-descriptions-item label="申请时间">{{dataInfo.create_at}}</a-descriptions-item>
      </a-descriptions>

      <a-descriptions
        bordered
        :column="1"
        title="甲方信息"
        class="border-none table-layout-fixed m-bottom-md"
      >
        <a-descriptions-item label="公司名称">{{dataInfo.company ? dataInfo.company.name : ''}}</a-descriptions-item>
        <a-descriptions-item label="公司地址">{{dataInfo.company ? dataInfo.company.address : ''}}</a-descriptions-item>
        <a-descriptions-item label="联系人姓名">{{dataInfo.company ? dataInfo.company.contacter : ''}}</a-descriptions-item>
        <a-descriptions-item label="联系电话">{{dataInfo.company ? dataInfo.company.phone : ''}}</a-descriptions-item>
        <a-descriptions-item label="联系邮箱">{{dataInfo.company ? dataInfo.company.email : ''}}</a-descriptions-item>
      </a-descriptions>

      <a-descriptions
        bordered
        :column="1"
        title="收件人信息"
        class="border-none table-layout-fixed m-bottom-md"
      >
        <a-descriptions-item label="邮寄地址">{{dataInfo.express ? dataInfo.express.address : ''}}</a-descriptions-item>
        <a-descriptions-item label="收件人姓名">{{dataInfo.express ? dataInfo.express.name : ''}}</a-descriptions-item>
        <a-descriptions-item label="收件人手机号">{{dataInfo.express ? dataInfo.express.phone : ''}}</a-descriptions-item>
      </a-descriptions>

      <a-descriptions
        bordered
        :column="1"
        title="项目信息"
        class="border-none table-layout-fixed m-bottom-md"
      >
        <a-descriptions-item label="使用项目">{{dataInfo.project}}</a-descriptions-item>
        <a-descriptions-item label="项目投放">{{dataInfo.project_path}}</a-descriptions-item>
        <a-descriptions-item label="项目描述">{{dataInfo.project_desc}}</a-descriptions-item>
      </a-descriptions>

      <a-descriptions
        bordered
        :column="1"
        title="订单信息"
        class="border-none table-layout-fixed m-bottom-md"
      >
        <a-descriptions-item label="订单发起时间">{{dataInfo.order ? dataInfo.order.create_at : ''}}</a-descriptions-item>
        <a-descriptions-item label="订单编号">{{dataInfo.order ? dataInfo.order.order_no : ''}}</a-descriptions-item>
        <a-descriptions-item label="订单金额">{{dataInfo.order ? dataInfo.order.pay_amount : ''}}</a-descriptions-item>
      </a-descriptions>

      <h3 class="font-bold m-bottom-md">购买曲目及授权信息</h3>

      <template v-if="dataInfo.order && dataInfo.order.detail && dataInfo.order.detail.length > 0">
        <div v-for="cItem in dataInfo.order.detail" :key="cItem.detail_id">
          <music-detail-item :data="cItem">
            <template v-slot:top="{item}">
              <b class="f-r">￥{{item.price | toPrice}}</b>
            </template>
          </music-detail-item>
        </div>
      </template>
    </main>
  </ng-dialog>
</template>

<script>
  import {Descriptions} from 'ant-design-vue';
  import DialogHeader from './DialogHeader.vue'
  import NgDialog from '@/components/NgDialog.vue';
  import MusicDetailItem from '@/pages/components/music/MusicDetailItem.vue';
  import {dialogMixins} from '@/mixins/index';
  import OrderHeaderMixins from '../_mixin';

  const Status = {
    success: 'success',
    wait: 'wait',
    init: 'init'
  }

  export default {
    data() {
      return {
        show: false,
        Status,
        dataInfo: {}
        // showDialog: false
      }
    },

    props: {
      visible: Boolean,
      data: Object,
      contract_id: String
    },

    mixins: [dialogMixins, OrderHeaderMixins],

    watch: {
      visible: {
        handler(newVal) {
          this.show = newVal;
        },
        immediate: true
      },
      contract_id: {
        handler(newVal) {
          newVal && this.getContractInfo({contract_id: newVal})
        },
        immediate: true
      }
    },

    computed: {
      contractStatus() {
        let status = this.dataInfo.is_operated;

        return status === this.$val.CommonCheck.fail ? Status.wait : status === this.$val.CommonCheck.correct ? Status.success : Status.init;
      },

      statusDir() {
        let ot = this.contractStatus;
        let headerStatus = 'wait';
        let dialogStatus = 'primary';
        let headerTitle = '合同申请中';
        let headerSubTitle = '';
        let headerSubText = '管理员会在 5 个工作日内向你邮寄授权合同, 请注意查收';

        if (ot === Status.success) {
          headerStatus = 'success';
          dialogStatus = 'success';
          headerTitle = '合同申请成功';
          headerSubTitle = '订单编号';
          headerSubText = this.data.order_no;
        }

        return {headerStatus, dialogStatus, headerTitle, headerSubTitle, headerSubText}
      },
    },

    components: {
      NgDialog,
      DialogHeader,
      ADescriptions: Descriptions,
      ADescriptionsItem: Descriptions.Item,
      MusicDetailItem
    },
    methods: {
      getContractInfo(params) {
        this.$axios.Order_contractInfo(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            // something
            this.dataInfo = data.data ? data.data : {}
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pay-success {
    .header {
      height: 200px;

      .icon-font {
        /deep/ {
          svg {
            width: 72px;
            height: 72px;
          }
        }
      }
    }
  }
</style>
