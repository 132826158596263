// 分享、下载、收藏、加入购物车
import {
  copyStr,
} from '@/utils/function'

// 1 音乐, 2 歌单, 3 音乐人
// const typeKey = {
//   music: '1',
//   songlist: '2',
//   songer: '3'
// }

// const SoundEffect = {
//   common: '1', // 试听，一般
//   high: '2', // 高清
// }

const OperationDir = {
  save: '1',
  cancel: '0'
}

/**
 * mode 参数
 * 
 * 收藏(1 音乐, 2 歌单, 3 音乐人)
 * 分享(1 音乐, 2 歌单, 3 音乐人)
 * 试听(播放)(1 试听)
 * 下载(1 试听, 2 高清)
 */
export default {
  data() {
    return {
      /**
       * music 音乐
       * songlist 歌单
       * songer 音乐人
       */
      goodType: 'music'
    }
  },

  methods: {
    share(url, obj_id) {
      let deed_type = this.$val.DeedType.share;
      let obj_type = this.$val.ShareAndCollectType[this.goodType];

      return new Promise(resolve => {
        if (!this.$root.checkLoginStatus()) return;

        if (url) {
          try {
            copyStr(url);

            this.staticDeed({
              deed_type,
              obj_type,
              obj_id,
              op_type: OperationDir.save
            })

            resolve();
          } catch (error) {
            console.error(error);
          }
        } else {
          this.$message.warning('音乐链接不存在');
          this.$error('url is not exist');
        }
      })
    },

    like(scope, obj_id) {
      let deed_type = this.$val.DeedType.collect;
      let obj_type = this.$val.ShareAndCollectType[this.goodType];
      let op_type = scope.is_collected == '1' ? OperationDir.cancel : OperationDir.save;

      return new Promise(resolve => {
        if (!this.$root.checkLoginStatus()) return;

        this.staticDeed({
          deed_type,
          obj_type,
          obj_id,
          op_type
        }).then(() => {
          resolve(scope)
        })
      })
    },

    /**
     * 
     * @param {*} scope 
     * @param {*} obj_type 对象类型(1 音乐, 2 歌单)
     * @param {*} obj_id 对象id 
     */
    download(scope, obj_id) {
      let deed_type = this.$val.DeedType.download;
      let obj_type = this.$val.ShareAndCollectType[this.goodType];

      return new Promise((resolve, reject) => {
        if (!this.$root.checkLoginStatus()) return;

        this.staticDeed({
          deed_type,
          obj_type,
          obj_id,
          op_type: OperationDir.save
        }).then(resolve, reject)
      })
    },

    inShopCart() {
      if (!this.$root.checkLoginStatus()) return;

      // this
    },

    goSimilar(scope) {
      this.$router.push(`/musicDetail/${scope.music_id}`)
    },

    staticDeed(params) {
      return new Promise((resolve, reject) => {
        this.$axios.Static_deed(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            resolve(data);
          } else {
            reject();
          }
        }, reject)
      })
    }
  }
}