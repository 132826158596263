<template>
  <div class="hot-wrap">
    <div class="container">
      <a-carousel
        :beforeChange="beforeChange"
        class="container banner-box"
        autoplay
        effect="fade"
        arrows
      >
        <div slot="prevArrow" class="custom-slick-arrow prev-arrow">
          <a-icon type="left" />
        </div>
        <div slot="nextArrow" class="custom-slick-arrow next-arrow">
          <a-icon type="right" />
        </div>
        <div
          class="banner-img-box "
          v-for="(banner, i) in bannerList"
          :key="i"
          @click="handleBanner(banner)"
          @touchstart="touchstart"
          @touchend="touchend"
        >
          <ng-image class="banner-img " :src="banner.url"></ng-image>
        </div>
      </a-carousel>
      <!-- 购买指引 -->
      <div class="home-help-wrap">
        <h2>如何获得音乐授权？</h2>
        <div class="home-help">
          <img
            class="help-box shadow"
            v-for="img in HelpImgList"
            :key="img"
            :src="img"
            alt=""
          />
        </div>
      </div>

      <div class="case-wrap">
        <div class="m-bottom-md look-more-wrap flex cen-space ">
          <page-title-icon
            title="歌单推荐"
            sub="至臻选择 每周更新"
          ></page-title-icon>
          <router-link class="font-color-base" :to="`/playlist`">
            <lookMore></lookMore>
          </router-link>
        </div>

        <div class="case-music">
          <HotMusicList
            class="hot-music-list m-bottom-lg"
            @update="updateListItem"
            :data="hotPlaylist"
          ></HotMusicList>
        </div>
      </div>
      <div class="heart-wrap m-bottom-60">
        <div class="look-more-wrap flex cen-space m-bottom-md">
          <page-title-icon
            title="官方精选"
            sub="至臻选择 每周更新"
          ></page-title-icon>

          <router-link
            class="font-color-base"
            :to="`/playListDetail/${heartSongListId}`"
          >
            <lookMore></lookMore>
          </router-link>
        </div>

        <div class="heart-music">
          <music-table :dataSource="heartList" :is-more="false" :pagination="false"></music-table>
        </div>
      </div>
      <!--  -->
      <div class="home-con-img">
        <img :src="HomeConImg" alt="" />
      </div>
      <!-- 精彩案例 -->
      <div class="case-wrap m-bottom-60">
        <div class="m-bottom-md">
          <page-title-icon
            title="精彩案例"
            sub="专业编辑 精准选曲"
          ></page-title-icon>
        </div>

        <div class="case-music">
          <CaseList :data="caseList"></CaseList>
        </div>
      </div>

      <!-- 伙伴推荐 -->
      <div class="partner-wrap m-bottom-60">
        <page-title-icon
          class="m-bottom-md"
          title="音乐伙伴推荐"
          sub="全球加盟 倾心原创"
        ></page-title-icon>

        <div class="partner">
          <PartnerList :data="partnerList"></PartnerList>
        </div>
      </div>

      <!-- 特色流派 -->
      <div class="genre-wrap m-bottom-60">
        <div class="look-more-wrap flex cen-space m-bottom-md">
          <page-title-icon title="特色风格"></page-title-icon>

          <router-link class="font-color-base" :to="`/labeldetail/style`">
            <lookMore></lookMore>
          </router-link>
        </div>

        <div class="genre">
          <GenreList :data="genreList" :num="6"></GenreList>
        </div>
      </div>

      <!-- 心情 mood -->
      <div class="genre-wrap m-bottom-60">
        <div class="look-more-wrap flex cen-space m-bottom-md">
          <page-title-icon title="特色情绪"></page-title-icon>

          <router-link class="font-color-base" :to="`/labeldetail/mood`">
            <lookMore></lookMore>
          </router-link>
        </div>

        <div class="genre">
          <GenreList
            :isShowCollect="false"
            :data="moodList"
            :num="6"
          ></GenreList>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, Icon } from "ant-design-vue";
import HotMusicList from "./components/HotMusicList.vue";
import GenreList from "./components/GenreList.vue";
import CaseList from "./components/CaseList.vue";
import PartnerList from "./components/PartnerList.vue";
import LookMore from "./components/LookMore.vue";
import PageTitleIcon from "@/pages/components/common/PageTitleIcon.vue";
import MusicTable from "@/pages/components/music/MusicTable.vue";
import help1Img from "@/assets/images/home-help1.png";
import help2Img from "@/assets/images/home-help2.png";
import help3Img from "@/assets/images/home-help3.png";
import help4Img from "@/assets/images/home-help4.png";
import HomeConImg from "@/assets/images/home-con-img.png";
const HelpImgList = [help1Img, help2Img, help3Img, help4Img];

//   banner-中国风
// https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/202103/13/67008ce1bf8dc00c001.jpg
// https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/pic_banner/Chinese_style.png

// banner-企业宣传片
// https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/pic_banner/Corporate_video.png

// banner-卡通欢乐季
// https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/pic_banner/Cartoon_season.png
export default {
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      currentBannerIndex: 0,
      touchstartX: 0,
      HomeConImg,
      HelpImgList,
      bannerList: [
        {
          url:
            "https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/pic_banner/game.png",
          alt: "「游戏配乐」身临其境，成为头号玩家",
          id: 6,
          link: "https://www.spark.art/playListDetail/7f00c3d97580d010001",
        },
        {
          url:
            "https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/pic_banner/sleeping.png",
          alt: "「助眠」送你一颗失眠特效药",
          id: 5,
          link: "https://www.spark.art/playListDetail/80008bea936e2010001",
        },
        {
          url:
            "https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/pic_banner/summer.png",
          alt: "「清新系」夏日清爽电音",
          id: 4,
          link: "https://spark.art/playListDetail/80008b4109031010001",
        },
        {
          url:
            "https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/pic_banner/Chinese_style.png",
          alt: "「中国风」千古风华 泱泱华夏",
          id: 1,
          link: "https://www.spark.art/playListDetail/7f0094d7469b8010001",
        },
        {
          url:
            "https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/pic_banner/Corporate_video.png",
          alt: "「宣传片」完美塑造企业卓越形象",
          id: 2,
          link: "https://www.spark.art/playListDetail/80008c269b8e0010001",
        },
        {
          url:
            "https://spark-test-1304044220.cos.ap-beijing.myqcloud.com/assets/common/pic_banner/Cartoon_season.png",
          alt: "「卡通」轻松欢快的卖萌小调",
          id: 3,
          link: "https://www.spark.art/playListDetail/80008b8eac1dc010001",
        }
      ],
      heartSongListId: "",

      partnerList: [1, 2, 3, 4, 5, 6],
      cooperativePartner: [],
      hotPlaylist: [],
      heartList: [],
      caseList: [],
      genreList: [],
      moodList: [],
    };
  },
  components: {
    HotMusicList,
    CaseList,
    PartnerList,
    MusicTable,
    GenreList,
    PageTitleIcon,
    LookMore,
    ACarousel: Carousel,
    AIcon: Icon,
  },

  created() {
    this.$axios.Music_Fine_Hot().then(({ data }) => {
      if (data.code == 0) {
        this.hotPlaylist = data.data.hot_playlist.splice(0, 4);
        this.heartList = data.data.recommend_music.list;
        this.heartSongListId = data.data.recommend_music.playlist_id;
        this.caseList = data.data.scene_case;
        this.partnerList = data.data.musicians;
        this.genreList = data.data.label_genre;
        this.moodList = data.data.label_mood;
      }
    });
  },

  methods: {
    beforeChange(form, to) {
      this.currentBannerIndex = to;
    },
    touchstart(e) {
      this.touchstartX = e.changedTouches[0].pageX;
    },
    //touchmove(e) {},
    touchend(e) {
      if (this.touchstartX === e.changedTouches[0].pageX) {
        this.handleBanner(this.currentBannerIndex + 1, "touch");
      }
    },

    updateListItem(scope) {
      let idx = this.hotPlaylist.findIndex(
        (item) => item.playlist_id == scope.playlist_id
      );
      this.$set(this.hotPlaylist, idx, scope);
    },
    handleBanner(item) {
      let link = item.link;
      if (!item.link) {
        link = this.bannerList[item - 1].link;
      }
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.ant-carousel {
  margin-bottom: 40px;
}
.ant-carousel >>> .slick-slide {
  text-align: center;
  /* height: 350px;
  line-height: 350px; */
  /* background: #364d79; */
  /* border-radius: 6px;
  overflow: hidden; */
  /* margin:0 1px; */
}
.slick-slide,
.slick-active,
.slick-current {
  overflow: hidden;
  /* z-index: 999; */
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .slick-dots-bottom {
  bottom: 3rem !important;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 40px;
  height: 40px;
  top: 50%;
  /* right: 70px!important; */

  transform: translateY(-10px);
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 40px;
  text-align: center;
  line-height: 40px;
  opacity: 0;
  border: none;
  z-index: -1;
  transition: all 0.5s linear;
}
.ant-carousel >>> .prev-arrow {
  left: 70px;
  z-index: 1;
}
.ant-carousel >>> .next-arrow {
  right: 70px;
  z-index: 1;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
  color: #ff7b13;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .ant-carousel >>> .slick-dots-bottom {
    bottom: 1.5rem !important;
  }
  .ant-carousel >>> .prev-arrow {
    left: 40px;
    z-index: 1;
  }
  .ant-carousel >>> .next-arrow {
    right: 40px;
    z-index: 1;
  }
}
</style>
<style lang="scss">
.banner-box {
  &:hover {
    //  background-color: red;
    .custom-slick-arrow {
      color: #fff;
      opacity: 0.3;
      z-index: 999;
      //  display: block !important;

      //  transition:all 0.5s linear;
    }
  }
}
.hot-wrap {
  margin-top: 24px;
}
.home-con-img {
  width: 100%;
  margin: 40px 0;
  img {
    width: 100%;
  }
}
.banner-img-box {
  overflow: hidden;
  // border-radius: 6px;
  cursor: pointer;
}
.banner-img {
  width: 100%;
  // border-radius: 6px;
  overflow: hidden;
}
.home-help-wrap {
  margin-bottom: 40px;
  h2 {
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 20px;
  }
}
.home-help {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  .help-box {
    width: 23%;
    height: 51%;
    margin-right: 2%;
    &:last-child {
      margin-right: 0;
    }
    // max-width: 300px;
    // height: 200px;
  }
  .shadow {
    box-shadow: 0 2px 4px 0 rgba(189, 189, 189, 0.5);
  }
}
</style>
