<template>
  <ng-dialog
    class="exchange-dialog"
    :visible="show"
    :maskClosable="false"
    :footer="null"
    @close="onClose"
  >
    <div  class="header-con column start-cen">
        <span class="header">优惠券兑换</span>
        <span>请输入优惠券兑换码/口令码来获取优惠券</span>
    </div>
    <div class="con">
      <a-form
          class="login-wrap"
          :form="form"
         
        >
        <a-form-item  :required="false"  :colon='false'>
          <span class="code-label">兑换码/口令码</span>
          <a-input
            class="sub-input"
            size="large"
            placeholder="请输入兑换码/口令码"
            v-decorator="['code',{rules: [$validate.required],  initialValue: '',validateTrigger: 'blur' }]"
          />
        </a-form-item>
       </a-form>
       <div class="btn-wrap">
         <a-button  @click="handleSubmit" style="width:100%;height:40px" type='primary'>提交</a-button>
       </div>
    </div>
  </ng-dialog>
</template>

<script>
  import NgDialog from '@/components/NgDialog.vue'
  import Login from "@/pages/components/login/Login.vue";
  import FindPwd from "@/pages/components/login/FindPwd.vue";
  import Register from "@/pages/components/login/Register.vue";

  import {dialogMixins} from "@/mixins/index";
  import {mapMutations} from "vuex";

  export default {
    data() {
      return {
        form:this.$form.createForm(this, {
          code:''
        }),
        tabList: [
          {
            id: 1,
            tab: "账号密码登录",
          },
          {
            id: 2,
            tab: "动态密码登录",
          },
        ],
        pageType: "login",
        activeKey: 1,
        show: false,
      };
    },

    watch: {
     
    },

    components: {
      Login,
      Register,
      FindPwd,
      NgDialog,
    },

    props: {
    },

    mixins: [dialogMixins],

    methods: {
      ...mapMutations(["changeLoginAndRegisterDialog"]),

      clickRegister() {
        this.pageType = "register";
      },

      showLoginDialog(bool) {
        this.changeLoginAndRegisterDialog(bool);
      },

      onClose() {
        this.$emit('close');
      },
      handleSubmit(){
        console.log('handleSubmit: ', );

          this.form.validateFields((err, fieldsValue) => {
            console.log('err: ', err);
          if (err) {
            return;
          }

          let param = fieldsValue;


          this.$axios.CouponExchange(param).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.$message.success('兑换成功');
              this.onClose()
              this.$emit('reload')
            }else{
               this.$message.error('兑换失败');
            }

          }).catch(error => {

            console.error(error);
          })
        })
      }
     
    },
  };
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
  .exchange-dialog {
    .ant-modal-body {
      padding: 24px 40px !important;
      .header-con{
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        padding-bottom: 16px;
        .header{
          font-size: 28px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
        }
        span{
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          margin-top: 8px;
        }
      }
      .con{
        margin-top: 14px;
        .code-label{
          font-size: 14px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .btn-wrap{
          // margin-top: 32px;
      }
    }

    .ant-drawer-body-inner {
      padding-top: 60px !important;
    }

    .logon-tabs-wrap {
      .ant-tabs-bar {
        margin-bottom: 64px;
      }

      .ant-tabs-tab {
        font-size: 18px !important;
        color: rgba(0, 0, 0, 0.55);
      }

      .ant-tabs-tab-active {
        font-size: 24px !important;
        color: rgba(0, 0, 0, 0.85);
      }

      .ant-tabs-ink-bar {
        background-color: rgba(0, 0, 0, 0.85) !important;
      }
    }
  }
</style>
