<template>
  <div class="app-outer pos-relative">
    <!-- header -->
    <header class="app-header">
      <Header></Header>
    </header>

    <main class="app-main m-bottom-lg">
      <router-view :key="$store.state.rootKeyIndex"></router-view>
    </main>

    <footer class="app-footer">
      <Footer></Footer>
    </footer>

    <div class="video-box-web">
      <audio-tool v-if="$store.state.globalVideoInfo && $store.state.globalVideoInfo.url"></audio-tool>
    </div>

    <transition name="fade-out">
      <a-spin
        v-if="$store.state.contentLoading"
        class="global-spin pos-fixed-center w-100 h-100"
        :loading="$store.state.contentLoading"
      />
    </transition>

    <div class="tool-wrap">
      <tool></tool>
    </div>


    <dialog-add-to-cart
      :visible="$store.state.showMusicDetailDialog"
      :data="$store.state.currentMusicDetail"
    ></dialog-add-to-cart>
    <dialog-login-and-register :visible="loginAndRegister"></dialog-login-and-register>

    <dialog-download :visible="showMusicDownload" :data="currentDownloadMusicDetail"></dialog-download>

    <MusicHistory />
  </div>
</template>

<script>
  import Tool from "./components/tool.vue";

  import {Spin} from "ant-design-vue";
  import Header from "@/components/common/Header.vue";
  import AudioTool from "@/components/AudioTool.vue";
  import DialogAddToCart from "@/components/dialog/DialogAddToCart.vue";
  import DialogDownload from "@/components/dialog/DialogDownload.vue";
  import DialogLoginAndRegister from "@/pages/components/login/DialogLoginAndRegister.vue";
  import {mapState} from "vuex";
  import Footer from "@/components/common/Footer";
  import MusicHistory from "@/pages/components/music/MusicHistory"

  export default {
    data() {
      return {};
    },

    components: {
      Tool,
      Header,
      AudioTool,
      DialogAddToCart,
      DialogDownload,
      DialogLoginAndRegister,
      ASpin: Spin,
      Footer,
      MusicHistory
    },
    computed: {
      ...mapState([
        "loginAndRegister",
        "showMusicDownload",
        "currentDownloadMusicDetail",
        "showAuthPackageDialog",
        "currentAuthPackageInfo",
      ])
    },
    mounted() {
      // this.$confirm({
      //   title: '是否要删除？',
      //   content: '确定删除此应用码确定删除此应用码确定删除此应用码确定删除此应用码确定删除此应用码',
      // })
      // this.$message.error('111111111')
    },

    methods: {}
  };
</script>

<style lang="scss" scoped>
  .app-outer {
    width: 100%;
    position: relative;
    min-height: 100%;
    padding-bottom: 26rem;

    .app-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;
    }

    .app-main {
      position: relative;
      // width: $width-content;
      height: 100%;
      min-height: 300px;
      padding-top: $header-height;
      // padding-top: 294px;
      margin: 0 auto;
    }
    .tool-wrap{
      position:fixed;
      bottom: 154px;
      right: 15px;
    }
    .app-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    .video-box-web {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
    }

    .global-spin {
      background-color: rgba($color: #dddddd, $alpha: 0.4);
      z-index: 10000;

      /deep/ .ant-spin-dot {
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }
  }
  @media screen and (max-width: $screen-width-md) {
    .app-outer {
      padding-bottom: 55rem;

      .app-main {
        padding-top: $header-height;
      }
    }
  }
</style>
