import {
  mapState
} from 'vuex'
import {
  MusicWave,
  NgNotice
} from '@/components/index'
import {
  Slider,
  Icon
} from 'ant-design-vue';
import MusicVersion from '@/pages/components/music/MusicVersion.vue'

export default {
  data() {
    return {
      showDrawer: false,
      curTime: '',
      curVolume: 0,
      isMute: false,
      newPlaying: false,
      loading: true,
      duration: 0,
      currentIndex: 0,
    }
  },

  computed: {
    ...mapState({
      videoInfo: state => state.globalVideoInfo || [],
      playing: state => state.globalVideoPlaying,
      videoList: state => state.globalVideoList || [],
    }),

    // currentIndex() {
    //   return this.videoList.findIndex(item => item.music_id === this.videoInfo.music_id);
    // },

    disabledPrev() {
      return !(this.currentIndex > 0 && this.currentIndex <= this.videoList.length - 1)
    },

    disabledNext() {
      return !(this.currentIndex >= 0 && this.currentIndex < this.videoList.length - 1)
    }
  },

  watch: {
    playing: {
      handler(newVal) {
        this.newPlaying = newVal;
      },
      immediate: true
    },

    newPlaying: {
      handler(newVal) {
        this.$nextTick(() => {
          newVal ? this.playVideo() : this.pauseVideo();
        })
      },
      immediate: true
    },

    videoInfo: {
      handler(newVal, oldVal) {
        this.showDrawer = !!newVal;

        if (newVal && newVal.audition_url && (oldVal && newVal.audition_url !== oldVal.audition_url || !oldVal)) {
          this.$nextTick(() => {
            try {
              this.loadAudio(newVal.audition_url || '');
            } catch (error) {
              console.error(error);
            }
          })
        }
      },
      immediate: true
    },

    curVolume: {
      handler(newVal) {
        this.isMute = !newVal;
        this.setVolums(newVal);
      },
      immediate: true,
    },

    videoList: {
      handler(newVal = []) {
        this.$nextTick(() => {
          this.currentIndex = newVal.findIndex(item => item.music_id === this.videoInfo.music_id);
        })
      },
      immediate: true
    }
  },

  mounted() {
    this.produceAudio();
    this.bindGlobalEvent();
  },

  beforeDestroy() {
    this.clearAudio();
    this.clearAudioStatusLoop();
    this.unbindGlobalEvent();
  },

  components: {
    MusicWave,
    NgNotice,
    ASlider: Slider,
    AIcon: Icon,
    MusicVersion,
  },

  methods: {
    onMoveProgress(percent) {
      if (percent) {
        let duration = this.duration;
        let dur = duration || 0;
        let cur = percent * duration

        if (cur > dur) cur = dur;

        (cur || cur === 0) && (this.audioContent.currentTime = cur);
      }
    },

    produceAudio() {
      if (!this.audioContent) {
        this.audioContent = new Audio();
      }

      let {
        volume
      } = this.audioContent;

      this.curVolume = volume || 0;

      this.audioContent.addEventListener('play', this.onPlay)
      this.audioContent.addEventListener('pause', this.onPause)
      this.audioContent.addEventListener('ended', this.endVideo)
      this.audioContent.addEventListener('error', this.onError)
      this.audioContent.addEventListener('durationchange', this.onDurationChange)
    },

    bindGlobalEvent() {
      document.body.addEventListener('keydown', this.onKeyDown)
    },

    unbindGlobalEvent() {
      document.body.removeEventListener('keydown', this.onKeyDown)
    },

    onKeyDown(event) {
      let keyCode = event.keyCode;

      if (keyCode == '32') {
        event.preventDefault();

        this.playing ? this.pauseVideo() : this.playVideo();
      }
    },

    loadAudio(url) {
      if (this.audioContent) {
        this.audioContent.src = url;
        this.audioContent.load();
        this.playVideo();
      }
    },

    setVolums(value) {
      value = value < 0 ? 0 : value > 1 ? 1 : value;
      this.audioContent && (this.audioContent.volume = value);
    },

    clearAudio() {
      if (this.audioContent) {
        this.resetAudio();
        this.audioContent.removeEventListener('play', this.onPlay)
        this.audioContent.removeEventListener('pause', this.onPause)
        this.audioContent.removeEventListener('ended', this.endVideo)
        this.audioContent.removeEventListener('error', this.onError)
        this.audioContent.removeEventListener('durationchange', this.onDurationChange)
        this.audioContent = null;
      }
    },

    onDurationChange() {
      this.duration = this.audioContent ? this.audioContent.duration : 0;
    },

    goPrevMusic() {
      this.toggleList(-1);

      this.replay();
    },

    goNextMusic() {
      this.toggleList(1);

      this.replay();
    },

    toggleList(step) {
      let list = this.videoList;
      let len = list.length;
      let cinx = this.currentIndex;

      cinx += step;

      cinx = (cinx < 0 ? 0 : cinx > len ? len - 1 : cinx);

      this.currentIndex = cinx;

      let videoInfo = list[cinx] || {};

      videoInfo.url = videoInfo.audition_url;

      this.$store.commit('changeVideoInfo', Object.assign({}, videoInfo));
    },

    resetAudio() {
      if (this.audioContent) {
        this.pauseVideo();
        this.newPlaying = false;
        this.audioContent.currentTime = 0;
        this.curTime = 0;
      }
    },

    replay() {
      this.resetAudio();

      this.$nextTick(() => {
        setTimeout(() => {
          this.playVideo();
        }, 500);
      })
    },

    startMute() {
      this.copyCurVolume = this.curVolume;
      this.curVolume = 0;
      this.isMute = true;
    },

    endMute() {
      this.isMute = false;
      this.copyCurVolume && (this.curVolume = this.copyCurVolume);
      this.copyCurVolume = 0;
    },

    playVideo() {
      let audioContent = this.audioContent;

      try {
        typeof audioContent.play === 'function' && audioContent.play().catch(() => {
          this.newPlaying = false;
          this.resetAudio();
        });

        this.newPlaying = true;
        this.getAudioStatus();
      } catch (error) {
        this.onError(error);
      }
    },

    pauseVideo() {
      let audioContent = this.audioContent;

      audioContent && typeof audioContent.pause === 'function' && audioContent.pause();
      this.newPlaying = false;

      this.clearAudioStatusLoop();
    },

    onPlay() {
      this.$store.commit('changeVideoPlaying', true);
    },

    onPause() {
      this.$store.commit('changeVideoPlaying', false);
    },

    endVideo() {
      this.$store.commit('changeVideoPlaying', false);
    },

    getAudioStatus() {
      this.getCurTimeTimer = setInterval(() => {
        this.getCurTime();
        this.checkReliable();

        if (!this.playing) {
          this.clearAudioStatusLoop();
        }
      }, 100);
    },

    clearAudioStatusLoop() {
      if (this.getCurTimeTimer) {
        clearInterval(this.getCurTimeTimer)
        this.getCurTimeTimer = null;
      }
    },

    getCurTime() {
      this.curTime = this.audioContent ? this.audioContent.currentTime : 0;
    },

    /**
     * 检查音频是否有可以使用加载完成数据
     * 
     * 0 = HAVE_NOTHING - 没有关于音频是否就绪的信息
     * 1 = HAVE_METADATA - 关于音频就绪的元数据
     * 2 = HAVE_CURRENT_DATA - 关于当前播放位置的数据是可用的，但没有足够的数据来播放下一帧/毫秒
     * 3 = HAVE_FUTURE_DATA - 当前及至少下一帧的数据是可用的
     * 4 = HAVE_ENOUGH_DATA - 可用数据足以开始播放
     */
    checkReliable() {
      let status = this.audioContent ? this.audioContent.readyState : 0;

      if (status <= 3) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },

    onError() {
      this.$store.commit('changeVideoPlaying', false);
      this.$message.error('音频数据加载失败，请重新加载');
      this.resetAudio();
    }
  }
}