
<template>
  <div class="music-detail-box">
    <a-row class="music-detail-top" :gutter="{xl: 40, md: 20}">
      <a-col :xs="24" :sm="24" :md="8" :xl="6">
        <div class="image-box">
          <div class="img-all-box">
            <ng-image class="w-100 music-logo m-bottom-base" :src="data.logo_url" alt="thumbnail"></ng-image>
          </div>
        </div>
      </a-col>

      <a-col :xs="24" :sm="24" :md="16" :xl="18">
        <b class="font-heading-1 m-bottom-sm block">{{ data.name }}</b>

        <div class="music-desc m-bottom-md">{{ data.desc }}</div>

        <div class="m-bottom-md">
          <a-tag
            class="m-bottom-xs m-right-xs"
            v-for="item in data.labels"
            :key="item.name"
          >{{ item.name }}</a-tag>
        </div>

        <a-button
          class="collect-btn"
          :class="{'yellow-color': data.is_collected}"
          size="large"
          @click="handleCollect(data)"
        >
          <template v-if="data.is_collected == '1'">
            <icon-font class="font-color-primary icon" type="iconheart" />
            <span>已收藏</span>
          </template>
          <template v-else>
            <icon-font class="icon" type="iconheart-empty" />
            <span>收藏</span>
          </template>
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import {Tag} from 'ant-design-vue';
  import NgImage from '~/components/NgImage.vue';
  import musicHandle from '@/mixins/musicHandle'

  export default {
    data() {
      return {
      };
    },
    components: {NgImage, ATag: Tag},

    props: {
      data: {
        type: Object,
        default() {
          return {}
        }
      }
    },

    created() {
      this.goodType = 'songlist';
    },

    mixins: [musicHandle],

    mounted() {
    },

    methods: {
      handleCollect(scope) {
        typeof this.goCollectMusic === 'function' && this.goCollectMusic(scope, scope.playlist_id, false);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .music-detail-box {
    margin-top: 32px;

    .collect-btn {
      width: 120px;
    }

    .icon {
      font-size: 18px;
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .music-detail-top {
      .ant-col {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .image-box {
      width: 45%;
      margin-bottom: 24px;
    }
  }
</style>
