<template>
  <div class="container">
    <supplier-detail class="m-bottom-md" :user_id="user_id"></supplier-detail>

    <!-- <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="他的发布">
        <music-list-slide class="m-bottom-md"></music-list-slide>
        <music-table class="m-bottom-60" :isMore="false" :pagination="false"></music-table>
        <link-me></link-me>
      </a-tab-pane>
      <a-tab-pane key="2" tab="所有音乐">
        <music-table class="m-bottom-60" :isMore="true" :pagination="false"></music-table>
      </a-tab-pane>
    </a-tabs>-->

    <filter-music
      :defaultValue="searchDefaultValue"
      :musicNumParams="musicNumParams"
      @change="onChangeParams"
      @labelsChange="onChangeLabels"
    ></filter-music>

    <music-table class="m-bottom-60" :request="$axios.Music_List" :params="musicParams" is-more :pagination="false"></music-table>
  </div>
</template>

<script>
  import SupplierDetail from './supplierInfo/SupplierDetail.vue';
  // import MusicListSlide from './supplierInfo/MusicListSlide.vue';
  import MusicTable from '@/pages/components/music/MusicTable.vue';
  // import LinkMe from './supplierInfo/LinkMe.vue';
  import FilterMusic from '@/pages/components/music/filter/FilterMusic.vue'
  import {InitDefaultValue} from '@/pages/components/music/filter/filterMusicMixins'

  export default {
    data() {
      return {
        user_id: '',
        musicParams: {},
        musicNumParams: {}
      }
    },


    created() {
      let params = this.$route.params;
      let user_id = params.user_id;

      this.user_id = user_id;

      // this.musicParams.user_id = user_id;

      this.setSearchParams(user_id);
    },

    mixins: [InitDefaultValue],

    components: {
      SupplierDetail,
      // MusicListSlide,
      MusicTable,
      // LinkMe,
      FilterMusic,
    },
    methods: {
      setSearchParams(user_id) {
        this.musicParams = Object.assign({}, this.musicParams, {user_id});
        this.musicNumParams = Object.assign({}, this.musicNumParams, {user_id});
      },

      onChangeParams(params) {
        this.musicParams = Object.assign({}, this.musicParams, params);
        // this.musicNumParams = Object.assign({}, this.musicNumParams, this.musicParams);
      },

      onChangeLabels(params) {
        this.musicNumParams = Object.assign({}, this.musicNumParams, params);
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
