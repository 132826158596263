
<template>
  <div class="play-detail-box container">
    <div class="m-bottom-20">
      <song-list-info :data="songListInfo" @update="updateListItem"></song-list-info>
    </div>

    <div class="result-music-list m-bottom-20">
      <music-table
        :request="$axios.Play_Music_List"
        :params="musicParams"
        :song-list-id="songListId"
        :transformData="onTransformData"
        is-more
        :pagination="false"
      ></music-table>
    </div>

    <div class="similar-song-list m-bottom-lg">
      <page-title-icon class="m-bottom-md" title="相似歌单"></page-title-icon>
      <song-list :data-list="songListData" :isMore="false"></song-list>
    </div>
  </div>
</template>

<script>
  import MusicTable from '@/pages/components/music/MusicTable.vue'
  import PageTitleIcon from "@/pages/components/common/PageTitleIcon.vue";
  import SongList from '@/pages/components/songList/SongList.vue'
  import SongListInfo from './components/SongListInfo.vue'

  import {UpdateMusicList} from '@/mixins/music'
  import musicHandle from '@/mixins/musicHandle'

  export default {
    data() {
      return {
        songListInfo: {},
        musicParams: {},
        songListData: [],
        songListId: ''
      };
    },

    mixins: [musicHandle, UpdateMusicList],

    components: {SongListInfo, PageTitleIcon, MusicTable, SongList},

    watch: {
      $route(to) {
        this.reload(to.params.playlist_id);
      }
    },

    created() {
      this.songListId = this.$route.params.playlist_id || '';

      this.reload();
    },

    mounted() { },

    methods: {
      reload(sid) {
        sid = sid ? sid : this.$route.params.playlist_id;

        this.getplaylist(sid);

        this.setMusicParams(sid);
      },

      setMusicParams(sid) {
        let params = {};

        params.playlist_id = sid;

        this.musicParams = Object.assign({}, this.musicParams, params);
      },

      updateListItem(scope) {
        this.songListInfo = Object.assign({}, this.songListInfo, scope);
      },

      collectMusic() {

      },

      handleCollect() {

      },

      // 添加歌单识别
      onTransformData(list) {
        return list.map(item => Object.assign(item, {sid: this.songListId, isSongList: true}));
      },

      getplaylist(sid) {
        this.$store.commit("changeContentLoading", true);

        this.$axios
          .Music_Play_Info({playlist_id: sid})
          .then((res) => {
            const data = res.data;

            if (data && data.code == 0) {
              let newData = data.data ? data.data : {}

              this.songListInfo = newData;
              this.songListData = newData.similars || [];
            }

            this.$store.commit("changeContentLoading", false);
          })
          .catch(() => {
            this.$store.commit("changeContentLoading", false);
          });
      },
    }

  };
</script>

<style lang="scss" scoped>
  .play-detail-box {
    margin-top: 32px;
    padding-bottom: 60px;
  }
  .m-bottom-20 {
  margin-bottom: 30px !important;
}
</style>
