<template>
  <div class="nodify-form-wrap">
    <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol" class="ng-form">
      <!-- 头像 -->
      <a-form-item>
        <div class="flex flex-column cen-center">
          <form-upload
            class="upload-box"
            accept="image/jpg, image/jpeg, image/png"
            list-type="picture-card"
            :action="$axios.UploadFile"
            :data="uploadParams"
            :name="uploadName"
            circle
            @uploading="onUploading"
            :max-size="$config.UploadLicenseMaxSize"
            v-decorator="['profile_photo', { initialValue: '' }]"
            :remove-icon="true"
            :init-header-url="defaultValue.profile_photo_url"
          >
            <svg class="iconfont" slot="icon">
              <use xlink:href="#icontouxiang" />
            </svg>
          </form-upload>

          <div>{{$t('userSet.basic_uploadHeaderTip')}}</div>
        </div>
      </a-form-item>

      <!-- 输入框 -->
      <a-form-item :label="$t('form.username')">
        <a-input
          size="large"
          :placeholder="$t('template.selectSth', {name: $t('form.username')})"
          v-decorator="['name', { rules: [$validate.required], initialValue: '', trigger: 'blur' }]"
        />
      </a-form-item>

      <!-- 性别 -->
      <a-form-item :label="$t('form.sex')">
        <a-select
          size="large"
          class="ant-select-normal"
          :options="SexOptions"
          :placeholder="$t('template.selectSth', {name: $t('form.sex')})"
          v-decorator="['sex', { rules: [$validate.required] }]"
        ></a-select>
      </a-form-item>

      <!-- 地区 -->
      <a-form-item :label="$t('form.nation')">
        <form-country
          class="ant-select-normal"
          size="large"
          rank="first"
          :placeholder="$t('form.nationPlaceholder')"
          v-decorator="['address_id', { rules: [$validate.required] }]"
        ></form-country>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
  import {FormUpload} from '@/components/index'
  import {SexOptions} from '@/config/options'
  import {FormCountry} from '@/components/index'

  const labelCol = {span: 24};
  const wrapperCol = {span: 24};

  export default {
    data() {
      return {
        allDisabled: false,
        form: this.$form.createForm(this, "nodify"),

        labelCol,
        wrapperCol,

        uploading: false,
        uploadParams: {},
        uploadName: 'file',
        SexOptions,
      };
    },

    props: {
      type: String,
      defaultValue: Object,
    },

    watch: {
      defaultValue: {
        handler(newVal) {
          newVal && this.initFields(newVal);
        },
        immediate: true
      }
    },

    components: {
      FormUpload,
      FormCountry
    },

    created() {
      this.uploadParams = {
        upload_type: 1,
        field_name: this.uploadName
      }
    },

    mounted() {
      this.$nextTick(() => {
        this.initFields();
      })
    },

    methods: {
      onUploading(uploading) {
        this.uploading = uploading;
      },

      initFields(data) {
        let {profile_photo, name, sex, address_id} = {...data};

        sex === '0' && (sex = undefined);

        this.form.setFieldsValue({profile_photo, name, sex, address_id});
      },
    }
  };
</script>
<style lang="scss" scoped>
  .nodify-form-wrap {
    width: 100%;
    .ant-form-item-label {
      margin-right: 20px !important;
    }
    .ant-form-item-control-wrapper {
      margin-left: 20px;
    }
  }

  .upload-box {
    // display: block;
    // width: 80px;
    // height: 80px;
    // border-radius: 100%;
    // overflow: hidden;

    /deep/ .ant-upload {
      border: 0;
      width: 80px;
      height: 80px;
      padding: 0;
      background-color: #ddd;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

      img[alt="avatar"] {
        width: 100%;
        height: 100%;
      }

      .close-icon {
        transform: translate(10px, 0px);
      }
    }

    .iconfont {
      width: 60%;
      height: 60%;
    }
  }

  .ant-upload-picture-card-wrapper {
    width: auto;
  }
</style>