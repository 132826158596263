<template>
  <div class="user-detail">
    <div class="top-title back-grey p-bottom-md p-top-md m-bottom-base">
      <div class="container-md">
        <h1>个人资料</h1>
      </div>
    </div>

    <div class="main">
      <div class="container-md">
        <a-tabs :default-active-key="defaultKey" :animated="false" @change="onChangeTab">
          <a-tab-pane :key="$val.UserMenu.receiptHeader" tab="发票抬头">
            <user-receipt></user-receipt>
          </a-tab-pane>
          <a-tab-pane :key="$val.UserMenu.company" tab="公司信息">
            <user-company></user-company>
          </a-tab-pane>
          <a-tab-pane :key="$val.UserMenu.address" tab="联系地址">
            <user-address></user-address>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import UserReceipt from './receipt/Index.vue';
  import UserCompany from './company/Index.vue';
  import UserAddress from './address/Index.vue';

  export default {
    data() {
      return {
        defaultKey: ''
      }
    },

    created() {
      let menukey = this.$route.query.menukey;

      this.defaultKey = menukey ? menukey : this.$val.UserMenu.receiptHeader;
    },

    components: {
      UserReceipt,
      UserCompany,
      UserAddress
    },
    methods: {
      onChangeTab(newVal) {
        this.$root.$goSearch({
          menukey: newVal
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user-detail {
    /deep/ {
      .ant-table-placeholder {
        border: none;
      }

      .detail-main {
        .icon-font {
          margin-right: 16px;
        }
      }

      @media screen and (min-width: $screen-width-md) {
        .add-btn {
          position: absolute;
          top: -60px;
          right: 0;
        }
      }

      @media screen and (max-width: $screen-width-md) {
        .add-btn {
          width: 100%;
          margin-bottom: 24px;
        }
      }
    }
  }
</style>
