<template>
  <ng-dialog
    class="receipt-add"
    :title="title"
    :visible.sync="show"
    :bodyStyle="bodyStyle"
    @close="$emit('update:visible', false)"
  >
    <a-form
      :form="form"
      layout="horizontal"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      class="ng-form"
    >
      <a-form-item :label="$t('userSet.addr_receivAddr')">
        <a-input
          size="large"
          :placeholder="$t('userSet.addr_receivAddrPlaceholder')"
          v-decorator="['address', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
        ></a-input>
      </a-form-item>

      <a-form-item :label="$t('userSet.addr_recevierName')">
        <a-input
          size="large"
          :placeholder="$t('userSet.addr_recevierPlaceholder')"
          v-decorator="['name', { rules: [$validate.required, $validate.letter_zh_number_char], initialValue: '', validateTrigger: 'blur'  }]"
        ></a-input>
      </a-form-item>

      <a-form-item style="display: none;">
        <a-input
          size="large"
          show-type="both"
          v-decorator="['phone_code', { initialValue: '1000'}]"
        ></a-input>
      </a-form-item>

      <a-form-item style="display: none;">
        <a-input size="large" show-type="both" v-decorator="['post_code', { initialValue: '1'}]"></a-input>
      </a-form-item>

      <!-- 电话 -->
      <a-form-item :label="$t('userSet.addr_phone')">
        <a-input
          size="large"
          type="number"
          v-decorator="['phone', { rules: [$validate.phone], initialValue: '', trigger: 'blur' }]"
          :placeholder="$t('userSet.add_phonePlaceholder')"
        />
      </a-form-item>

      <a-form-item :label="$t('userSet.addr_email')">
        <a-input
          size="large"
          :placeholder="$t('userSet.addr_emialPlaceholder')"
          v-decorator="['email', { rules: [$validate.required, $validate.email], initialValue: '', validateTrigger: 'blur'  }]"
        ></a-input>
      </a-form-item>
    </a-form>

    <footer class="modal-footer text-center" slot="footer">
      <a-button
        type="primary"
        class="w-100"
        size="large"
        @click="handleOk"
        :loading="confirmLoading"
      >{{$t('common.submit')}}</a-button>
    </footer>
  </ng-dialog>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import NgDialog from '@/components/NgDialog.vue'

  const labelCol = {span: 24};
  const wrapperCol = {span: 24};

  export default {
    data() {
      return {
        show: false,

        labelCol,
        wrapperCol,

        confirmLoading: false,
      }
    },

    props: {
      handle: String,
      options: Object,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    computed: {
      isEdit() {
        return this.handle === this.$var.Handle.edit;
      },

      title() {
        return this.isEdit ? this.$t('userSet.addr_changeTitle') : this.$t('userSet.addr_addTitle')
      },
    },

    watch: {
      visible(newVal) {
        if (newVal && this.form) {

          this.$nextTick(() => {
            this.form.resetFields();

            this.isEdit && this.form.setFieldsValue(this.defaultValue);
          })
        }
      },
    },

    mixins: [dialogMixins],

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'linkman'
      })
    },

    components: {
      NgDialog
    },

    methods: {

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            this.onSubmit(values).then((data) => {
              this.$emit('update:visible', false);
              this.$emit('success', data);
            })
          }
        })
      },

      // Address_add  Address_edit
      onSubmit(param) {
        let url = this.isEdit ? 'Address_edit' : 'Address_add';

        this.confirmLoading = true;

        param.is_default = +(!!param.is_default);

        if (this.isEdit) {
          param = Object.assign(param, {express_id: this.defaultValue.express_id})
        }

        return new Promise(resolve => {
          this.$axios[url] && this.$axios[url](param).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              resolve(data);
              this.$message.success(this.handle === this.$var.Handle.edit ? '编辑成功' : '新增成功');
            }

            this.confirmLoading = false;
          }).catch(() => {
            this.confirmLoading = false;
          })
        })
      },

      handleCancel() {

      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-supplier-add {
    /deep/ .ant-form-item-label {
      width: 130px;
    }
  }

  .dynamic-delete-button {
    position: absolute;
    top: 50%;
    right: -0;
    transform: translate(120%, -50%);
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
    cursor: pointer;
  }

  .upload-box {
    /deep/ {
      .ant-upload {
        width: 148px;
        height: 104px;
        margin-bottom: 0;
      }
    }
  }
</style>
