<template>
  <div class="user-wrapper">
    <div class="container">
      <a-row class="user-info-outer m-bottom-lg" :gutter="20">
        <a-col class="first-item" :xs="24" :sm="24" :md="12">
          <div class="user-info-item first">
            <user-info></user-info>
          </div>
        </a-col>
        <a-col class="user-info-item" :xs="24" :sm="24" :md="12">
          <div class="user-info-item">
            <vip-info></vip-info>
          </div>
        </a-col>
      </a-row>

      <div class="user-main">
        <a-tabs
          :default-active-key="defaultKey"
          :animated="false"
          @change="onChangeTab"
          :key="tabsIndex"
        >
        
          <a-tab-pane :key="$val.UserMenu.collect" tab="我的收藏">
            <my-collect></my-collect>
          </a-tab-pane>
          <a-tab-pane :key="$val.UserMenu.buy" tab="已购买">
            <my-buy></my-buy>
          </a-tab-pane>
          <a-tab-pane :key="$val.UserMenu.package" tab="我的套餐">
            <my-package></my-package>
          </a-tab-pane>
          <a-tab-pane :key="$val.UserMenu.order" tab="我的订单">
            <my-order></my-order>
          </a-tab-pane>
           <a-tab-pane :key="$val.UserMenu.discount" tab="优惠券">
             <my-coupon></my-coupon>
          </a-tab-pane>
          <!-- <a-tab-pane  :key="$val.UserMenu.discount" tab="优惠券">
          </a-tab-pane>-->
          <a-tab-pane :key="$val.UserMenu.operation" tab="操作记录">
            <my-operation></my-operation>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import UserInfo from './components/UserInfo.vue';
  import VipInfo from './components/VipInfo.vue';

  import MyCollect from './collect/MyCollect.vue';
  import MyBuy from './buy/MyBuy.vue';
  import MyCoupon from './coupon/coupon.vue';
  import MyPackage from './package/MyPackage.vue';
  import MyOperation from './operation/MyOperation.vue';
  import MyOrder from './order/MyOrder.vue';

  export default {
    data() {
      return {
        defaultKey: '',
        tabsIndex: 0,
      }
    },

    created() {
      this.setDefaultIndex();
    },

    watch: {
      $route() {
        this.setDefaultIndex();
        this.tabsIndex++;
      }
    },

    components: {
      UserInfo,
      VipInfo,
      MyCollect,
      MyBuy,
      MyPackage,
      MyOperation,
      MyOrder,
      MyCoupon
    },
    methods: {
      onChangeTab(newVal) {
        this.$root.$goSearch({
          menukey: newVal
        })
      },

      setDefaultIndex() {
        let menukey = this.$route.query.menukey;
        this.defaultKey = menukey ? menukey : this.$val.UserMenu.collect;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user-wrapper {
    ::v-deep {
      .ant-tabs-content {
        padding-bottom: 40px;
      }

      @media screen and (min-width: $screen-width-md) {
        .tab-btns {
          position: absolute;
          top: -63px;
          right: 0;
          z-index: 2;
        }
      }

      @media screen and (max-width: $screen-width-md) {
        .tab-btns {
          margin-bottom: 24px;
          width: 100%;
        }

        .user-info-outer .first-item {
          margin-bottom: 24px;
        }
      }
    }
  }

  .user-info-outer {
    margin-top: 32px;
  }
</style>
