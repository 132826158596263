<template>
  <div class>
    <div class>
      <b class="font-color-primary font-heading-2">¥{{scope.total_amount | toPrice}}</b>
      <div class="m-bottom-sm">实付金额</div>
      <!-- <div class="font-color-sub m-bottom-sm">
            <span class="m-right-xs">套餐金额 {{orderInfo.price | toPrice}}</span>
            <span>优惠金额 {{orderInfo.price | toPrice}}</span>
      </div>-->
    </div>

    <a-divider></a-divider>

    <div class="pay-info m-bottom-md pos-relative">
      <a-descriptions
        :title="`订单信息(${scope.detail ? scope.detail.length : 0 }首)`"
        :column="1"
        class="border-none"
      >
        <a-descriptions-item label="订单编号">{{scope.order_no}}</a-descriptions-item>
      </a-descriptions>

      <!-- <router-link class="go-auth" :to="`/user?menukey=${$val.UserMenu.buy}`">去授权</router-link> -->

      <slot name="operation"></slot>
    </div>

    <div class="pay-info">
      <template v-for="(item, index) in scope.detail">
        <music-detail-item class="m-bottom-lg" :data="item" :key="index">
          <b class="m-top-sm inline-block font-md">￥{{item.price}}</b>

          <!-- <template v-slot:top>
            <template v-if="status === $val.PayResultStatus.success">
              <div class="f-r">
                <router-link
                  v-if="item.is_licensed == $val.CommonCheck.correct"
                  class="font-base"
                  :to="`/authCenter?license_id=${item.license_id}`"
                >查看授权信息</router-link>
                <a-button v-else class="font-base" type="link" @click="onActiveAuth(item)">激活音乐授权</a-button>
              </div>
            </template>
          </template>-->
        </music-detail-item>
      </template>
    </div>

    <dialog-active-auth
      :visible.sync="showDialogActive"
      :data="handleData"
      :type="$val.orderType.music"
      @success="onSuccess"
    ></dialog-active-auth>
  </div>
</template>

<script>
  import {Descriptions, Divider} from 'ant-design-vue';
  import MusicDetailItem from '@/pages/components/music/MusicDetailItem.vue'
  import DialogActiveAuth from "@/pages/components/order/DialogActiveAuth.vue";

  export default {
    data() {
      return {
        // orderInfo: {}
        showDialogActive: false,
        handleData: {}
      }
    },

    props: {
      scope: {
        type: Object,
        default() {
          return {}
        }
      },
      status: String
    },

    components: {
      ADescriptions: Descriptions,
      ADescriptionsItem: Descriptions.Item,
      ADivider: Divider,
      MusicDetailItem,
      DialogActiveAuth
    },

    methods: {
      onSuccess(scope) {
        this.$emit('success', scope);
      },

      onActiveAuth(item) {
        this.handleData = item;
        this.showDialogActive = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .cart-detail-item {
      border-bottom: 0;
    }
  }

  .go-auth {
    position: absolute;
    top: 2px;
    right: 0;
  }
</style>
