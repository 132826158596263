<template>
  <div>
    <a-divider class="m-empty"></a-divider>

    <ng-list
      class="m-top-base"
      id-key="order_id"
      :load.sync="loadData"
      :reload.sync="reloadData"
      :url-func="$axios.OrderList"
      :params="params"
      :changeData="changeItem"
      :empty-text="$t('userOrder.tableEmtpy')"
    >
      <template v-slot="{item}">
        <div class="item-header">
          <!-- <a-button @click="onGoApplyReceipt(item)">发票</a-button>
          <a-button @click="onGoApplyContract(item)">合同</a-button>-->

          <order-list-item-pc
            class="visiable-md hidden-sm"
            :item="item"
            @applyReceipt="onGoApplyReceipt"
            @applyContract="onGoApplyContract"
            @lookDetail="onLookDetail"
            @lookReceipt="onLookReceipt"
            @lookContract="onLookContract"
            @success="onSuccess"
            @loadlist="onLoadList"
          ></order-list-item-pc>
          <order-list-item-mobile
            class="hidden-md visiable-sm"
            :item="item"
            @applyReceipt="onGoApplyReceipt"
            @applyContract="onGoApplyContract"
            @lookDetail="onLookDetail"
            @lookReceipt="onLookReceipt"
            @lookContract="onLookContract"
            @success="onSuccess"
            @loadlist="onLoadList"
          ></order-list-item-mobile>
        </div>

        <div class="good-list-item" v-for="cItem in item.detail" :key="cItem.detail_id">
          <music-detail-item :data="cItem"></music-detail-item>
        </div>
      </template>
    </ng-list>

    <dialog-apply-receipt
      :visible.sync="showDialogReceipt"
      :data="handleDataApplyReceipt"
      @success="onSuccess"
      @loadlist="onLoadList"
    ></dialog-apply-receipt>
    <dialog-apply-contract
      :visible.sync="showDialogContract"
      :data="handleDataApplyContract"
      @success="onSuccess"
      @loadlist="onLoadList"
    ></dialog-apply-contract>
    <dialog-order-result
      :visible.sync="showDialogOrderResult"
      :data="handleDataOrderResult"
      @success="onSuccess"
      @loadlist="onLoadList"
    ></dialog-order-result>
    <dialog-receipt-result
      :visible.sync="showDialogReceiptResult"
      :data="handleDataReceiptResult"
      :invoice_id="handleDataReceiptResult.invoice_id"
      @success="onSuccess"
    ></dialog-receipt-result>
    <dialog-contract-result
      :visible.sync="showDialogContractResult"
      :data="handleDataContractResult"
      :contract_id="handleDataContractResult.contract_id"
      @success="onSuccess"
    ></dialog-contract-result>
  </div>
</template>

<script>
  import NgList from '@/components/NgList.vue';
  import MusicDetailItem from '@/pages/components/music/MusicDetailItem.vue';
  import DialogApplyReceipt from "./DialogApplyReceipt.vue";
  import DialogApplyContract from "./DialogApplyContract.vue";
  import OrderListItemPc from './OrderListItemPc.vue'
  import OrderListItemMobile from './OrderListItemMobile.vue'
  import DialogOrderResult from './result/DialogOrderResult.vue'
  import DialogReceiptResult from './result/DialogReceiptResult.vue'
  import DialogContractResult from './result/DialogContractResult.vue'

  import {DidPayOrderOptions} from '@/config/options';

  export default {
    data() {
      return {
        DidPayOrderOptions,
        radioActiveValue: this.$val.DidPayOrderType.undoAuth,
        // params: null,
        loadData: false,
        reloadData: false,

        showDialogReceipt: false,
        showDialogContract: false,
        showDialogOrderResult: false,
        showDialogReceiptResult: false,
        showDialogContractResult: false,

        handleData: {},
        handleDataApplyReceipt: {},
        handleDataApplyContract: {},
        handleDataOrderResult: {},
        handleDataReceiptResult: {},
        handleDataContractResult: {},

        changeItem: [],
      }
    },

    props: {
      type: String,
      params: Object,
    },

    components: {
      NgList,
      MusicDetailItem,
      DialogApplyReceipt,
      OrderListItemPc,
      OrderListItemMobile,
      DialogApplyContract,
      DialogOrderResult,
      DialogReceiptResult,
      DialogContractResult
    },

    methods: {
      onSuccess(scope) {
        this.changeItem = [scope.data];

        this.$nextTick(() => {
          this.changeItem = [];
        })

        this.closeAllDialog();
      },

      onLoadList() {
        this.reloadData = true;

        this.closeAllDialog();
      },

      closeAllDialog() {
        this.showDialogReceipt = false;
        this.showDialogContract = false;
        this.showDialogOrderResult = false;
        this.showDialogReceiptResult = false;
        this.showDialogContractResult = false;
        this.handleData = {};
      },

      onGoApplyReceipt(scope) {
        this.handleDataApplyReceipt = Object.assign({}, scope);
        this.showDialogReceipt = true;
      },

      onGoApplyContract(scope) {
        this.handleDataApplyContract = Object.assign({}, scope);
        this.showDialogContract = true;
      },

      onLookDetail(scope) {
        this.handleDataOrderResult = Object.assign({}, scope);
        this.showDialogOrderResult = true;
      },

      onLookReceipt(scope) {
        console.log(scope);
        this.handleDataReceiptResult = Object.assign({}, scope);
        this.showDialogReceiptResult = true;
      },

      onLookContract(scope) {
        this.handleDataContractResult = Object.assign({}, scope);
        this.showDialogContractResult = true;
      },

    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .item-right {
      .right {
        width: 400px;
      }
    }
  }

  .auth-code {
    margin-top: $space-ver-sm;
  }

  .good-item-right {
    display: flex;
    justify-content: space-between;
  }

  .operation {
    button {
      height: auto;
    }
  }

  .good-list-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .good-item-right {
      width: 100%;
      flex-direction: column;
      margin-top: $space-ver-sm;

      .price {
        margin-bottom: $space-ver-sm;
      }
    }
  }
</style>
