<template>
  <div class="list-header">
    <div class="left flex-1">
      <!-- 已支付 -->
      <template v-if="item.status === $val.UndoPayOrderType.payed">
        <icon-font class="font-color-success icon m-right-xs" type="iconcorrect"></icon-font>

        <div class="price m-right-sm">
          <b class="font-md">已支付</b>
          <b class="font-md">￥{{item.total_amount}}</b>
        </div>
      </template>
      <!-- 未支付 -->
      <template v-else-if="item.status === $val.UndoPayOrderType.wait">
        <icon-font class="font-color-primary icon m-right-xs" type="iconwait"></icon-font>

        <div class="price m-right-sm">
          <b class="font-md">待支付</b>
          <b class="font-md">￥{{item.total_amount}}</b>
        </div>
      </template>
      <!-- 支付中 -->
      <template v-else-if="item.status === $val.UndoPayOrderType.paying">
        <icon-font class="font-color-primary icon m-right-xs" type="iconpay-solid"></icon-font>

        <div class="price m-right-sm">
          <b class="font-md">支付中</b>
          <b class="font-md">￥{{item.total_amount}}</b>
        </div>
      </template>
      <!-- 未支付 已取消 -->
      <template v-else>
        <icon-font class="font-color-sub icon m-right-xs" type="iconwait"></icon-font>

        <div class="price m-right-sm">
          <b class="font-md">已取消</b>
          <b class="font-md">￥{{item.total_amount}}</b>
        </div>
      </template>

      <span class="m-right-sm">交易时间 {{item.create_at}}</span>

      <span class="m-right-sm">
        <span>订单编号</span>&nbsp;
        <span class="cursor-pointer" @click="onCopyOrder(item.order_no)">
          <span class="m-right-xs">{{item.order_no}}</span>
          <icon-font type="iconcopy"></icon-font>
        </span>
      </span>
    </div>

    <div class="right">
      <template v-if="item.status !== $val.UndoPayOrderType.paying">
        <a-button class="p-empty m-right-sm" type="link" @click="$emit('lookDetail', item)">查看详情</a-button>
      </template>
      <template v-else>
        <router-link
          class="p-empty m-right-sm"
          :to="`/payresult/paying/${item.order_id}?order_type=${$val.orderType.music}`"
        >查看详情</router-link>
      </template>

      <template v-if="item.status === $val.UndoPayOrderType.wait">
        <a-button class="p-empty m-right-sm" type="link" @click="onCancelOrder(item)">取消支付</a-button>
        <a-button type="primary" @click="$router.push(`/paymusic/${item.order_id}`)">去支付</a-button>
      </template>
      <template v-else-if="item.status !== $val.UndoPayOrderType.paying">
        <a-button class="p-empty m-right-sm" type="link" @click="onDelOrder(item)">删除订单</a-button>

        <template v-if="item.status === $val.UndoPayOrderType.payed">
          <!--  -->
          <template v-if="item.is_invoiced == $val.CommonCheck.correct">
            <a-button
              class="p-empty m-right-sm"
              type="link"
              @click="$emit('lookReceipt', item)"
            >查看发票</a-button>
          </template>
          <template v-else>
            <a-button class="m-right-sm" type="primary" @click="goApplyReceipt(item)">申请发票</a-button>
          </template>
          <!--  -->
          <template v-if="item.is_contracted == $val.CommonCheck.correct">
            <a-button class="p-empty" type="link" @click="$emit('lookContract', item)">查看合同</a-button>
          </template>
          <template v-else>
            <a-button class type="primary" @click="goApplyContract(item)">申请合同</a-button>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import OrderHeaderMixins from './_mixin';

  export default {
    data() {
      return {
      }
    },
    props: {
      item: Object
    },

    mixins: [OrderHeaderMixins],

    computed: {
      orderType() {
        return this.getOrderType(this.item);
      }
    },

    components: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      flex-shrink: 1;

      .icon {
        font-size: 20px;
      }
    }

    .right {
      flex-shrink: 0;
    }

    .price {
      color: $black-1;
    }
  }
</style>
