<template>
  <div class="coupon-list">
    <a-divider class="m-empty"></a-divider>
    <div>
      <div class="top-operation w-100 flex cen-end">
        <div class="desc-wrap">
            <icon-font class="iconfont shuoming-icon" type="iconiconlineshuoming"></icon-font>
           <span class="coupon-desc" @click="ruleDesc">优惠券规则说明</span>
        </div>
          <a-button type='primary' @click="exchangeCoupon">优惠券兑换</a-button>
      </div>
      <div class="coupon-list-wrap flex cen-start">
          <div class="coupon-item  flex cen-start" v-for="(item,i) in requestData" :key="i"  :class="item.type==3 || item.type==4 ?'coupon-gray':''" >
            <div class="left flex cen-center cro">
              <div class="cro_right_top"></div>
              <div class="cro_right_bottom"></div>
              <span v-if="item.coupon_type == '2'">满<br/>减<br/>券</span>
              <span v-else> 折<br/>扣<br/>券</span>
             
            </div>
            <div class="right column cen-center cro">
              <div class="cro_left_top"></div>
              <div class="cro_left_bottom"></div>
              <div class="right-top flex cen-space-around">
                <div class="discount h-100 flex " v-if="item.coupon_type == '1'">
                  <span class="price">{{item.discount}}  &nbsp;</span>
                  <span class="ft17 zhe">折</span>
                </div>
                <div class="discount h-100 flex" v-else>
                  <span class="price"> <span class="ft20">¥ &nbsp;</span>{{item.money}}</span>
                </div>
                <div class="sill column cen-center">
                  <span class="menkan"  :class="item.type==3 || item.type==4 ?'c-gray':''" >{{item.threshold>0?`满${item.threshold}元使用`:'无门槛使用'}}</span>
                  <span class="ft12 use-btn" @click="useCoupon(item)">立即使用</span>
                </div>
              </div>
              <div class="right-bottom" :class="item.type==3 || item.type==4 ?'c-gray':''" >
                <span>有效期：{{item.begin_time}}-{{item.end_time}}</span>
              </div>
          
            </div>
            <div class="coupon-status" v-if="item.type==3 || item.type==4">
              <img :src="item.type==3?useCouponImg:expiredCouponImg " alt="">
            </div>

          </div>
      </div>

    </div>
  </div>
</template>

<script>
  import useCouponImg from '@/assets/images/use-coupon-icon.png'
  import expiredCouponImg from '@/assets/images/expired-coupon-icon.png'

  export default {
    data() {
      return {
        useCouponImg,
        expiredCouponImg,
        couponList:[
     
        ],
        radioActiveValue: this.$val.DidPayOrderType.undoAuth,
        loadData: false,
        reloadData: false,
        showDialogActive: false,
        handleData: {},
        changeItem: [],
      }
    },
    

    props: {
      type: String,
      requestData: Array,
    },


    components: {
    },

    methods: {
      ruleDesc(){
        this.$emit('showCouponRule')
      },
      useCoupon(item){
        if(item.type !== 2) return;
         this.$router.push(`/allMusic`);
      },
      exchangeCoupon(){
        this.$emit('showExchangeCoupon')
      },
      onSuccess(scope) {
        let data = scope.data;

        this.changeItem = [data];

        setTimeout(() => {
          this.changeItem = [];
        }, 1000);
      },

      onLoadList() {
        this.reloadData = true;
      },

     
    }
  }
</script>

<style lang="scss" scoped>

  .coupon-list{
      .top-operation{
        margin-top: $space-ver-md;
        .desc-wrap{
          cursor: pointer;
            .shuoming-icon{
              font-size: 16px;
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
            .coupon-desc{
              margin-right: $space-base;
            }
        }
      
      }
      .coupon-list-wrap{
        width: 100%;
        margin-top: 36px;
        flex-wrap: wrap;
        // justify-content: space-around;
        .coupon-item{
          width: 23.5%;
          margin-right: 2%;
          margin-bottom: 30px;
          height: 112px;
            color: #FF9751;
          
           &:nth-child(4n) {
            margin-right: 0;
          }
          .left{
            width: 22%;
            flex: 0 0 22%;
            height: 100%;
            border-right:1px dashed transparent;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            // -webkit-writing-mode: vertical-rl;
            // writing-mode: vertical-rl;
            font-size: 22px;
            font-family: AlibabaPuHuiTiM;
            span{
               font-weight: 500; 
               line-height: 24px;
            }
          }
           
        }
        .right{
            left: -1px;
            flex: 0 0 78%;
            width: 78%;
            height: 100%;
            border-left:1px dashed #FF9751 !important;
            border-top-right-radius:4px ;
            border-bottom-right-radius: 4px;
            padding: 0 7px ;
            .right-top{
              width: 100%;

              .discount{
                justify-content: center;
                align-items: flex-end;
                //  vertical-align:middle;   
                //  height: 50px; 
                font-weight: bold;
                padding-top: 18px;
            
                .price{
                  font-size: 28px;
                  font-family: DINCondensed-Bold, DINCondensed;
                  line-height: 45px;
                  // margin-top: 15px;
                  margin-right: 5px;
                }
                .zhe{
                  line-height: 40px;
                }
              }
              .sill{
                margin-left: 5px;
                .menkan{
                  color: rgba(0, 0, 0, 0.85);
                  font-weight: bold;
                 

                }
               
                .use-btn{
                  cursor: pointer;
                  border: 1px solid #FF9751;
                  width: 80px;
                  line-height: 20px;
                  border-radius: 10px;
                  text-align: center;
                  margin-top: 5px;
                }
              }
            }
            .right-bottom{
              font-size: 12px;
              color: rgba(0, 0, 0, 0.65);
              text-align: center ;
              margin-top: 6px;
            }
             .c-gray{
                  color:  rgba(0, 0, 0, 0.35)!important;
                }
          }
          .coupon-status{
            width: 60px;
            height: 60px;
            position:absolute;
            bottom: 8px;
            right: 5px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .coupon-gray{
              -webkit-filter:grayscale(100%);
              -moz-filter:grayscale(100%);
              -ms-filter:grayscale(100%);
              -o-filter:grayscale(100%);
              filter:grayscale(100%);
              filter:url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
              filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1)
          } 
          .cro {
          
              border: 1px solid #FF9751;
              

              position: relative;
          }
        
          .cro_left_top, .cro_right_top, .cro_left_bottom, .cro_right_bottom {
              position: absolute;
              width: 10px;
              height: 10px;
              border: 1px solid #fff;
              z-index: 1;
              background: #fff;
          }
      
          .cro_left_top {
              top: -1px;
              left: -1px;
              border-radius: 0 0 10px 0;
              border-bottom: 1px solid #FF9751;
              border-right: 1px solid #FF9751;
          }
      
          .cro_right_top {
              top: -1px;
              right: -1px;
              border-radius: 0 0 0 10px;
              border-bottom: 1px solid #FF9751;
              border-left: 1px solid #FF9751;
          }
      
          .cro_left_bottom {
              left: -1px;
              bottom: -1px;
              border-radius: 0 10px 0 0;
              border-top: 1px solid #FF9751;
              border-right: 1px solid #FF9751;
          }
      
          .cro_right_bottom {
              right: -1px;
              bottom: -1px;
              border-radius: 10px 0 0 0;
              border-top: 1px solid #FF9751;
              border-left: 1px solid #FF9751;
          }
      }
  }
  

  

  .operation {
    button {
      height: auto;
    }
  }

  @media screen and (max-width: $screen-width-md) {

      .top-operation{
       justify-content: space-between;
      
      }
    .coupon-list{
       .coupon-list-wrap{
         justify-content: center;
        .coupon-item{
          width: 25rem;
          margin-right: 0;
        }
       }
    }
    .good-item-right {
      width: 100%;
      flex-direction: column;
      margin-top: $space-ver-sm;

      .price {
        margin-bottom: $space-ver-sm;
      }
    }
  }
</style>
