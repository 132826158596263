<template>
  <ng-dialog
    :type="dialogStatus"
    :visible.sync="show"
    :bodyStyle="bodyStyle"
    :footer="null"
    @close="$emit('update:visible', false)"
  >
    <template v-slot:title>
      <dialog-header
        :status="headerStatus"
        :title="headerTitle"
        subTitle="订单编号"
        :subText="data.order_no"
      ></dialog-header>
    </template>

    <main>
      <div class="price-info m-bottom-sm">
        <div class="flex cen-space">
          <b class="font-color-primary font-heading-2">¥{{data.pay_amount}}</b>

          <div>
            <template v-if="data.status === $val.UndoPayOrderType.wait">
              <a-button class="p-empty m-right-sm" type="link" @click="onCancelOrder(data)">取消支付</a-button>
              <a-button type="primary" @click="$router.push(`/paymusic/${data.order_id}`)">去支付</a-button>
            </template>
            <template v-else>
              <a-button class="p-empty" type="link" @click="onDelOrder(data)">删除订单</a-button>
            </template>
          </div>
        </div>
        <div>实付金额</div>
      </div>
      <!-- <div class="font-color-sub m-bottom-sm">
        <span class="m-right-xs">套餐金额 ¥460.00</span>
        <span>优惠金额 ¥80.00</span>
      </div> -->

      <a-divider></a-divider>

      <a-descriptions bordered :column="1" class="border-none table-layout-fixed m-bottom-md">
        <a-descriptions-item label="订单编号">{{data.order_no}}</a-descriptions-item>
        <a-descriptions-item label="下单时间">{{data.create_at}}</a-descriptions-item>
        <a-descriptions-item label="联系人姓名">{{data.contacter}}</a-descriptions-item>
        <a-descriptions-item label="手机号码">{{data.phone}}</a-descriptions-item>
      </a-descriptions>

      <template v-if="data.detail && data.detail.length > 0">
        <div v-for="cItem in data.detail" :key="cItem.detail_id">
          <music-detail-item class="m-bottom-lg" :data="cItem">
            <template v-slot:top="{item}">
              <b class="f-r">￥{{item.price | toPrice}}</b>
            </template>
          </music-detail-item>
        </div>
      </template>
    </main>
  </ng-dialog>
</template>

<script>
  import {Descriptions} from 'ant-design-vue';
  import DialogHeader from './DialogHeader.vue'
  import NgDialog from '@/components/NgDialog.vue';
  import MusicDetailItem from '@/pages/components/music/MusicDetailItem.vue';
  import {dialogMixins} from '@/mixins/index';
  import OrderHeaderMixins from '../_mixin';

  export default {
    data() {
      return {
        show: false
        // showDialog: false
      }
    },

    props: {
      visible: Boolean,
      data: Object
    },

    mixins: [dialogMixins, OrderHeaderMixins],

    watch: {
      visible: {
        handler(newVal) {
          this.show = newVal;
        },
        immediate: true
      }
    },

    computed: {
      isPayed() {
        return this.data.status === this.$val.UndoPayOrderType.payed;
      },

      isCancel() {
        return this.data.status === this.$val.UndoPayOrderType.cancel;
      },

      headerStatus() {
        return (this.isPayed ? 'success' : this.isCancel ? 'cancel' : 'wait');
      },

      dialogStatus() {
        return (this.isPayed ? 'success' : this.isCancel ? 'sub' : 'primary');
      },

      headerTitle() {
        return (this.isPayed ? '已支付' : this.isCancel ? '已取消' : '未支付');
      }
    },

    components: {
      NgDialog,
      DialogHeader,
      ADescriptions: Descriptions,
      ADescriptionsItem: Descriptions.Item,
      MusicDetailItem
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .pay-success {
    .header {
      height: 200px;

      .icon-font {
        /deep/ {
          svg {
            width: 72px;
            height: 72px;
          }
        }
      }
    }
  }
</style>
