<template>
  <div class="header flex flex-column cen-center">
    <icon-font class="icon-font m-bottom-md" :type="IconDir[status]"></icon-font>
    <div class="font-heading-2 m-bottom-sm">{{title}}</div>
    <div class="font-reverse-color-sub text-center">
      <span v-if="subTitle" class="mobile-block">{{subTitle}}：</span>
      <span>{{subText}}</span>
    </div>
  </div>
</template>

<script>
  const IconDir = {
    success: 'iconcorrect-empty',
    wait: 'iconwait-empty',
    cancel: 'iconbias'
  }

  export default {
    data() {
      return {
        IconDir
      }
    },

    props: {
      // success wait cancel
      status: {
        type: String,
        default: 'success'
      },
      title: String,
      subTitle: String,
      subText: String
    },

    components: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .header {
    height: 200px;

    .icon-font {
      /deep/ {
        svg {
          width: 72px;
          height: 72px;
        }
      }
    }
  }
</style>
