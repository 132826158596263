

<script>
  import {Spin, Empty, List as AntList} from 'ant-design-vue';
  import {div} from '../utils/function';
  import {Load} from '@/utils/variables';

  //   <template>
  //   <a-list :data-source="list" :loading="loading">
  //     <template v-slot:header>
  //       <a-checkbox v-if="selection" class="all-checkout">全选</a-checkbox>
  //     </template>

  //     <a-list-item slot="renderItem" slot-scope="item, index" :scopedSlots="$scopedSlots">
  //       <a-checkbox v-if="selection" class="checkout"></a-checkbox>

  //       <div class="item-outer">
  //         <slot :item="item" :index="index"></slot>
  //       </div>
  //     </a-list-item>
  //   </a-list>
  // </template>

  export default {
    data() {
      return {
        list: [],
        current: 1,
        total: 0,
        page_size: 0,
        loading: false,
        checkAll: false,
        indeterminate: false,
        checkSelects: [],
        checkSelectScopes: [],
        loadStatus: Load.init,
        Load,
      }
    },

    render(h) {
      let scopedSlots = {...this.$scopedSlots};
      let defaultSlot = scopedSlots.default;

      const SlotScops = {
        renderItem: (props, index) => {
          let on = {change: () => {this.onChangeCheckbox(index)}}

          return <a-list-item scopedSlots={scopedSlots}>
            {this.selection ? <a-checkbox checked={this.checkSelects[index]} class="checkbox" on={{change: (value) => {this.onChangeCheckbox(value, index, props)}}}></a-checkbox> : ''}
            {defaultSlot ? defaultSlot({item: props}) : ''}
          </a-list-item>
        },
      }

      if (this.selection || scopedSlots.header) {
        SlotScops.header = (props) => {
          return h('div', [
            this.selection ? h('ACheckbox', {
              props: {indeterminate: this.indeterminate, checked: this.checkAll},
              on: {change: this.onChangeAll},
              class: 'all-checkout'
            }, ['全选']) : '',
            scopedSlots.header ? scopedSlots.header({item: props}) : ''
          ])
        }
      }

      let loadStatus = this.loadStatus;

      let loadMore = () => {
        if (loadStatus !== Load.empty && loadStatus !== Load.error) {
          if (loadStatus === Load.noMore) {
            return (<span class="no-more-box font-color-sub flex cen-center active-class">没有更多</span>)
          } else {
            return (<a-button
              disabled={loadStatus === Load.loading}
              class="more-box-wrap flex cen-center active-class"
              on={{click: this.onClickMore}}
            >
              <span>查看更多</span>
              <div class="more-icon-box flex cen-center">
                <icon-font type="iconarrow-line-bottom"></icon-font>
              </div>
            </a-button>)
          }
        }
      }

      loadMore = loadMore();

      return (
        <div>
          <a-list data-source={this.list} class={['ng-list', this.selection ? 'ng-list-selection' : '']} loading={this.loading} scopedSlots={SlotScops}>
          </a-list>
          {loadMore}
        </div >
      )
    },

    props: {
      urlFunc: Function,
      params: {
        type: Object,
        default() {
          return {}
        }
      },
      idKey: String,
      changeData: Array,
      emptyText: String,
      load: Boolean,
      reload: Boolean,
      scroll: {
        type: Object,
        default() {
          return {}
        }
      },
      dataList: {
        type: Array,
        default() {
          return []
        }
      },
      selection: {
        type: Boolean,
        default: false
      },

      clearSelected: Boolean,

      changeSelect: Function
    },

    computed: {
      isRequest() {
        return typeof this.urlFunc === 'function' && (!this.dataList || !this.dataList.length > 0);
      }
    },

    created() {
      this.newParams = {page: 1};
    },

    watch: {
      params: {
        handler(newVal) {
          this.$nextTick(() => {
            if (newVal && typeof newVal === 'object' && this.isRequest) {
              this.newParams = Object.assign({}, this.newParams, newVal);

              newVal && newVal.page && (this.current = parseInt(newVal.page) || 1);

              this.getData(this.newParams, true);
            }
          })
        },
        immediate: true
      },

      changeData(newVal) {
        if (newVal && newVal.length > 0) {
          let musicIdObj = {};
          let musicIdList = newVal.map(item => {
            let id = item[this.idKey];

            musicIdObj[id] = item;

            return id ? id : 0;
          });

          this.list = this.list.map(item => {
            let id = item[this.idKey] ? item[this.idKey] : 0

            return !~musicIdList.indexOf(id) ? item : Object.assign({}, item, musicIdObj[id] || {});
          })

          this.$emit('listChange', this.list);
        }
      },

      load(newVal) {
        this.$nextTick(() => {
          if (newVal && this.isRequest) {
            this.getData(this.newParams);
          }
        })
      },

      reload(newVal) {
        this.$nextTick(() => {
          if (newVal && this.isRequest) {
            this.newParams.page = 1;
            this.getData(this.newParams, true);
          }
        })
      },

      dataList: {
        handler(newVal) {
          this.$nextTick(() => {
            if (!this.isRequest) {
              this.list = newVal;
            }
          })
        },
        immediate: true
      },

      checkSelectScopes(newVal) {
        let ids = [], sopes = [];

        if (Array.isArray(newVal)) {
          sopes = newVal.filter(item => item);
          ids = newVal.map(item => item ? item[this.idKey] : '')
        }

        typeof this.changeSelect === 'function' && this.changeSelect(sopes, ids);
      },

      clearSelected(newVal) {
        if (newVal) {
          this.checkSelects = this.list.map(() => false);
          this.checkSelectScopes = [];
          this.indeterminate = false;
          this.checkAll = false;
        }
      }
    },

    components: {
      ASpin: Spin,
      AEmpty: Empty,
      AList: AntList,
      AListItem: AntList.Item
    },

    methods: {
      onClickMore() {
        this.newParams.page += 1;

        this.getData(this.newParams);
      },

      onChangeAll() {
        if (this.indeterminate) {
          this.checkAll = true;
          this.indeterminate = false;
        } else {
          this.checkAll = !this.checkAll;
        }

        if (this.checkAll) {
          this.checkSelects = this.list.map(() => true);
          this.checkSelectScopes = [].concat(this.list);
        } else {
          this.checkSelects = this.list.map(() => false);
          this.checkSelectScopes = [];
        }
      },

      onChangeCheckbox(event, index, scope) {
        let checked = event.target.checked;

        this.checkSelects.splice(index, 1, checked);
        this.checkSelectScopes.splice(index, 1, checked ? scope : null);

        let filterCheck = this.checkSelects.filter(item => item);

        let len = filterCheck.length;
        let listLen = this.list.length;

        this.checkAll = len === listLen;
        this.indeterminate = len < listLen && len > 0;
      },

      getData(newParams, reload = false) {
        if (typeof this.urlFunc !== 'function') return false;

        this.loading = true;
        this.loadStatus = Load.loading;

        typeof this.urlFunc === 'function' && this.urlFunc(newParams).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let newData = res.data.data;
            let total = newData.total, pagesize = newData.page_size, page = this.newParams.page;
            let curTotal = pagesize * page;

            let list = newData ? newData.list : [];

            this.list = !reload ? this.list.concat(list) : [...list];
            this.total = newData.total;
            this.page_size = newData.page_size;

            this.checkSelects = this.$root.getDefaultArray(list.length, false);
            this.checkSelectScopes = this.$root.getDefaultArray(list.length, {});

            this.loadStatus = total == 0 ? Load.empty : total <= curTotal ? Load.noMore : Load.load;

            this.$emit('listChange', this.list);
            this.$emit('dataChange', newData);
          }

          this.$emit('update:load', false);
          this.$emit('update:reload', false);

          this.loading = false;
        }).catch(() => {
          this.loadStatus = Load.error;
          this.loading = false;
        })
      },

      // onChangePagination(current) {
      //   let page = {page: current};

      //   this.current = current;

      //   this.newParams = Object.assign({}, this.newParams, page);

      //   this.$root.$goSearch(page);

      //   window.scrollTo(0, 0);

      //   this.getData(this.newParams);
      // }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .ant-list-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 24px;
      padding-bottom: 24px;
      width: 100%;

      > div {
        width: 100%;
      }

      .item-header {
        border-bottom: 1px solid $border-color;
        padding: 0 0 12px;
        margin-bottom: 16px;
      }
    }
  }

  .ng-list {
    &.ng-list-selection {
      ::v-deep {
        .all-checkout {
          margin-bottom: 8px;
        }

        .ant-list-item {
          position: relative;
          padding-left: 30px;
        }

        .checkbox {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }

  .no-more-box {
    margin-top: 16px;
  }

  .more-box-wrap {
    display: block;
    margin: 24px auto 0;
    cursor: pointer;
    width: 150px;
    text-align: center;
    border: 0;

    .more-icon-box {
      display: inline-block;
      width: 36px;
      height: 36px;
      line-height: 36px;
      color: #fff;
      text-align: center;
      margin-left: 8px;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 50% 50%;
    }

    &[disabled] {
      background: none;

      .more-icon-box {
        background: rgba(0, 0, 0, 0.45);

        &:hover {
          background: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }

  .empty-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
