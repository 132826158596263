<template>
  <div class="my-collect pos-relative">
    <div class="tab-btns">
      <a-select
        class="w-100"
        :options="AllOptions"
        defaultValue='2'
        size="large"
        @change="onChange"
      ></a-select>
    </div>

    <coupon-list  @showCouponRule='showCouponRule = true' @showExchangeCoupon='showExchangeCoupon = true' :requestData="requestData"></coupon-list>
    <MoreData @dataChange='dataChange' :params='couponListParams' :isMore=true :request='$axios.CouponList'></MoreData>

    <DialogExchangeCoupon @reload='getData' @close='showExchangeCoupon=false' :visible="showExchangeCoupon"></DialogExchangeCoupon>
    <DialogCouponRule  @close='showCouponRule=false' :visible="showCouponRule"></DialogCouponRule>
  </div>
</template>

<script>
  import CouponList from './components/CouponList.vue'
  import DialogExchangeCoupon from './components/DialogExchangeCoupon.vue'
  import DialogCouponRule from './components/DialogCouponRule.vue'
  import MoreData from './components/MoreData';
// {label: '全部优惠券', value: '1'},
  let AllOptions = [{label: '待使用优惠券', value: '2'},{label: '已使用优惠券', value: '3'},{label: '已过期优惠券', value: '4'}]


  export default {
    data() {
      return {
        couponListParams: {},
        AllOptions,
        requestData:[],
        showExchangeCoupon:false,
        showCouponRule:false,
      }
    },
    components: {
      CouponList,
      DialogExchangeCoupon,
      DialogCouponRule,
      MoreData
    },

    created() {
      let params = {};
      params.page_size = '20'
      params.type = '2'
      this.couponListParams = params;
      // this.getData(this.couponListParams)
    },

    methods: {
      dataChange(data){
        console.log('data: ', data);
            this.requestData = data

      },
    
      getData(param){
        this.$axios.CouponList(this.couponListParams).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.requestData = data.data.list;
          }
        })
      },
      onChange(status) {
        this.couponListParams = Object.assign({}, this.couponListParams, {type: status});
        // this.getData(this.couponListParams)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .tab-btns {
    width: 150px;
  }
</style>
