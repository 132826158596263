<template>
  <div class="fine-music-list flex">
    <div class="music-item" v-for="(item, index) in newList" :key="index">
      <router-link class="w-100" :to="`/playListDetail/${item.playlist_id}`">
        <div class="img-all-box-a enlarge" :style="imgBg(item.logo_url)">
          <!-- <ng-image class="music-img" :src="item.logo_url"></ng-image> -->
          <div class="music-play-btn" >
            <!-- @click="palyVideo(item)" -->
            <icon-font type="iconplay-fff" />
          </div>
          <div class="lable-wrap">
            <span class="lable-item" v-for="lable in item&& item.labels"  :key='lable.id'>{{lable&&lable.name}}</span>
          </div>
            <div
              class="collect-icon"
              :class="{'active': item.is_collected == '1'}"
              @click.prevent="handleCollect(item,index)"
            >
              <icon-font v-if="item.is_collected == '1'" type="iconheart" />
              <icon-font v-else type="iconheart-empty" />
            </div>
        </div>
        <div class="music-info">
          <div
            class="music-type text-ellipsis ft14 font-bold"
           
          >{{item.name}}</div>
           <!-- @click="handlePlaylist(item)" -->
        </div>
      </router-link>
    </div>
    <!-- <moreBox class="hidden-md" @handleMore="handleMore"></moreBox> -->
  </div>
</template>
<script>
  // import {Tag} from "ant-design-vue";
  // import moreBox from "@/components/moreBox";
  import musicHandle from '@/mixins/musicHandle'

  export default {
    props: {
      data: {
        type: Array
      }
    },
    mixins: [musicHandle],

    data() {
      return {
        num: 8,
        CollectMusic:[]
      }
    },
    components: {
      // ATag: Tag,
      // moreBox
    },

    computed: {
      newList() {
        let data = this.data;
        console.log('this.data: ', this.data);
        this.data.map(item => {
          console.log('item: ', item);
          item.labels = item.labels.splice(0,3)
        })
        let num = this.num;
        let list =  data && data.length > 0 ? data : (new Array(num)).fill('').map(() => ({}));
        return list
      },
      imgBg(url){
        return (url) =>{
            return {
            'background-image':`url(${url})`
          }
        }
        
      }
    },

    created() {
       this.goodType = 'songlist';
    },

    methods: {
      handleCollect(scope) {
        typeof this.goCollectMusic === 'function' && this.goCollectMusic(scope, scope.playlist_id, false)
         
      },
      handlePlaylist(item) {
        console.log('item: ', item);
        this.$router.push(`/playListDetail/${item.playlist_id}`);
      },
      handleMore() {
        console.log('handleMore: ');

      },
      callback(key) {
        console.log(key);
      },
    },
  }
</script>
<style lang="scss">
  // mobile
  @media screen and (max-width: $screen-width-md) {
    .fine-music-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // justify-content: space-between;
      .music-item {
        width: 14.4rem;
        // height: 310px;
        margin-right: 1rem;
        &:nth-child(2n) {
          margin-right: 0;
        }
          .img-all-box-a{
            width: 100%;
            // height: 100%;
            height: 10rem;
           position: relative;
              .music-play-btn {
                display:none;
                border-radius: 50%;
                background-color: #fff;
                cursor: pointer;
                opacity: 0.8;
                width: 48px;
                height: 48px;
                font-size: 3.2rem;
                color: #000;
                position: absolute;
                top: 50%;
                left: 50%;
                line-height: 48px;
                transform: translate(-50%, -50%);
                cursor: pointer;
                text-align: center;
                padding:1px 0 0 5px ;
              // background-color: red;
            }
            .lable-wrap{
              width: 100%;
              padding-right: 20px;
              position: absolute;
              left: 10px;
              bottom: 10px;
              font-size: 12px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap-reverse;
              .lable-item{
                // flex: 0 0 44px;
                // max-width: 60px;
            
                padding: 0 4px;
                margin:0  8px 4px 0 ;
                 border: 1px solid #fff;
                  border-radius: 4px;
              }
            }

             .collect-icon {
              position: absolute;
              top: 10px;
              right: 10px;
              font-size: 28px;
              line-height: 1;
              color: #fff;

              &.active {
                color: $primary-color;
              }
            }
         }
         .enlarge{
        
          // background: orange;
          border-radius:8px ;
          overflow: hidden;
          transition: all 0.4s;
          -moz-transition:all .4s;
          -webkit-transition:all .4s;
          -o-transition:all .4s;
          background-repeat:no-repeat;   /* 不会重复 */
          background-position: center;   /* 是为了让图片放大的时候从中部放大，不会偏移 */
          background-size: 100% 100%;
        }
        .enlarge:hover{
          background-size: 120% 120%;
          .music-play-btn{
            display: inline-block;
          }
        }
        margin-bottom: 2.4rem;
        .music-img {
          width: 100%;
          // max-width: 310px;
          // height: 310px;
        }
       

        .music-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 11px;
          .music-type {
            color: #000;
          }
          .ant-tag {
            margin-top: 0.3rem;
          }
        }
      }
    }
  }
  // pc
  @media screen and (min-width: $screen-width-md) {
    .fine-music-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .music-item {
        width: 24%;
        // height: 310px;
        margin-right: 1.3%;
        &:nth-child(4n) {
          margin-right: 0;
        }
         .img-all-box-a{
            width: 100%;
            // height: 100%;
            height: 200px;
           position: relative;
              .music-play-btn {
                display:none;
                border-radius: 50%;
                background-color: #fff;
                cursor: pointer;
                opacity: 0.8;
                width: 48px;
                height: 48px;
                font-size: 32px;
                color: #000;
                position: absolute;
                top: 50%;
                left: 50%;
                line-height: 48px;
                transform: translate(-50%, -50%);
                cursor: pointer;
                text-align: center;
                padding:2px 0 0 3px ;
              // background-color: red;
            }
            .lable-wrap{
              position: absolute;
              left: 20px;
              bottom: 20px;
              font-size: 12px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              flex-direction: row;
              span{
                flex: 0 0 44;
                // max-width: 60px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                padding: 0 4px;
                margin:0  8px 4px 0 ;
                 border: 1px solid #fff;
                  border-radius: 4px;
              }
            }

             .collect-icon {
              position: absolute;
              top: 10px;
              right: 10px;
              font-size: 28px;
              line-height: 1;
              color: #fff;

              &.active {
                color: $primary-color;
              }
            }
         }
         .enlarge{
        
          // background: orange;
          border-radius:8px ;
          overflow: hidden;
          transition: all 0.4s;
          -moz-transition:all .4s;
          -webkit-transition:all .4s;
          -o-transition:all .4s;
          background-repeat:no-repeat;   /* 不会重复 */
          background-position: center;   /* 是为了让图片放大的时候从中部放大，不会偏移 */
          background-size: 100% 100%;
        }
        .enlarge:hover{
          background-size: 120% 120%;
          .music-play-btn{
            display: inline-block;
          }
        }
        margin-bottom: 24px;
        .music-img {
          width: 100%;
          max-width: 300px;
          cursor: pointer;
          // height: 310px;
        }
        .music-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 11px;
          .music-type {
            color: #000;
            cursor: pointer;
          }
          .ant-tag {
            margin-top: 3px;
          }
        }
      }
    }
  }
</style>