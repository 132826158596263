<template>
  <div class="song-list-pay">
    <div class="container-sm">
      <page-title title="订单提交成功，请尽快付款" sub="请您在30分钟内完成支付，否则订单会被自动取消"></page-title>

      <a-divider class="m-empty m-bottom-lg"></a-divider>

      <div class="text-center m-bo">
        <b class="font-color-primary font-heading-2">¥{{orderInfo.total_amount | toPrice}}</b>
        <div class="m-bottom-sm">实付金额</div>
        <div class="font-color-sub m-bottom-sm">
            <span class="m-right-xs">订单金额 {{orderInfo.order_amount | toPrice}}</span>
            <span>优惠金额 {{orderInfo.discount_amount | toPrice}}</span>
        </div>
      </div>

      <pay-way v-if="!payWay" @change="onChangePayWay"></pay-way>
      <template v-else>
        <ali-pay v-if="payWay=== $val.PayType.zfb" :order_id="order_id" @success="onPaySuccess"></ali-pay>
        <wechat-pay v-if="payWay=== $val.PayType.wx" :order_id="order_id" @success="onPaySuccess"></wechat-pay>
        <com-to-com-pay
          v-if="payWay=== $val.PayType.gdg"
          :order_id="order_id"
          @success="onPaySuccess"
          style="margin-top: 42px;"
        ></com-to-com-pay>
      </template>

      <a-divider class="m-empty m-top-lg m-bottom-lg"></a-divider>

      <div class="pay-info">
        <a-descriptions
          :title="`订单信息(${orderInfo.detail ? orderInfo.detail.length : 0 }首)`"
          :column="1"
          class="border-none m-bottom-lg"
        >
          <a-descriptions-item label="订单编号">{{orderInfo.order_no}}</a-descriptions-item>
        </a-descriptions>
      </div>

      <div class="pay-info">
        <template v-for="item in orderInfo.detail">
          <music-detail-item class="m-bottom-lg" :data="item" :key="item.music_id">
            <template v-slot:top>
              <b class="f-r">￥{{item.price}}</b>
            </template>
          </music-detail-item>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import {Descriptions} from 'ant-design-vue';

  import PageTitle from '@/pages/components/common/PageTitle.vue'
  import MusicDetailItem from '@/pages/components/music/MusicDetailItem.vue'
  import AliPay from './components/AliPay.vue';
  import PayWay from './components/PayWay.vue'
  import WechatPay from './components/WechatPay.vue'
  import ComToComPay from './components/ComToComPay.vue'

  import payMixins from '@/mixins/pay'

  export default {
    data() {
      return {
        // showPaySuccessDialog: true,
        orderInfo: {},
        payWay: '',
        order_id: ''
      }
    },

    beforeRouteLeave(to, from, next) {
      let ignorePathNames = ['paysuccess', 'payerror', 'payovertime', 'paying']

      if (!~ignorePathNames.indexOf(to.name)) {
        this.$confirm({
          title: '确认放弃支付？',
          onOk() {
            next();
          }
        });
      } else {
        next();
      }
    },

    created() {
      let order_id = this.$route.params.order_id || '';

      this.order_id = order_id;

      this.payWay = this.$route.query.payway || '';

      this.getOrderDetail({order_id})
    },

    mixins: [payMixins],

    components: {
      AliPay,
      PayWay,
      PageTitle,
      ADescriptions: Descriptions,
      ADescriptionsItem: Descriptions.Item,
      MusicDetailItem,
      WechatPay,
      ComToComPay
    },
    methods: {
      getOrderDetail(params) {
        this.$store.commit('changeContentLoading', true);

        this.$axios.OrderInfo(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.orderInfo = data.data || {};
          }

          this.$store.commit('changeContentLoading', false);
        }).catch(() => {
          this.$store.commit('changeContentLoading', false);
        })
      },

      onPaySuccess(type) {
        let isPaying = type === this.$val.OrderStatus.doingPay;

        let url = isPaying
          ? `/payresult/paying/${this.order_id}?order_type=${this.$val.orderType.music}`
          : `/payresult/success/${this.order_id}?order_type=${this.$val.orderType.music}`

        this.$router.replace(url);
      },

      onChangePayWay(value) {
        this.payWay = value;
      }
    },

  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .cart-detail-item {
      border-bottom: 0;
    }
  }

  .song-list-pay {
    padding-bottom: 40px;
  }

  .top-content {
    height: 246px;
    color: #fff;
    background: #2e3557;

    .top-title {
      > img {
        width: 72px;
        height: 72px;
      }
    }

    .last-item {
      > span {
        padding: 4px 8px;
        border: 1px solid rgba($color: #fff, $alpha: 0.12);

        &:last-child {
          border-left: none;
        }
      }
    }
  }

  .q-and-a {
    /deep/ {
      .ant-descriptions-view {
        .ant-descriptions-row:nth-child(2n) {
          td,
          th {
            padding-bottom: 15px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .bottom-content {
      padding: 0 1rem;
    }
  }
</style>
