<template>
  <div class="ng-header border-bottom-pos" :class="[isSearching ? 'search-open' : '']">
    <div class="ng-header-content container pos-relative">
      <router-link class="svg-box w-100 h-100 logo" to="/musicFine">
        <img src="/images/web/logo-mobile.png" alt="logo" />
      </router-link>

      <div class="f-r search-box">
        <a-input
          v-model="searchValue"
          class="search"
          size="large"
          :allowClear="false"
          placeholder="输入音乐名/风格/情绪"
          @keydown.enter="onSearch"
          @focus="onSearchFocus"
          @blur="onSearchBlur"
          ref="search"
        >
          <span class="font-heading-1" slot="prefix" @click="onSearch">
            <icon-font class="search-icon" type="iconsearch"></icon-font>
          </span>
          <icon-font
            v-if="searchValue"
            class="close-icon"
            slot="suffix"
            type="iconclose"
            @click="clearSearch"
          ></icon-font>
        </a-input>

        <div class="mask" @click="onSearchBlur"></div>
      </div>

      <div class="header-right">
        <icon-font class="m-right-sm" type="iconsearch" @click="onSearchInMobile"></icon-font>

        <!-- <svg class="search-svg" @click="handleOperation('/myNews')">
            <use xlink:href="#iconnotice" />
        </svg>-->

        <a-badge class="m-right-sm" :count="$store.state.goodNumOfCart" :overflow-count="99">
          <router-link class="header-icon" to="/cart">
            <icon-font type="iconcart-empty"></icon-font>
          </router-link>
        </a-badge>

        <icon-font class="menu-icon" type="iconmenu" @click="showMobileHeaderMenuDrawer = true"></icon-font>
      </div>
    </div>

    <a-drawer
      wrapClassName="menu-info header-menu-mobile-drawer"
      placement="right"
      :closable="true"
      width="23.4rem"
      :visible="showMobileHeaderMenuDrawer"
      @close="showMobileHeaderMenuDrawer = false"
    >
      <div
        class="menu-info-item border-bottom"
        v-for="(item, index) in mobileMenuList"
        :key="index"
        @click="handleMenu(item)"
      >{{ item.name }}</div>

      <div class="user-login flex flex-column cen-center">
        <ng-image class="user-img" :src="userInfo.profile_photo_url"></ng-image>
        <b class="user-name ft20">{{ userInfo.name }}</b>
        <a-button v-if="userInfo.user_id" type="text" class="loginout" @click="goOutLogin">退出登录</a-button>
        <div v-if="!userInfo.user_id" class="ft20 login-text" @click="login">请登录</div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
  import {Badge} from "ant-design-vue";
  import NgImage from "~/components/NgImage.vue";

  import HeaderMixins from './header_mixins'

  const MenuList = function () {
    return [
      {
        id: "2",
        name: this.$t("headerMenu.fine"),
        to: "/musicFine",
      },
      {
        id: "1",
        name: this.$t("headerMenu.allMusic"),
        to: "/allmusic",
      },
      {
        id: '4',
        name: '歌单',
        to: '/playlist',
      },
      {
        id: "3",
        name: this.$t("headerMenu.vipMeal"),
        to: "/package",
      },
      {
        id: "5",
        name: this.$t("headerMenu.supplier"),
        to: "/supplier",
      },
      {
        id: '6',
        to: `/user`,
        name: '个人中心',
        onlyMobile: true
      },
    ];
  };

  export default {
    data() {
      return {
        showMobileHeaderMenuDrawer: false,
      };
    },

    computed: {
      mobileMenuList() {
        return MenuList.call(this);
      },

      Menu() {
        return MenuList.call(this);
      },
    },

    mixins: [HeaderMixins],

    watch: {
    },

    created() {
    },

    components: {
      NgImage,
      ABadge: Badge,
    },

    methods: {
      handleMenu(item) {
        this.showMobileHeaderMenuDrawer = false;

        this.$router.push({
          path: item.to
        })
      },

      onSearchInMobile() {
        this.isSearching = true;
        this.$refs.search.focus();
      }
    },
  };
</script>

<style lang="scss">
  .header-menu-mobile-drawer {
    .ant-drawer-header {
      height: 6.3rem;
    }

    .ant-drawer-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 64px !important;
    }

    .ant-drawer-close {
      width: 2.4rem;
      // height: 24px;
      font-size: 24px;
      right: 10px;
    }
  }
</style>

<style lang="scss" scoped>
  .ng-header {
    width: 100%;
    color: #fff;
    background-color: #fff !important;

    .search-box {
      position: absolute;
      right: 34px;
      left: 100%;
      display: flex;
      align-items: center;
      background: #fff;
      transition: all linear 0.2s;
      z-index: 10;
      margin-top: -1px;

      .search {
        position: relative;
        z-index: 1000;
        overflow: hidden;
      }
    }

    &.search-open {
      .search-box {
        left: 0;

        .mask {
          position: absolute;
          top: -100000px;
          bottom: -100000px;
          left: -100000px;
          right: -100000px;
          z-index: 99;
        }
      }
    }
  }

  .header-menu-mobile-drawer {
    .menu-info-item {
      width: 100%;
      height: 4.8rem;
      display: flex;
      justify-self: flex-start;
      align-items: center;
      padding-left: 1rem;
      font-size: 1.4rem;
      color: rgba(0, 0, 0, 0.85);
    }

    .user-login {
      margin-top: 24px;

      .user-img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }

      .user-name {
        margin: 8px 0;
      }

      .loginout {
        width: 88px;
        height: 32px;
      }

      .login-text {
        font-weight: bold;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .ng-header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 64px;

    .logo {
      width: 88px;
      height: 40px;

      > img {
        height: 100%;
      }
    }

    .header-right {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      color: #737373;
      font-size: 24px;

      .header-icon {
        color: inherit;
        font-size: 24px;
      }

      .menu-icon {
        position: relative;
        margin-left: 8px;
        color: #262626;

        &::after {
          content: " ";
          position: absolute;
          top: 0;
          bottom: 0;
          left: -1rem;
          width: 1px;
          background-color: $border-color;
        }
      }
    }
  }
</style>
