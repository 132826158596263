<template>
  <div>
    <ng-dialog
      class="dialog-shop-cart"
      :visible.sync="show"
      :maskClosable="false"
      :bodyStyle="bodyStyle"
      @close="onClose"
      :footer="null"
    >
      <template v-slot:title>
        <div class="header flex">
          <span class="play-icon m-right-xs music-play-icon-box">
            <music-play-icon :scope="data"></music-play-icon>
          </span>

          <div>
            <b>{{data.name}}</b>
            <div class="font-base">{{data.suplr_name}}</div>
          </div>
        </div>
      </template>

      <div v-if="isHigh" class="m-bottom-md">
        <p>尊敬的用户，您享有无损音乐下载权限，如确定使用该作品，请及时完善授权信息。</p>
        <a-button v-if="isActive" type="primary" size="small" @click="onActive">现在激活</a-button>
      </div>
      <div v-else class="m-bottom-md">
        <p>你将下载带水印低音质版本的曲目音频，该版本仅供学习和试用，如确定使用请购买授权，获取无损音乐文件。</p>
      </div>

      <div class="f-r">
        <a-button class="m-right-sm" @click="downloadTryMusic(data)">下载试用音乐</a-button>

        <a-button v-if="isHigh" @click="donwloadHighMusic(data)">下载无损音乐</a-button>
      </div>
    </ng-dialog>

    <dialog-active-auth-package
      v-if="$store.state.login"
      :visible.sync="showAuthPackageDialog"
      :data="handData"
      @success="onSucces"
    ></dialog-active-auth-package>
  </div>
</template>

<script>
  import NgDialog from "@/components/NgDialog.vue";
  import DialogActiveAuthPackage from '@/pages/components/order/DialogActiveAuthPackage.vue'
  import MusicPlayIcon from "@/pages/components/music/MusicPlayIcon.vue";
  import {dialogMixins} from "@/mixins/index";
  import musicHandle from '@/mixins/musicHandle';

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        showAuthPackageDialog: false,
        handData: {},
      };
    },

    components: {
      NgDialog,
      MusicPlayIcon,
      DialogActiveAuthPackage
    },

    computed: {
      isHigh() {
        return this.data && this.data.op_download == this.$val.CommonCheck.correct;
      },

      isActive() {
        return this.data && this.data.op_auth == this.$val.CommonCheck.correct;
      }
    },

    props: {
      data: Object
    },

    mixins: [dialogMixins, musicHandle],

    methods: {
      showCartDialog(bool) {
        this.$store.commit("changeMusicDownloadDialog", bool);
      },

      onClose() {
        this.showCartDialog(false)
      },

      onActive() {
        this.showAuthPackageDialog = true;
        this.handData = this.data;
      },

      onSucces() {
        this.showCartDialog(false)
      }
    }
  };
</script>

<style lang="scss" scoped>
  .dialog-shop-cart {
    .header {
      .play-icon {
        width: 32px;
        height: 32px;
        margin-top: 6px;
      }
    }
  }
</style>
