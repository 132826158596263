<template>
  <div class="help-center-wrap h-100">
    <div class="help-center">
      <div class="help-center-title">
        <h1 class="title flex cen-start">{{ "帮助中心" }}</h1>
      </div>
      <div class="help-tab flex cen-center">
        <div
          class="client flex cen-center"
          :class="{ 'active-class': activeTab === 0 }"
          @click="handleHelpTab(0)"
        >
          <ng-image class="kehu-img" :src="kehuImg"></ng-image>
          <span>客户</span>
        </div>
        <div
          class="musician flex cen-center"
          :class="{ 'active-class': activeTab === 1 }"
          @click="handleHelpTab(1)"
        >
          <ng-image class="musician-img" :src="musicianImg"></ng-image>
          <span>音乐人</span>
        </div>
      </div>
      <div class="small-tabs-wrap flex row cen-center">
        <a-tabs v-model="activeKey" @change="changeTab" :animated="false">
          <a-tab-pane
            v-for="tab in tabList[activeTab]"
            :key="tab.id"
            :tab="tab.tab"
          >
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="help-collapse-wrap">
        <a-collapse v-model="activeCollapse" expand-icon-position="right">
          <template #expandIcon="props">
            <a-icon type="right" :rotate="props.isActive ? 270 : 90" />
          </template>
          <template
            v-for="(item, index) in helpLists[activeTab][activeKey - 1]"
          >
            <a-collapse-panel
              :key="index + ''"
              :header="item.title"
              :style="customStyle"
            >
              <p v-html="item.text"></p>
            </a-collapse-panel>
          </template>
        </a-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse as ACollapse, Icon } from "ant-design-vue";
const ACollapsePanel = ACollapse.Panel;

import kehuImg from "@/assets/images/help-kehu.png";
import musicianImg from "@/assets/images/help-musician.png";
export default {
  data() {
    return {
      musicianImg,
      kehuImg,
      text: `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`,
      customStyle:
        "background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden",
      activeTab: 0,
      activeKey: 1,
      activeCollapse: [],
      helpLists: [
        // 客户的
        [
          [
            {
              title: "曲库中的音乐从哪里来？",

              text: `目前我们已与Lynne Publishing、Sonic Librarian等十几家音乐公司、厂牌，以及数百位音乐人签约，完成逾四十万首罐头音乐和数千首vocal流行音乐的一级代理曲库搭建。<br><br> 
                平台的SKU除了罐头音乐，也聚合了众多独立音乐人和艺术家的原创作品，比如经由黄奕、马苏等人在腾讯视频跨年节目中翻唱后冲上热搜的PattyCake Productions厂牌的《 Tought Love（后妈茶话会）》；以及《乘风破浪的姐姐2》中李菲儿初舞台编舞使用的潮流组合APEX的作品《2045》等，皆来自于火花音悦平台的授权。

              `,
            },
            {
              title: "在SPARK.ART购买的音乐可以用在哪里？",
              text: `客户购买音乐后，可以将其应用在相应的产品场景（商业广告（宣传片）；自媒体视频；电视剧/综艺/动画片；电影；纪录片；游戏/内置音乐；广播剧/有声读物；网课/直播；线下公播；个人项目（非商用））中。<br><br> 
              但音乐版权归属依然是音乐人或版权方，不属于SPARK.ART或客户。如需对音乐进行改编或作为个人作品发行，请联系客服咨询。
              `,
            },
            {
              title: "授权期限是多久？",
              text: `目前我们提供1年和永久两种授权周期，该周期指的是音乐作品应用项目的投放期限。授权期限从购买当日算起，同时会在授权书上显示`,
            },
            {
              title: "购买的音乐可以使用几次？",
              text: `
              我们以项目为单位对音乐进行授权，即同一首音乐在不同项目中视为多次授权。<br><br> 
              其中以下情况可视为同一个项目，即一首音乐应用在多个视频或音频中仅视为一次授权：<br><br> 
              同一部影视剧、综艺、动画中的不同集数；<br><br> 
              同一系列纪录片、短视频、有声书、课程；<br><br> 
              某品牌同一次运营活动的不同物料；<br><br> 
              作为同一个平台的UGC素材。<br><br> 
              但要注意的是，如果该音乐作品的应用项目需要投放到多个渠道，则需要多次购买。
              `,
            },
            {
              title: "曲库中的音乐可以用于哪些渠道？",
              text: `商业广告（宣传片）：网络、电视（含线下）、全渠道<br><br>
                      自媒体视频：单账号，多账号。<br><br>
                      电视剧/综艺/动画片：网络、全渠道<br><br>
                      电影：网络、院线、电影节<br><br>
                      纪录片：网络、全渠道<br><br>
                      游戏/内置音乐：商业游戏、小型游戏、APP内置、UGC平台、硬件内置<br><br>
                      广播剧/有声读物：全渠道<br><br>
                      网课/直播：网络、全渠道<br><br>
                      线下公播：现场活动、小型门店、大型商超<br><br>
                      个人项目（非商用）：学生教育、原创视频`,
            },
            {
              title: "是否有批量优惠或会员服务？",
              text: `我们提供多种阶梯的批量优惠，更有包年会员，详情请咨询客服或添加官方微信客服号：火花音悦客服（微信号：sparkmusic2021）。`,
            },
           
            {
              title: "购买音乐后会提供相应的凭证及发票吗？",
              text: `用户购买音乐后我们可以提供专业授权证书、开具发票、申请纸质授权合同，提供授权证书，可以通过SSM授权查询功能查询使用授权。`,
            }
            
          ],
          [
            {
              title: "如何购买心仪的音乐？",
              text: `首先，点击心仪的音乐右侧的购物车标识——选择此音乐的授权用途、授权地域、授权期限以及使用方式，此时会显示该音乐在该种状态下的价格，点击【进入购物车】，确认订单中音乐的详情后点击【递交订单】跳转至结算页面——扫描支付二维码即可购买该音乐的使用授权。`,
            },
            {
              title: "单首音乐可否同时选择多个授权用途？",
              text: `我们的授权是以项目为单位，因此如果想要购买单首音乐不同用途的授权，可以将不同授权用途添加进购物车一起结算，也意味着需要支付实际使用用途数量相对应的价格。`,
            },
            {
              title: "我们支持哪些支付方式？",
              text: `目前线上支持微信及支付宝扫码付款。<br><br>
              如果需要公对公银行转账，请联系客服。`,
            },
            {
              title: "如何下载授权书和音乐？",
              text: `付款后，在【已购买的音乐】中即可下载电子版授权书（如有需要可自行打印）和高清无水印音乐`,
            },
            {
              title: "授权书可以修改吗？",
              text: `可以提供一次的修改机会，请直接联系客服。`,
            },
            {
              title: "如何申请发票，提供哪些种类的发票，申请周期是多久？",
              text: `
                付款成功后可以在【我的订单】页面点击【申请发票】进入申请页面，填写开具发票所需信息。<br><br>
                我们提供电子版普通发票、纸质版普通发票和纸质版专用发票。<br><br>
                在发票余量足够的情况下，我们每周二和每周四处理一次发票信息，能够保证在15个工作日内处理完毕。
              `
            },
            {
              title: "发票可以重开吗？",
              text: `如果是电子版发票，则不可以重开，因为税务局不允许撤销之前的发票。<br><br>
                  如果是纸质版发票，并且是由于工作人员这边信息录入的失误，会尽快重开补上。
              `
            },
            {
              title: "支持签订合同吗，合同申请周期是多久？",
              text: `支持。如需纸质版合同走公司报账流程，请直接联系客服。<br><br>
                  与发票相同，我们每周二和每周四处理一次合同信息，能够保证在5个工作日内处理完毕。同时，授权书与合同的效力是相同的。如在公司申请报账流程中必需合同，则可申请合同。
              `
            },
            {
              title: "音乐买错了可以换吗？",
              text: `音乐属于虚拟商品，不支持退款，请您谅解。因涉及到与音乐人的结算问题，如果已经购买成功，只能在相同音乐人的作品中进行更换，无法更换不同音乐人的作品。
              `
            },
            {
              title: "购买的音乐可以进行剪辑和改编吗？",
              text: `可以对音乐按需进行基础剪辑，由于网站只是出售音乐的使用权，如果需要改编，请联系客服咨询。

              `
            },
          ],
       
        ],
        // 音乐人的
        [
          [
            {
              title: "音乐人注册流程",
              text: `
             常规注册后点击网站上方“音乐入驻”申请成为音乐人，申请时需上传一首原创音乐及有效身份证件照片。`,
            },
            {
              title: "音乐人审核标准",
              text: `1、上传音乐必须为原创（申请人身份为“歌手”的除外）<br><br>
                      2、混音及录音要达到一定标准（详见官网帮助栏“音乐审核标准”）<br><br>
                      3、歌曲完整，时长超过30s`,
            },
           
          ],
          [
            {
              title: "音乐人提现流程",
              text: ` 登陆【供应商管理系统】-【我的收益】-【提现】<br><br>
                      提现申请状态：申请中-待转帐-已到账。<br><br>
                      提现时间：每月30日以前（即当1日至29日）的提现会在对应结算日30日统一处理，结算日后的5个工作日内完成转账。<br><br>
                      海外转账：PAYPAL可直接转账，PAYONEER账户在提现申请审核通过之后会收到一封邮件，按照邮件内容操作转账。<br><br>
                      结算货币：人民币|美金`,
            },
            {
              title: "音乐人缴税规则",
              text: ` 音乐人在提现时需根据中国政府要求缴纳个人劳务所得税，计算方法如下：<br>
                      税前金额<=800：<br>
                      应纳税所得额=0；个人所得税=0;<br><br>
                      税前金额<=4000：<br>
                      应纳税所得额=税前金额-800；个人所得税=应纳税所得额*20%;<br><br>
                      税前金额>4000：<br>
                      应纳税所得额=税前金额-税前金额*20%;<br><br>
                      应纳税所得额<=2w：<br>
                      个人所得税=应纳税所得额*20%;<br><br>
                      应纳税所得额>2w & 应纳税所得额<=5w：<br>
                      个人所得税=应纳税所得额*30%-2000;<br><br>
                      应纳税所得额>5w：<br>
                      个人所得税=应纳税所得额*40%-7000;`,
            },
          ],
          [
            {
              title: "如何修改音乐信息",
              text: `更改音乐名称/封面/音频文件/风格：<br><br>
                    进入【供应商管理系统】-【我的作品】<br> 
                    选择想要修改的歌曲，点击【操作】中的【下架音乐】后，点击【编辑音乐】即可进行以上修改，修改完成后需重新提交审核才可上架。`,
            },
            {
              title: "音乐上传审核标准",
              text: `由于平台自身的商业性质，我们会对您上传的音乐作品进行审核，具体审核标准请见以下内容：<br><br>
                    一、您需拥有作品的完整权利<br> 
                    由于您上传的作品会因为被用于商业用途而产生盈利，因此您必须保证作品为独立原创或您拥有该音乐作品的完整权利。<br><br>
                    二、音乐作品需满足以下质量要求<br> 
                    1. 上传的格式要求为：16bit，44kHz，WAV文件，文件大小200M以内，时长尽量6分钟以内。<br> 
                    2. 满足作品风格的响度要求，绝对不能爆表。<br> 
                    3. 实录乐器保证足够高的信噪比，保证声音清晰度，避免使用较大的降噪处理。<br> 
                    4. 避免midi乐器的过度量化与力度的过度统一。<br> 
                    5. 除非作品需要，避免声场不统一；避免出现音量、声像、频率响应不平衡等问题。<br> 
                    6. 作品内容不得涉及反动、色情、暴力、粗口、毒品等。<br> 
                    7. 伴奏：需为消除人声伴奏，组合型歌曲伴奏可只保留男（or女声），上传者需拥有伴奏版权或使用权。`,
            },
           
          ],
          
          
        ],
      ],
      tabList: [
        [
          {
            id: 1,
            tab: "售前",
          },
          {
            id: 2,
            tab: "售后",
          },
         
        ],
        [
          {
            id: 1,
            tab: "入驻",
          },
          {
            id: 2,
            tab: "收益",
          },
          {
            id: 3,
            tab: "上传",
          },
          
          
        ],
      ],
    };
  },
  components: {
    ACollapse,
    ACollapsePanel,
    AIcon: Icon,
  },

  created() {
    this.activeTab = this.$route.query.tab === "kehu" ? 0 : 1;
    console.log("this.activeTab : ", this.activeTab);
  },
  watch:{
    $route(n,o){
      if(n.query.tab != o.query.tab){
         if(n.query.tab== "kehu"){
            this.activeTab = 0
         }else{
            this.activeTab = 1
        
         }
        this.activeKey = 1
        
      }
    }
  },
  methods: {
    handleHelpTab(e) {
      this.activeKey = 1
      this.activeTab = e;
      this.activeCollapse = [];
    },
    changeTab() {
    
      this.activeCollapse = [];
    },
    changeActivekey(e) {
      console.log("e: ", e);
    },
    handleClick(event) {
      // If you don't want click extra trigger collapse, you can prevent this:
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" >
$content-padding-top: 95px;
$side-wdith: 250px;
$side-margin-right: 80px;
$side-width-add: $side-wdith + $side-margin-right;
// mobile
@media screen and (max-width: $screen-width-md) {
.help-center-wrap {
   .help-center {
     
      .help-center-title {
         padding:0 1rem;
      .title {
        width: 100%;
        margin: 0 auto;
        height: 8.8rem;
        font-size: 3.2rem;
        background-color:  rgba(0, 0, 0, 0.02);
      }
    }
    .help-tab{
       padding:0 1rem;
    }
    .help-collapse-wrap {
       padding:0 1rem;
      width: 100%;
      margin: 0 auto;
      // padding-bottom: 1.2rem;
      .ant-collapse {
        background: #fff;
        border: none;
        .ant-collapse-header {
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          padding-left: 0;
          padding-right: 0;
          .ant-collapse-arrow {
            right: 0;
          }
        }
        .ant-collapse-content {
          border: none;
          .ant-collapse-content-box {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
   }
}

  }
.help-center-wrap {
  width: 100%;
 
  
  .help-center {
    .ant-collapse{
      // background: rgba(0, 0, 0, 0.02)!important;
      .ant-collapse-item{
        // background: rgba(0, 0, 0, 0.02)!important;
      }
      .ant-collapse-header{
        //  background: rgba(0, 0, 0, 0.02)!important;
      }
      }
    .help-center-title {
       height: 8.8rem;
  padding-bottom: 3.2rem;
  background: rgba(0, 0, 0, 0.02);
      .title {
        width: 900px;
        margin: 0 auto;
        height: 8.8rem;
        font-size: 3.2rem;
      }
    }
    .help-tab {
      margin-top: 3.2rem;
      .client,
      .musician {
        width: 31rem;
        height: 12rem;
        border: 1px solid #f0f0f0;
        font-size: 0.9rem;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.55);
      }
      .kehu-img,
      .musician-img {
        width: 8.4rem;
        height: 8.4rem;
      }

      .active-class {
        border: 1px solid rgba(0, 0, 0, 0.85);
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .small-tabs-wrap {
      margin-top: 2rem;
    }
    .help-collapse-wrap {
      width: 900px;
      margin: 0 auto;
      padding-bottom: 1.2rem;
      .ant-collapse {
        background: #fff;
        border: none;
        .ant-collapse-header {
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          padding-left: 0;
          padding-right: 0;
          .ant-collapse-arrow {
            right: 0;
          }
        }
        .ant-collapse-content {
          border: none;
          .ant-collapse-content-box {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

//   // pc
// @media screen and (min-width: $screen-width-md) {
//   .help-center-wrap {
//     width: 100%;
//     height: 88px;
//     background: rgba(0, 0, 0, 0.02);
//     .help-center {
//       .help-center-title {
//         .title {
//           height: 88px;
//           margin: 0;
//         }
//       }
//       .help-tab {
//         margin-top: 32px;
//         .client,
//         .musician {
//           width: 310px;
//           height: 120px;
//           border: 1px solid #f0f0f0;
//           font-size: 18px;
//           font-weight: bold;
//           color: rgba(0, 0, 0, 0.55);
//         }
//         .kehu-img,
//         .musician-img {
//           width: 84px;
//           height: 84px;
//         }

//         .active-class {
//           border: 1px solid rgba(0, 0, 0, 0.85);
//           color: rgba(0, 0, 0, 0.85);
//         }
//       }
//       .small-tabs-wrap {
//         margin-top: 20px;
//       }
//       .help-collapse-wrap {
//         padding-bottom: 12px;
//         .ant-collapse {
//           background: #fff;
//           border: none;
//           .ant-collapse-header {
//             font-weight: bold;
//             color: rgba(0, 0, 0, 0.85);
//             border-bottom: 1px solid rgba(0, 0, 0, 0.06);
//             padding-left: 0;
//             padding-right: 0;
//             .ant-collapse-arrow {
//               right: 0;
//             }
//           }
//           .ant-collapse-content {
//             border: none;
//             .ant-collapse-content-box {
//               padding-left: 0;
//               padding-right: 0;
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>
