<template>
  <div class="ng-header border-bottom-pos" :class="[isSearching ? 'search-open' : '']">
    <div class="ng-header-content container hidden-sm">
      <router-link class="svg-box w-100 h-100 logo" to="/musicFine">
        <img src="/images/web/logo.png" alt="logo" />
      </router-link>

      <div class="menu-list flex-1 pos-relative">
        <ul class="header-menu header-menu-left f-l">
          <template v-for="item in Menu">
            <li class="header-menu-item" :key="item.id">
              <router-link class="header-link" :to="item.to">{{item.name}}</router-link>
            </li>
          </template>
        </ul>

        <div class="f-r search-box">
          <a-input
            v-model="searchValue"
            class="search"
            size="large"
            :allowClear="false"
            placeholder="输入音乐名/风格/情绪"
            @keydown.enter="onSearch"
            @focus="onSearchFocus"
            @blur="onSearchBlur"
            ref="search"
          >
            <span class="font-heading-1" slot="prefix" @click="onSearch">
              <icon-font class="search-icon" type="iconsearch"></icon-font>
            </span>
            <icon-font
              v-if="searchValue"
              slot="suffix"
              @click="clearSearch"
              class="close-icon"
              type="iconclose"
            ></icon-font>
          </a-input>

          <div class="mask" @click="onSearchBlur"></div>
        </div>
      </div>

      <ul class="header-menu f-l">
        <template v-if="$store.state.login">
          <li class="header-menu-item">
            <a-badge :count="$store.state.goodNumOfCart" :overflow-count="99">
              <router-link class="header-icon" to="/cart">
                <icon-font type="iconcart-empty"></icon-font>
              </router-link>
            </a-badge>
          </li>

          <li class="header-menu-item">
            <a-dropdown placement="bottomRight">
              <router-link class="flex cen-center font-color-base" to="/user">
                <ng-image class="avarator" :src="userInfo.profile_photo_url"></ng-image>
                <span style="max-width:100px" class="text-ellipsis">{{ userInfo.name || "" }}</span>
              </router-link>

              <!-- 下拉菜单 -->
              <a-menu slot="overlay">
                <a-menu-item v-for="item in UserMenu" :key="item.id">
                  <router-link class="header-user-menu-item" :to="item.to">{{item.name}}</router-link>
                </a-menu-item>
                <a-menu-item>
                  <div class="header-user-menu-item" @click="goOutLogin">退出登录</div>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </li>
        </template>
        <!-- 未登录 -->
        <template v-else>
          <li class="header-menu-item" @click="login">登录/注册</li>
        </template>
      </ul>
    </div>

    <header-mobile class="hidden-md visible-sm"></header-mobile>
  </div>
</template>

<script>
  import {Badge, Dropdown, Menu} from "ant-design-vue";
  import NgImage from "~/components/NgImage.vue";
  import HeaderMobile from './HeaderMobile.vue'

  import HeaderMixins from './header_mixins'

  const MenuList = function () {
    return [
      {
        id: "2",
        name: this.$t("headerMenu.fine"),
        to: "/musicFine",
      },
      {
        id: "1",
        name: this.$t("headerMenu.allMusic"),
        to: "/allmusic",
      },
      {
        id: '4',
        name: '歌单',
        to: '/playlist',
      },
      {
        id: "3",
        name: this.$t("headerMenu.vipMeal"),
        to: "/package",
      },
      {
        id: "5",
        name: this.$t("headerMenu.supplier"),
        to: "/supplier",
      },
    ];
  };

  const UserMenu = function () {
    return [
      {
        id: '1',
        to: `/user?menukey=${this.$val.UserMenu.order}`,
        name: '我的订单',
      },
      {
        id: '2',
        to: `/user?menukey=${this.$val.UserMenu.collect}`,
        name: '我的收藏',
      },
      {
        id: '3',
        to: `/user?menukey=${this.$val.UserMenu.operation}`,
        name: '操作记录',
      },
      {
        id: '4',
        to: `/user/account?menukey=${this.$val.UserMenu.basic}`,
        name: '个人资料',
      },
    ]
  }

  export default {
    data() {
      return {
      };
    },

    computed: {
      Menu() {
        return MenuList.call(this);
      },

      UserMenu() {
        return UserMenu.call(this);
      },
    },

    created() {
    },

    mixins: [HeaderMixins],

    components: {
      NgImage,
      ABadge: Badge,
      ADropdown: Dropdown,
      AMenu: Menu,
      AMenuItem: Menu.Item,
      HeaderMobile
    },

    methods: {
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .search {
      .ant-input {
        height: $header-height;
        line-height: $header-height;
        padding-left: 36px;
        border: none;
        background-color: transparent !important;

        &:hover,
        &:focus {
          border-color: transparent;
          box-shadow: none;
        }
      }

      .ant-input-suffix {
        display: inline-block;
        height: 20px;
        width: 20px;
        line-height: 21px;
        text-align: center;
        color: #fff;
        background-color: $black-1;
        border-radius: 100%;
        font-size: 12px;

        .ant-input-search-icon {
          display: none;
        }
      }

      .search-box {
        position: absolute;
        left: 70%;
        right: 0;
        background: #fff;
        transition: all linear 0.2s;
        z-index: 10;

        .search {
          position: relative;
          height: $header-height;
          line-height: $header-height;
          border-bottom: 1px solid rgba(0, 0, 0, 0.55);
          z-index: 1000;
        }
      }
    }

    .ant-input-affix-wrapper .ant-input-prefix {
      left: 0 !important;
      cursor: pointer;
      z-index: 100;
    }
  }

  .ng-header {
    background-color: #fff;

    &.search-open {
      .search-box {
        // width: 95%;
        left: 2%;
      }

      .header-menu-left {
        opacity: 0;
      }

      .mask {
        position: absolute;
        top: -100000px;
        bottom: -100000px;
        left: -100000px;
        right: -100000px;
        z-index: 99;
      }
    }

    .ng-header-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .logo {
      width: 170px;
      height: 39px;

      > img {
        height: 100%;
      }
    }

    .header-link {
      padding: 0 20px;
      color: $font-base-color;

      &.router-link-active {
        color: #fff;
        background: rgba(0, 0, 0, 0.85);
      }
    }

    .avarator {
      flex: 0 0 32px;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 5px;
    }

    .search-box {
      position: absolute;
      left: 70%;
      right: 0;
      background: #fff;
      transition: all linear 0.2s;
      z-index: 10;

      .search {
        position: relative;
        height: $header-height;
        line-height: $header-height;
        border-bottom: 1px solid rgba(0, 0, 0, 0.55);
        z-index: 1000;
      }
    }

    .header-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      opacity: 1;
      transition: all linear 0.3s;

      .header-menu-item {
        display: flex;
        align-items: center;
        height: $header-height;
        line-height: $header-height;
        padding: 0 10px;
        font-size: 18px;
        cursor: pointer;
      }

      &.right {
        margin-right: -10px;
      }
    }

    .header-icon {
      font-size: 24px;
      color: $font-base-color;
    }
  }

  @media screen and (min-width: $screen-width-md) {
    .ng-header {
      .header-menu-left {
        .header-menu-item {
          padding: 0;
          font-size: 14px;
        }

        .header-link {
          padding: 0 10px;
        }
      }

      .search-box {
        left: 73%;
      }
    }
  }

  @media screen and (min-width: $screen-width-lg) {
    .ng-header {
      .header-menu-left {
        .header-link {
          padding: 0 20px;
        }

        .header-menu-item {
          font-size: 18px;
        }
      }
    }
  }
</style>
