<template>
  <div class="partner-music-list flex">
    <div class="music-item" v-for="(item, index) in data" :key="index">
      <router-link :to="`/supplierInfo/${item.user_id}`">
        <div class="img-box radius-6">
          <ng-image class="music-img" :src="item.profile_photo_url"></ng-image>

          <div class="mask hidden-sm">
            <span class="text-box">{{item.desc}}</span>
          </div>
        </div>

        <div class="music-info">
          <div class="music-type text-ellipsis ft14 font-bold">{{item.name}}</div>
          <!-- <div class="ft12 case-desc">
          {{item.desc}}
          </div>-->
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
  import {Tag} from "ant-design-vue";

  export default {
    props: {
      data: {
        type: Array,
      },
    },
    data() {
      return {};
    },
    components: {
      ATag: Tag,
    },

    created() {
    },

    methods: {
      callback(key) {
        console.log(key);
      },
    },
  };
</script>
<style lang="scss">
  // mobile
  @media screen and (max-width: $screen-width-md) {
    .partner-music-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .music-item {
        width: 14.4rem;
        // height: 310px;
        margin-right: 1rem;

        &:nth-child(2n) {
          margin-right: 0;
        }
        margin-bottom: 2.4rem;
        .music-img {
          width: 14.4rem;
          // height: 310px;
        }
        .music-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 1.2rem;
          .music-type {
            color: #000;
            margin-bottom: 0.4rem;
          }
          .case-desc {
            color: rgba(0, 0, 0, 0.55);
          }
        }
      }
    }
  }
  // pc
  @media screen and (min-width: $screen-width-md) {
    .partner-music-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .music-item {
        position: relative;
        width: 15.4%;
        // height: 310px;
        margin-right: 1.3%;

        &:nth-child(6n) {
          margin-right: 0;
        }
        padding-bottom: 8px;
        .music-img {
          width: 100%;
          max-width: 200px;
          // height: 310px;
        }
        .music-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 11px;
          .music-type {
            color: #000;
            margin-bottom: 4px;
          }
          .case-desc {
            color: rgba(0, 0, 0, 0.55);
          }
        }

        .img-box {
          position: relative;

          .mask {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.25);
            text-align: center;
            transition: all linear 0.2s;
            color: #fff;
            overflow: hidden;

            .text-box {
              position: absolute;
              left: 50%;
              top: 50%;
              line-height: 20px;
              height: 100px;
              transform: translate(-50%, -50%);
              width: 100%;
              padding: 0 10px;
              overflow: hidden;
            }
          }

          &:hover {
            .mask {
              opacity: 1;
            }
          }
        }
      }
    }
  }
</style>