<template>
  <div class="my-collect pos-relative">
    <a-radio-group class="tab-btns" v-model="curRadio" button-style="solid">
      <a-radio-button value="1">音乐收藏</a-radio-button>
      <a-radio-button value="2">歌单收藏</a-radio-button>
    </a-radio-group>

    <template v-if="curRadio === '1'">
      <music-table
        :request="$axios.Static_deedList"
        :params="musicTableParams"
        :reload="reloadTable"
        @success="onSuccessTable"
        :pagination="false"
      ></music-table>
    </template>
    <template v-else-if="curRadio === '2'">
      <!-- <collect-song-list :request="$axios.Static_deedList" :params="songListParams"></collect-song-list> -->
      <ng-load-page
        class="m-top-base song-list-box"
        keyId="playlist_id"
        :request="$axios.Static_deedList"
        :params="songListParams"
        :changeData="changeItem"
        :reload.sync="reloadSonglist"
      >
        <template v-slot:default="{data}">
          <a-row :gutter="20">
            <a-col
              v-for="item in data"
              class="m-bottom-md"
              :xs="12"
              :sm="12"
              :md="4"
              :key="item.playlist_id"
            >
              <song-list-item :item="item" isShowCollect @update="updateListItem"></song-list-item>
            </a-col>
          </a-row>
        </template>
      </ng-load-page>
    </template>
  </div>
</template>

<script>
  import MusicTable from '@/pages/components/music/MusicTable.vue'
  // import CollectSongList from './components/CollectSongList.vue'
  import NgLoadPage from '@/components/NgLoadPage.vue'
  import SongListItem from '@/pages/components/songList/SongListItem.vue'

  export default {
    data() {
      return {
        text: {},
        curRadio: '1',
        musicTableParams: {},
        songListParams: {},
        changeItem: [],

        reloadTable: false,
        reloadSonglist: false,
      }
    },
    components: {
      MusicTable,
      // CollectSongList,
      NgLoadPage,
      SongListItem
    },

    created() {
      const {music, songlist} = this.$val.ShareAndCollectType;
      const {collect} = this.$val.DeedType;

      this.musicTableParams = {obj_type: music, deed_type: collect};
      this.songListParams = {obj_type: songlist, deed_type: collect};
    },

    methods: {
      updateListItem() {
        this.reloadSonglist = true;
      },
      
      onSuccessTable() {
        this.reloadTable = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
