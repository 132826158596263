<template>
  <div class="song-list-pay">
    <div class="top-content flex flex-column cen-center">
      <div class="top-title m-bottom-md">
        <img src="@/assets/images/diamond.png" class="m-right-sm" alt />
      </div>
      <div class="m-bottom-xs">
        <span
          class="font-heading-1"
        >{{packageInfo.auth_scene | toAuthScene}}·{{packageInfo.auth_num}}首</span>
      </div>
      <div class="last-item">
        <span>福利</span>
        <span>可下载{{packageInfo.download_num}}首无水印音乐用于试听</span>
      </div>
    </div>

    <div class="container-sm container-custome">
      <page-title title="订单提交成功，请尽快付款" sub="请您在30分钟内完成支付，否则订单会被自动取消"></page-title>

      <a-divider class="m-empty m-bottom-lg"></a-divider>

      <div class="text-center m-bo">
        <b class="font-color-primary font-heading-2">¥{{packageInfo.total_amount | toPrice}}</b>
        <div class="m-bottom-sm">实付金额</div>
        <!-- <div class="font-color-sub m-bottom-sm">
            <span class="m-right-xs">套餐金额 {{orderInfo.price | toPrice}}</span>
            <span>优惠金额 {{orderInfo.price | toPrice}}</span>
        </div>-->
      </div>

      <pay-way v-if="!payWay" @change="onChangePayWay"></pay-way>
      <template v-else>
        <ali-pay
          v-if="payWay=== $val.PayType.zfb"
          :order_id="order_id"
          :order_type="$val.orderType.package"
          @success="onPaySuccess"
        ></ali-pay>
        <wechat-pay
          v-else-if="payWay=== $val.PayType.wx"
          :order_id="order_id"
          :order_type="$val.orderType.package"
          @success="onPaySuccess"
        ></wechat-pay>
        <com-to-com-pay
          v-if="payWay=== $val.PayType.gdg"
          :order_id="order_id"
          :order_type="$val.orderType.package"
          @success="onPaySuccess"
          style="margin-top: 42px;"
        ></com-to-com-pay>
      </template>

      <a-divider class="m-empty m-top-lg m-bottom-lg"></a-divider>

      <div class="pay-info">
        <a-descriptions title="套餐信息" bordered :column="1" class="border-none m-bottom-lg">
          <a-descriptions-item label="订单编号">{{packageInfo.order_no}}</a-descriptions-item>
          <a-descriptions-item label="套餐类型">{{packageInfo.goods_cate}}</a-descriptions-item>
          <a-descriptions-item label="使用渠道">
            <div>{{packageInfo.use_mode}}</div>
            <!-- <div class="font-color-sub">{{packageInfo.desc}}</div> -->
          </a-descriptions-item>
          <a-descriptions-item label="授权地域">{{packageInfo.auth_area | toArea}}</a-descriptions-item>
          <a-descriptions-item label="授权期限">
            <div>{{packageInfo.auth_time | toAuthPeriod}}</div>
            <!-- <div class="font-color-sub">随片永久授权期限指的是音乐作品应用于项目的投放期限为随片永久。授权期限从购买当日算起，同时会在授权书上显示</div> -->
          </a-descriptions-item>
          <a-descriptions-item label="有效期">
            <div>{{packageInfo.valid_time}}天</div>
            <!-- <div class="font-color-sub">套餐有效期是指此套餐内的音乐必须在一年内完成授权，过期作废</div> -->
          </a-descriptions-item>
          <a-descriptions-item label="授权次数">{{packageInfo.auth_num}}次授权</a-descriptions-item>
        </a-descriptions>

        <a-descriptions title="常见QA" bordered :column="1" class="border-none q-and-a">
          <a-descriptions-item label="Q：">如何申请发票？</a-descriptions-item>
          <a-descriptions-item label="A：" class="m-bottom-md">
            付款成功后可以
            <span class="font-color-primary">联系客服</span>申请。
          </a-descriptions-item>

          <a-descriptions-item label="Q：">如何申请合同？</a-descriptions-item>
          <a-descriptions-item label="A：" class="m-bottom-md">
            在付款后，
            <span class="font-color-primary">联系客服</span>申请即可。
          </a-descriptions-item>

          <a-descriptions-item label="Q：">如何申请授权书？</a-descriptions-item>
          <a-descriptions-item label="A：">Spark提供电子版授权书，可作为正确购买了音乐授权的证明材料，付款成功后在“已购买音乐”中即可下载。</a-descriptions-item>
        </a-descriptions>
      </div>
    </div>

    <!-- <dialog-pay-success :visible.sync="showPaySuccessDialog"></dialog-pay-success> -->
  </div>
</template>

<script>
  import {Descriptions} from 'ant-design-vue';
  import PageTitle from '@/pages/components/common/PageTitle.vue'
  import AliPay from './components/AliPay.vue';
  import PayWay from './components/PayWay.vue'
  import WechatPay from './components/WechatPay.vue'
  import ComToComPay from './components/ComToComPay.vue'

  // import DialogPaySuccess from './components/DialogPaySuccess.vue'

  export default {
    data() {
      return {
        showPaySuccessDialog: true,
        packageInfo: {},
        payWay: '',
        order_id: ''
      }
    },

    created() {
      let order_id = this.$route.params.order_id || '';

      this.order_id = order_id;

      this.payWay = this.$route.query.payway || '';

      this.getPackageDetail({order_id})
    },

    components: {
      PageTitle,
      ADescriptions: Descriptions,
      ADescriptionsItem: Descriptions.Item,

      AliPay,
      PayWay,
      WechatPay,
      ComToComPay
    },
    methods: {
      getPackageDetail(params) {
        this.$store.commit('changeContentLoading', true);

        this.$axios.Package_orderInfo(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.packageInfo = data.data || {};
          }

          this.$store.commit('changeContentLoading', false);
        }).catch(() => {
          this.$store.commit('changeContentLoading', false);
        })
      },

      onPaySuccess(type) {
        let isPaying = type === this.$val.OrderStatus.doingPay;

        let url = isPaying
          ? `/payresult/paying/${this.order_id}?order_type=${this.$val.orderType.package}`
          : `/payresult/success/${this.order_id}?order_type=${this.$val.orderType.package}`

        this.$router.replace(url);
      },

      onChangePayWay(value) {
        this.payWay = value;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .top-content {
    height: 246px;
    color: #fff;
    background: #2e3557;

    .top-title {
      > img {
        width: 72px;
        height: 72px;
      }
    }

    .last-item {
      > span {
        padding: 4px 8px;
        border: 1px solid rgba($color: #fff, $alpha: 0.12);

        &:last-child {
          border-left: none;
        }
      }
    }
  }

  .q-and-a {
    /deep/ {
      .ant-descriptions-view {
        .ant-descriptions-row:nth-child(2n) {
          td,
          th {
            padding-bottom: 15px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .bottom-content {
      padding: 0 1rem;
    }
  }
</style>
