<template>
  <div class="all-music m-spill container">
    <!--<h1 class="title border-none flex cen-start container">全部音乐</h1>-->

    <filter-music
      :defaultValue="searchDefaultValue"
      :musicNumParams="musicNumParams"
      @change="onChangeParams"
      @labelsChange="onChangeLabels"
    ></filter-music>

    <music-table :request="resultApi" :params="musicParams"  :is-more="false" :pagination="pagination"></music-table>
  </div>
</template>

<script>
  import MusicTable from '@/pages/components/music/MusicTable.vue'
  import FilterMusic from '@/pages/components/music/filter/FilterMusic.vue'

  import {dialogMixins} from '@/mixins/index';
  import {InitDefaultValue} from '@/pages/components/music/filter/filterMusicMixins'


  export default {
    data() {
      return {
        musicParams: {},
        musicNumParams: {},
        isMore:false,
        display: '',
        pagination: {
          "show_pagin" : true,
          "show-quick-jumper": true,
          "default-current":1,
          total:0,
          pageSize: 20,
          current: 0,
          onChange: this.onChangePage,
          hideOnSinglePage: true,
          style:{
            "float": "none",
            "text-align": "center",
          },
        }
      };
    },
    components: {
      MusicTable,
      FilterMusic,
    },

    mixins: [dialogMixins, InitDefaultValue],

    computed: {
      resultApi() {
        return this.isPlaylist ? this.$axios.Play_Music_List : this.$axios.Music_List
      },
    },

    watch: {
      $route() {
        this.setDefaultLabels();
        this.initSearchLabelParams();
      }
    },

    methods: {
      onChangeParams(params) {
        this.musicParams = Object.assign({}, this.musicParams, params);
        // this.musicNumParams = Object.assign({}, this.musicNumParams, this.musicParams);
      },

      onChangeLabels(params) {
        this.musicNumParams = Object.assign({}, this.musicNumParams, params);
      },

      onChangePage(params) {
        this.pagination.current = params;
        this.pagination = Object.assign({}, this.pagination);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .all-music {
    .title {
      height: 88px;
      line-height: 88px;
      border-bottom: 1px solid $border-color;
    } 
  }
</style>
