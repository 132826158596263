import {
    Axios
  } from '@/utils/from-common';
  
  // 热门
  export const Music_Fine_Hot = (param) => Axios('POST', '/web/page/index', param);
  // 风格
  export const Music_Fine_Style = (param) => Axios('POST', '/web/page/labelStyle', param);
  // 情绪
  export const Music_Fine_Mood = (param) => Axios('POST', '/web/page/labelMood', param);


  // 音乐人信息
  export const Supplier_page = (param) => Axios('POST', '/web/page/supplier', param);
  
    