<template>
  <div class="vip-info-item top-bottom h-100 w-100">
    <div>
      <div class="detail-top flex cen-space">
        <b class="font-heading-2 title">{{data.goods_name}}</b>

        <router-link class="link-grey" to="/package">
          <span class="m-right-xs">查看更多套餐</span>
          <icon-font type="iconangle-right"></icon-font>
        </router-link>
      </div>
      <div class="number-detail">
        <span class="font-color-white-sub">授权次数</span>&nbsp;
        <span class="m-right-base">{{data.authed_num}}/{{data.auth_num}}</span>
        <span class="font-color-white-sub">下载次数</span>&nbsp;
        <span>{{data.downloaded_num}}/{{data.download_num}}</span>
      </div>
    </div>
    <div class="vip-use font-sm-fix">
      <div class="use-item">
        <span class="font-color-white-sub">授权用途</span>&nbsp;
        <span>{{data.use_mode}}</span>
      </div>
      <div class="use-item">
        <span class="font-color-white-sub">授权地域</span>&nbsp;
        <span>{{data.auth_area | toArea}}</span>
      </div>
      <div class="use-item">
        <span class="font-color-white-sub">授权期限</span>&nbsp;
        <span>{{data.auth_time | toAuthPeriod}}</span>
      </div>
      <div class="use-item">
        <span class="font-color-white-sub">到期时间</span>&nbsp;
        <span>{{data.expire_date}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import {AuthAreaOfMusicOptions, BusinessSceneOptions, AuthTimeOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  export default {
    data() {
      return {

      }
    },

    props: {
      data: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOfMusicOptions, value);
      },

      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      },

      toAuthPeriod(value) {
        return toOptionName(AuthTimeOptions, value);
      }
    },

    components: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .vip-info-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .vip-use {
      .use-item {
        display: inline-block;
        margin-right: 16px;
      }
    }

    .detail-top {
      margin-top: -2px;
      margin-bottom: 4px;
    }
  }
</style>
