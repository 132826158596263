<template>
  <ng-dialog :visible.sync="show" mHeight="auto">
    <template v-slot:title>
      <h1 class="ant-drawer-title">{{`修改${typeText}`}}</h1>
      <span class="font-base lh-1">
        <span>{{`已绑定${typeText}`}}：</span>
        <span>{{number}}</span>
      </span>
    </template>

    <div class="progress-wrapper">
      <template v-for="(item, index) in progressList">
        <div
          :class="['progress-item flex cen-center m-right', proStep == item.key ? 'active' : '']"
          :key="index"
        >
          <b class="font-heading-0 m-right-sm">{{item.step}}</b>
          <span>{{item.content}}</span>
        </div>

        <div v-if="index < progressList.length - 1" class="step-icon" :key="`icon_${index}`">
          <icon-font type="iconplay-fff"></icon-font>
        </div>
      </template>
    </div>

    <first-step
      v-if="proStep == $var.Common.first"
      :type="type"
      :codeType="codeType"
      ref="firstStep"
    ></first-step>
    <second-step
      v-else
      :type="type"
      :handle="this.$var.Handle.edit"
      :codeType="codeType"
      ref="secondStep"
    ></second-step>

    <template v-slot:footer>
      <a-button
        type="primary"
        class="w-100"
        size="large"
        @click="handleOk"
        :loading="confirmLoading"
      >{{btnText}}</a-button>
    </template>
  </ng-dialog>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import NgDialog from '@/components/NgDialog.vue'
  import FirstStep from './FirstStep.vue'
  import SecondStep from './SecondStep.vue'

  const Progress = (type, order) => {
    let text = type === 'email' ? '邮箱' : '手机';

    return [
      {
        step: '01',
        content: `验证旧${text}`,
        key: order.first
      },
      {
        step: '02',
        content: `绑定新${text}`,
        key: order.second
      }
    ]
  }

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        proStep: this.$var.Common.first, // '1' 第一步  '2' 第二步
      }
    },

    props: {
      // phone email
      type: {
        type: String,
        default: 'phone'
      },

      number: String,
      data: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    computed: {
      codeType() {
        return this.type === 'email' ? '2' : '1';
      },

      typeText() {
        return this.type === 'email' ? '邮箱' : '手机号';
      },

      btnText() {
        return this.proStep == this.$var.Common.first ? '下一步' : '确认'
      },

      progressList() {
        return Progress(this.type, this.$var.Common)
      },

      // 绑定手机/邮箱
      bindCodeFunc() {
        return this.type === 'email' ? this.$axios.Account_editEmail : this.$axios.Account_editPhone;
      }
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.proStep = this.$var.Common.first;
        }
      }
    },

    mixins: [dialogMixins],

    components: {
      FirstStep,
      NgDialog,
      SecondStep
    },

    methods: {

      handleOk() {
        if (this.proStep == this.$var.Common.first) {
          this.submitFirstStep();
          // this.proStep = this.$var.Common.second;
        }
        else if (this.proStep == this.$var.Common.second) {
          this.submitSecondStep();
        }
      },

      submitFirstStep() {
        this.$refs.firstStep.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            let param = Object.assign(values, {type: this.codeType})

            this.confirmLoading = true;

            this.$axios.VerifySelfCode(param).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.proStep = this.$var.Common.second;
              }

              this.confirmLoading = false;
            })
          }
        })
      },

      submitSecondStep() {
        let secondStep = this.$refs.secondStep;

        secondStep && secondStep.handleOk && secondStep.handleOk().then(values => {
          this.confirmLoading = true;

          this.bindCodeFunc(values).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.$message.success(this.handle === this.$var.Handle.add ? this.$t('userSet.ac_tipBindSuccess') : this.$t('userSet.ac_tipChangeSuccess'));
              this.$emit('update:visible', false);
              this.$emit('success');
            }

            this.confirmLoading = false;
          }).catch(() => {
            this.confirmLoading = false;
          })
        });
      },

      handleCancel() {

      },
    }
  }
</script>

<style lang="scss" scoped>
  .progress-wrapper {
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 20px;
    color: rgba($color: #000000, $alpha: 0.25);

    .progress-item {
      padding: 10px 0;

      &.active {
        color: $primary-color;
      }
    }

    .step-icon {
      width: 16px;
      height: 16px;
    }
  }

  @media screen and(min-width: $screen-width-md) {
    .progress-wrapper {
      justify-content: space-around;
    }
  }

  @media screen and(max-width: $screen-width-md) {
    .progress-wrapper {
      justify-content: space-between;
    }
  }
</style>
