<template>
  <div class="activity-wrap">
    <div class="con_header">
      <img class="bg" src="@/assets/images/active-bg.png" />
      <img class="shengdian" src="@/assets/images/618.png" alt="" />
    </div>
    <div class="con_use">
      <div class="btn_zhiyin">
        <img src="@/assets/images/active-zhiyin.png" alt="" />
      </div>
      <div class="use_top">
        <img src="@/assets/images/active-first.png" alt="" />
        <img src="@/assets/images/active-second.png" alt="" />
      </div>
      <div class="use_bottom">
        <img src="@/assets/images/active-third.png" alt="" />
        <img src="@/assets/images/active-fourth.png" alt="" />
      </div>
    </div>
    <div class="con_rules">
      <div class="btn_rules">
        <img src="@/assets/images/active-guize.png" alt="" />
      </div>
      <div class="text_rules">
       <ul>
          <li>优惠券只能在订单结算时抵扣金额，每个订单最多使用一张优惠券；</li>
          <li>优惠券不能与其他优惠共享，订单中包含特价音乐时，特价音乐不参与优惠；</li>
          <li>订单取消优惠券自动退回账户，所退优惠券的有效起止时间不变；</li>
          <li>优惠券抵扣的金额不能开具发票。</li>
        </ul>
      </div>
    </div>
    <div class="con_footer" v-if="!userInfo">
      <a @click="login">
        <img src="@/assets/images/active-registered.png" alt="" />
      </a>
    </div>
  </div>
</template>

<script>

import {
  mapMutations
} from "vuex";

export default {
  data() {
    return {
      userInfo: false,
    };
  },
  created(){
    var users = this.$store.state.userInfo;
    if(users.user_id) {
      this.userInfo = true;
    }
  },
  components: {
  },
  computed: {
  },
  mounted() {},

  methods: {
    ...mapMutations(["changeLoginAndRegisterDialog"]),
    login() {
      this.changeLoginAndRegisterDialog(true);
    },
  },
  
};
</script>

<style lang="scss" scope>
.activity-wrap {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background: #FE7B03;
}
.con_header{
  width: 100%;
  height: 10%;
  .bg{
    width:100%;
  }
  .shengdian{
    width: 50%;
    position:absolute;
    top:8%;
    left: 30%;
    width: 40%;
  }
}
.con_use{
  width: 80%;
  height: 10%;
  margin: 0 auto;
  margin-top:5%;
  .btn_zhiyin{
    width: 50%;
    margin: 0 auto;
    text-align:center;
    img{
      width:100%;
    }
  }
  .use_top{
    width:100%;
    margin: 0 auto;
    display:flex;
    margin-top:5%;
    margin-bottom:5%;
    img{
      width: 48%;
      margin-right:4%;
    }
  }
  .use_bottom{
    width:100%;
    margin: 0 auto;
    display:flex;
    img{
      width: 48%;
      margin-right:4%;
    }
  }
}

.con_rules{
  width: 80%;
  height: 10%;
  margin: 0 auto;
  margin-top: 5%;
  padding-bottom: 5%;
  .btn_rules{
    width: 50%;
    margin: 0 auto;
    text-align:center;
    img{
      width:100%;
    }
  }
  .text_rules{
    width: 100%;
    margin: 0 auto;
    margin-top:5%;
    background:#FFFFFF;
    border-radius: 20px;
    padding: 1%;
    font-weight: bold;
  }
  ul{
    margin-left:5%;
    width: 90%;
    margin-top:4%;
    color: rgba(28, 27, 26, 0.8);
    letter-spacing: 1px;
  }
  li::before{
      content: "• "; 
      color: #FF803A;
  }
  li{
    margin-bottom: 4%;
  }
}
.con_footer{
  width: 80%;
  margin: 0 auto;
  img{
    width: 100%;
  }
}

.hidden{
  display: none;
}

@media screen and (max-width: 720px){
  .activity-wrap{
    margin-bottom: -19%;
  }
  .con_header{
    .shengdian{
      top:5%;
    }
  }
  .con_use{
    width: 90%;
    .use_top{
      display:block;
      margin-bottom: 0;
      img{
        width: 100%;
        margin-bottom: 5%;
      }
    }
    .use_bottom{
      display:block;
      margin-top: 0;
      img{
        width: 100%;
        margin-bottom: 5%;
      }
    }
  }

  .con_rules{
    width: 90%;
    margin-top: 0;
    .text_rules{
      font-size: 12px;
      line-height: 18px;
    }
  }

  .con_footer{
    width: 90%;
  }

}

</style>