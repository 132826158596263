import {
  Axios
} from '@/utils/from-common';
import * as $config from '~/config/config.c';

let baseURL = $config.ServeBaseHost;

// 授权列表
export const AuthList = (param) => Axios('POST', '/web/license/list', param)

// 授权验证
export const Auth_verify = (param) => Axios('POST', '/web/license/preadd', param)

// 修改授权
export const AuthEdit = (param) => Axios('POST', '/web/license/save', param)

// 修改授权
export const AuthAdd = (param) => Axios('POST', '/web/license/add', param)

// 获取授权书
export const Auth_SearchAuth = `${baseURL}/web/license/query`;

// 授权查询
export const License_Query= (param) => Axios('GET', '/web/license/query', param);

// 查看授权信息 是否可以下载 授权
export const Auth_lookInfo = (param) => Axios('POST', '/web/usergoods/access', param)

// export const Auth_SearchAuth = (param) => Axios('GET', '/web/license/query', param, {
//   responseType: 'blob',
//   timeout: DownloadTimeout,
// })

// 激活订单
// export const ActiviateOrder = (param) => Axios('POST', '/web/license/add', param);