<template>
  <div class="pos-relative">
    <a-button
      class="add-btn"
      size="large"
      type="primary"
      @click="onHandle('add')"
    >{{$t('common.add')}}</a-button>

    <a-spin :spinning="spinning" class="w-100">
      <Detail :list="list" @onEdit="onEditHandle" @success="onSuccess"></Detail>
    </a-spin>

    <dialog-add
      :default-value="dialogFormDefault"
      :visible.sync="showHandleDialog"
      :handle="handleType"
      @success="onSuccess"
    ></dialog-add>
  </div>
</template>

<script>
  import {Spin} from 'ant-design-vue';
  import Detail from "./components/Detail.vue";
  import DialogAdd from './components/DialogAdd.vue'

  export default {
    data() {
      return {

        showHandleDialog: false,
        dialogFormDefault: {},
        handleType: this.$var.Handle.add,

        list: [],
        spinning: false,
      };
    },

    created() {
      this.getData();
    },

    components: {
      Detail,
      DialogAdd,
      ASpin: Spin
    },

    methods: {
      onSuccess() {
        this.getData();
      },

      onEditHandle(item) {
        item = Object.assign({}, item, {
          is_default: !!+item.is_default
        })

        this.dialogFormDefault = item;

        this.onHandle(this.$var.Handle.edit);
      },

      getData() {
        this.spinning = true;

        this.$axios.Address_list().then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            // something
            this.list = data.data.list.map(item => Object.assign(item, {
              phone_code: item.phone_code.id,
              phone_code_item: item.phone_code,
            }));
          }

          this.spinning = false;
        })
      },

      onHandle(type) {
        this.handleType = type;
        this.showHandleDialog = true;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .add-btn {
    cursor: pointer;

    .iconfont {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
  }
</style>
