<template>
  <div :class="['vip-info top-bottom', isVip ? 'vip-active' : '']">
    <div v-show="!isVip" class="top-content flex h-100">
      <div class="icon-box m-right-xs">
        <ng-image :src="require('@/assets/images/diamond-grey.png')"></ng-image>
      </div>

      <div class="vip-detail flex-1-steady">
        <div class="detail-top">
          <b class="font-heading-2 title">未开通套餐</b>
        </div>

        <div class="bottom">
          <a-button class="w-100" type="sub" @click="$router.push('/package')">查看套餐</a-button>
        </div>
      </div>
    </div>

    <div class="top-content flex h-100">
      <div class="swiper-container w-100">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in list"
            class="swiper-slide swiper-slide-self flex w-100"
            :key="index"
          >
            <div class="icon-box m-right-xs">
              <ng-image :src="require('@/assets/images/diamond.png')"></ng-image>
            </div>

            <div class="flex-1-steady content">
              <vip-info-item :data="item"></vip-info-item>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import VipInfoItem from './VipInfoItem.vue'
  import Swiper from 'swiper';
  import 'swiper/swiper.scss'

  export default {
    data() {
      return {
        isVip: false,
        list: [],
      }
    },

    created() {
      this.getData();
    },

    mounted() {
    },

    watch: {
      list(newVal) {
        if (newVal && newVal.length > 0) {
          this.$nextTick(() => {
            this.swiper && this.swiper.destroy && this.swiper.destroy(false);

            this.swiper = new Swiper('.swiper-container', {
              allowTouchMove: false,
              pagination: {
                el: '.swiper-pagination',
                clickable: true
              },
            })
          })
        }
      }
    },

    components: {
      VipInfoItem
    },
    methods: {
      getData() {
        let params = {};

        this.$axios.Package_listInUser(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let list = data.data ? data.data.list : [];

            this.list = list;

            this.isVip = list && list.length > 0;
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  // .top-bottom {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  // }

  ::v-deep {
    .swiper-pagination-bullet {
      display: inline-block;
      width: 24px;
      height: 4px;
      background: rgba(255, 255, 255, 0.25);
      margin: 0 4px;
      cursor: pointer;

      &.swiper-pagination-bullet-active {
        background: rgba(255, 255, 255, 0.95);
      }
    }
  }

  .swiper-container {
    .content {
      padding-bottom: 24px;
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    width: 100%;
    text-align: center;
  }

  .vip-info {
    height: 200px;
    padding: 24px 20px;
    background-color: $back-color;
    overflow: hidden;

    &.vip-active {
      &,
      & h2 {
        color: #fff;
      }

      background-color: #2e3557;
    }
  }

  .top-content {
    .icon-box {
      width: 32px;
      height: 32px;

      > img {
        width: 100%;
      }
    }
  }

  .detail-top {
    margin-top: -2px;
    margin-bottom: 4px;
  }

  .vip-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
