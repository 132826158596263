<template>
  <a-form :form="form" layout="horizontal" class="ng-form">
    <a-form-item style="display: none;">
      <form-phone-area
        show-type="both"
        v-decorator="['phone_code', { rules: [$validate.required], initialValue: $config.PhoneCodeDefault}]"
      ></form-phone-area>
    </a-form-item>

    <!-- 电话 -->
    <a-form-item
      v-if="type === 'phone'"
      :required="false"
      :label="handle === $var.Handle.add ? $t('form.phone') : $t('form.newPhone')"
    >
      <a-input
        size="large"
        type="number"
        v-decorator="['phone', { rules: [$validate.required, $validate.phone], initialValue: '', trigger: 'blur' }]"
        :placeholder="handle === $var.Handle.add ?
              $t('template.typeSth', {name: $t('form.phone')}) :
              $t('template.typeSth', {name: $t('form.newPhone')})"
      />
    </a-form-item>

    <!-- 邮箱 -->
    <a-form-item
      v-else
      :required="false"
      :label="handle === $var.Handle.add ? $t('form.email') : $t('form.newEmail')"
    >
      <a-input
        size="large"
        v-decorator="['email', { rules: [$validate.required, $validate.email], initialValue: '', trigger: 'blur' }]"
        :placeholder="handle === $var.Handle.add ?
            $t('template.typeSth', {name: $t('form.email')}) :
            $t('template.typeSth', {name: $t('form.newEmail')})"
      />
    </a-form-item>

    <a-form-item :required="false" label="验证码" class="m-bottom-0">
      <a-form-item style="display: inline-block; width: calc(100% - 130px); margin-right: 10px">
        <a-input
          size="large"
          v-decorator="['vfcode', { rules: [$validate.required], initialValue: '', trigger: 'blur' }]"
          :placeholder="$t('template.typeSth', {name: $t('form.code')})"
        />
      </a-form-item>

      <a-form-item :style="{display: 'inline-block', width: '120px'}">
        <form-send-code-button
          type="sub"
          size="large"
          :url-func="codeRequestFunc"
          :param="identifyCodeParam"
          @click="onClickSend"
          @sendSuccess="onSendSuccess"
        ></form-send-code-button>
      </a-form-item>
    </a-form-item>
  </a-form>
</template>

<script>
  import {FormSendCodeButton, FormPhoneArea} from '@/components/index';

  const phoneKey = 'phone';
  const emailKey = 'email';

  export default {
    data() {
      return {
        identifyCodeParam: {},
      }
    },
    props: {
      // phone email
      type: String,
      handle: String,
      codeType: String,
    },

    computed: {
      // 验证码发送方法
      codeRequestFunc() {
        return this.type === phoneKey ? this.$axios.SendCommonPhoneCode : this.$axios.SendCommonEmailCode;
      },
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'linkman'
      })
    },

    components: {
      FormSendCodeButton, FormPhoneArea
    },
    methods: {

      onClickSend(next) {
        this.form.validateFields(['phone', 'phone_code', 'email'], (errors, values) => {

          if (this.type === phoneKey) {
            values.sms_tpl = this.$val.SendCodeDefaultValue.phone;
          } else if (this.type === emailKey) {
            values.email_tpl = this.$val.SendCodeDefaultValue.email;
          }

          if (this.type === phoneKey && this.$root.$PhoneCodeArea && this.$root.$PhoneCodeArea.length > 0) {
            this.$root.$PhoneCodeArea.forEach(item => {
              if (item.id == values.phone_code) {
                values.phone_area = item.prefix;
              }
            })
          }

          if (!errors) {
            next(values);
          }
        })
      },

      onSendSuccess(data) {
        if (data && data.code == 0) {
          this.$message.success('发送成功');
          this.transaction_id = data.data.transaction_id;
        }
      },

      handleOk() {
        return new Promise((resolve, reject) => {
          this.form.validateFieldsAndScroll((errors, values) => {

            if (!errors) {
              values.transaction_id = this.transaction_id;

              resolve(values);
            } else {
              reject(errors);
            }
          })
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
