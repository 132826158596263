<template>
  <a-spin class="w-100 secure-page" :spinning="spinning">
    <user-phone :data="info" @success="onSuccess"></user-phone>
    <user-email :data="info" @success="onSuccess"></user-email>
  </a-spin>
</template>

<script>
  import {Spin} from 'ant-design-vue';
  import UserPhone from './components/UserPhone.vue';
  import UserEmail from './components/UserEmail.vue';

  export default {
    data() {
      return {
        title: "账户安全",

        showHandleDialog: false,
        dialogFormDefault: {},
        handleType: 'add',

        info: {},

        spinning: false,
      };
    },
    components: {
      UserPhone, UserEmail,
      ASpin: Spin
    },

    created() {
      this.getData();
    },

    methods: {
      onSuccess() {
        this.getData();
      },

      getData() {
        this.spinning = true;

        this.$axios.Account_info().then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.info = data.data;
          }

          this.spinning = false;
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: $screen-width-md) {
    /deep/ {
      .secure-item {
        padding: 10px 0;
        border-bottom: 1px solid $border-color;
      }

      .detail-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  @media screen and (max-width: $screen-width-md) {
    /deep/ {
      .secure-item {
        .text,
        .btn-outer {
          display: block;
        }

        .text {
          padding: 10px 0 12px;
          border-bottom: 1px solid $border-color;
        }

        .btn-outer {
          padding: 10px 0;
        }

        margin-bottom: 40px;
      }
    }
  }
</style>
