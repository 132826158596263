/**
 * 全局业务方法
 */




/**
 * 添加搜索参数
 */
export const $goSearch = function (params) {
  let query = this.$route.query;

  params = Object.assign({}, query, params);

  this.$router.push({
    query: params
  });
}

/**
 * 检查权限
 * 如果此权限存在那么返回true
 * 
 * @param {*} authname 
 */
export const $checkAuth = function (authname) {
  let authList = this.$store.state.authList;

  return authList && authList.length > 0 && authname ? authList.includes(authname) : true;
}

/**
 * 获取数组对象某个属性值等于给定值的相应对象值
 * 
 * arr = [{name: 1}, {name: 2}]
 * key = name
 * value = 2 
 * @return {name: 2}
 */
export const $getItemWithKey = function (arr, key, value) {
  let final = {};

  arr.some(item => {
    if (item[key] === value) {
      final = item;
      return true;
    }
  })

  return final;
}

export const $downloadFile = function (url, params) {
  return new Promise( (resolve, reject) =>{
    let iframe = document.createElement('iframe')
    let str =  params ? buildQuery(params) : ''
    iframe.src = url + str
    iframe.style.display = 'none'
    document.body.appendChild(iframe)
    resolve()
    setTimeout(() =>{
      document.body.removeChild(iframe)
    },3000)
  })
 
}

export const buildQuery =function (queryObj) {
  let queryList = []
  for(let key in queryObj) {
    let value =queryObj[key]
    queryList.push(`${key}=${value}`)
      queryList.length
    return `?${queryList.join('&')}`
  }
  
}
  
 
 