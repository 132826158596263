<template>
  <div class="auth-center">
    <div class="auth-center-top m-spill">
      <div class="auth-center-top-inner">
        <div class="auth-center-title">
          <h1 class="title flex cen-center font-heading-0">{{ "使用授权查询中心" }}</h1>
        </div>
        <div class="auth-form flex cen-center m-bottom-sm">
          <a-input-search
            v-model="license_id"
            class="code-input"
            size="large"
            placeholder="授权码"
            @search="onSearch"
          />
        </div>
        <div class="code-case">
          <div class="default-code-box">
            <span>授权码示例：</span>
            <span class="cursor-pointer" @click="onClickCode(defaultCode)">{{defaultCode}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="auth-result-wrap">
      <div class="result-content" id="iframeBox">
        <iframe class="iframe" :src="fileUrl" frameborder="0" @load="onLoadIframe"></iframe>
        <!-- <embed
          class="iframe"
          :src="fileUrl"
          frameborder="0"
          @load="onLoadIframe"
          @progress="onProgress"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
  let is_view = '1'; // 1 预览  2 下载

  export default {
    data() {
      return {
        license_id: "",
        // license_idInput: '',
        defaultCode: this.$config.DefaultAuthCode,
        vHtml: '',
        fileUrl: ''
      };
    },

    created() {
      let license_id = this.$route.query.license_id;
      let defaultCode = this.defaultCode;

      if (license_id === 'default') {
        this.license_id = defaultCode;
      } else if (license_id) {
        this.license_id = license_id;
      }

      this.goSearch(this.license_id);
    },

    mounted() {
      // this.createElement();
    },

    methods: {
      onSearch(value) {
        console.log(value);
        this.goSearch(value)
      },

      goSearch(value) {
        this.fileUrl = `${this.$axios.Auth_SearchAuth}?code=${value}&is_view=${is_view}`
      },

      onClickCode(str) {
        this.license_id = str;
        this.goSearch(this.license_id);
      },

      onLoadIframe(event) {
        console.log(event);
      },
      
      onProgress(event) {
        console.log(event);
      }

      // createElement() {
      //   let box = document.getElementById('iframeBox');
      //   let iframe = document.createElement('iframe');

      //   iframe.className = 'iframe';

      //   this.iframe = iframe;

      //   box.appendChild(iframe);

      //   iframe.onload = this.onLoadIframe();
      // },

      // changeUrl() {
      //   this.iframe.src = this.fileUrl;
      // },
    },
  };
</script>

<style lang="scss" scoped>
  .auth-result-wrap {
    width: 100%;
    overflow: auto;

    .result-content {
      width: 800px;
      height: 1200px;
      margin: 30px auto;

      .iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .auth-center-top {
    background-color: $back-color;
    padding: 40px 0;

    .auth-center-title {
      margin-bottom: 53px;
    }

    .auth-center-top-inner {
      width: 430px;
      margin: 0 auto;
    }
  }

  .auth-form {
    border-bottom: 1px solid $black-2;

    /deep/ {
      .ant-input {
        height: 60px;
        padding-top: 20px;
        padding-bottom: 20px;
        border: none;
        background-color: transparent !important;

        &:focus,
        &:hover {
          border: none;
          box-shadow: none;
        }
      }
    }
  }

  @media screen and(max-width: $screen-width-md) {
    .auth-center-top {
      .auth-center-top-inner {
        width: 95%;
        margin: 0 3%;
      }
    }

    .default-code-box {
      > span {
        display: block;
        margin-bottom: 8px;
      }
    }
  }
</style>
