<template>
  <ng-dialog
    class="dialog-shop-cart"
    :visible.sync="show"
    :maskClosable="false"
    :bodyStyle="bodyStyle"
    @close="onClose"
  >
    <template v-slot:title>
      <div class="header flex">
        <span class="play-icon m-right-xs music-play-icon-box">
          <music-play-icon :scope="data"></music-play-icon>
        </span>

        <div>
          <b>{{data.name}}</b>
          <div class="font-base">{{data.suplr_name}}</div>
        </div>
      </div>
    </template>

    <cart-detail
      :data="data"
      :show="show"
      ref="cartDeail"
      @changeFinished="onChangeFinished"
      @changePrice="onChangePrice"
      @changeDiscount="onChangeDiscount"
    ></cart-detail>

    <template slot="footer">
      <div class="flex cen-space w-100">
        <div class="f-1">
          <template v-if="allFieldFinised">
            <h2 v-if="!hasDiscount" class="f-l font-bold">价格 ￥{{allPrice | toPrice}}</h2>
            <div v-else class="f-l">
              <a-tag class="m-empty" color="black">{{div(discountRatio, 10)}} 折</a-tag>
              <h2 class="font-bold m-empty">价格 ￥{{mul(allPrice, div(discountRatio, 100)) | toPrice}}</h2>
              <span class="text-line-through">原价 ￥{{allPrice | toPrice}}</span>
            </div>
          </template>
        </div>

        <div class="f-1">
          <a-button
            class="m-right-sm"
            type="primary"
            size="large"
            @click="handleOk(false)"
            :loading="confirmLoading && submitingCart"
          >加入购物车</a-button>
          <a-button
            class="hidden-sm"
            type="primary"
            size="large"
            @click="handleOk(true)"
            :loading="confirmLoading && !submitingCart"
          >立即购买</a-button>
        </div>
      </div>
    </template>
  </ng-dialog>
</template>

<script>
  import {Tag} from 'ant-design-vue';
  import NgDialog from '@/components/NgDialog.vue'
  import CartDetail from '@/components/CartDetail.vue'
  import MusicPlayIcon from '@/pages/components/music/MusicPlayIcon.vue'
  import {dialogMixins} from '@/mixins/index';
  import {mul, div} from '@/utils/from-common';

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        loading: false,
        allPrice: '',
        submitingCart: false,
        allFieldFinised: false,
        hasDiscount: false,
        discountRatio: ''
      }
    },

    components: {
      CartDetail,
      NgDialog,
      MusicPlayIcon,
      ATag: Tag
    },

    props: {
      data: Object
    },

    mixins: [dialogMixins],

    methods: {
      mul, div,
      onChangeFinished(bool) {
        this.allFieldFinised = bool;
      },

      onChangePrice(price) {
        this.allPrice = price;
      },

      onChangeDiscount(scope) {
        this.hasDiscount = scope.discount_id;

        if (this.hasDiscount) {
          this.discountRatio = scope.ratio;
        }
      },

      handleOk(bool) {
        let cartDeail = this.$refs.cartDeail;
        this.submitingCart = !bool;

        if (!cartDeail) return;

        cartDeail.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            values.music_id = this.data.music_id
            values.price = this.allPrice;

            this.addToCart(values).then(() => {
              bool ? this.$router.push(`/cart`) : this.$message.success('已成功添加到购物车');
            });
          }
        });
      },

      addToCart(params) {
        this.confirmLoading = true;

        return new Promise((resolve, reject) => {
          this.$axios.AddToCart(params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.showCartDialog(false);
              this.$store.dispatch('updateGoodNumOfCart');

              resolve();
            } else {
              reject();
            }

            this.confirmLoading = false;
          }, reject)

        })
      },

      goBuy(params) {
        this.confirmLoading = true;

        this.$axios.AddToOrder(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let orderDetail = data.data

            this.showCartDialog(false);

            this.$router.push(`/paymusic/${orderDetail.order_id}`);
          }

          this.confirmLoading = false;
        })
      },

      showCartDialog(bool) {
        this.$store.commit('changeMusicDetailDialog', bool);
      },

      handleCancel() {
        this.showCartDialog(false);
      },

      closeModal() {
        this.showCartDialog(false);
      },

      onClose() {
        this.$store.commit('changeMusicDetailDialog', false);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .dialog-shop-cart {
    .header {
      .play-icon {
        width: 32px;
        height: 32px;
        margin-top: 6px;
      }
    }
  }
</style>
