<template>
  <div>
    <!-- <detail v-if="!isModifly" :data="detailData"></detail> -->

    <div class="form-wrapper">
      <modify-form :defaultValue="detailData" ref="formBox"></modify-form>
    </div>

    <a-button
      class="modifly-btn"
      type="primary"
      size="large"
      @click="handleBtn"
      :loading="confirmLoading"
    >{{$t('common.saveChange')}}</a-button>
  </div>
</template>

<script>
  // import {CommonPage} from '@/components/index';
  import ModifyForm from "./components/ModifyForm.vue";
  // import detail from "./components/detail.vue";

  export default {
    data() {
      return {
        isModifly: false,
        detailData: {},
        confirmLoading: false,
      };
    },
    components: {
      // CommonPage,
      // detail,
      ModifyForm,
    },
    computed: {
      // btnText() {
      //   return this.isModifly ? this.$t('common.save') : this.$t('common.change1');
      // },
    },

    created() {
      this.getData();
    },

    methods: {
      handleBtn() {
        this.$refs.formBox.form.validateFieldsAndScroll((err, values) => {

          if (!err) {
            this.submit(values);
          }
        });
      },

      submit(param) {
        this.confirmLoading = true;

        this.$axios.Base_editInfo(param).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(this.$t('userSet.basic_changeSuccess'));
            this.getData();
          }

          this.confirmLoading = false;
        })
      },

      getData() {
        this.$axios.Base_info().then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.detailData = data.data;
            this.$store.dispatch('updateUserInfo', this.detailData)
          }
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: $screen-width-md) {
    .form-wrapper {
      max-width: 530px;
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .modifly-btn {
      width: 100%;
    }
  }
</style>
