const Supplier = () => import('@/pages/supplier/Supplier.vue');
const SupplierInfo = () => import('@/pages/supplier/SupplierInfo.vue');
const AllMusic = () => import('@/pages/allMusic/AllMusic.vue');
const MusicFine = () => import('@/pages/home/Home.vue');
// const MusicFine = () => import('@/pages/musicFine/musicFine.vue');
const Package = () => import('@/pages/package/Package.vue');
const Playlist  = () => import('@/pages/songList/SongList.vue');
const SonglistDetail  = () => import('@/pages/songList/SonglistDetail.vue');
// const SongListPay = () => import('@/pages/songList/SongListPay.vue');

export default [
  {
    path: '/supplier',
    name: 'musicSuplier',
    component: Supplier
  },
  {
    path: '/supplierinfo/:user_id',
    name: 'supplierinfo',
    component: SupplierInfo
  },
  {
    path: '/allMusic',
    name: 'allMusic',
    component: AllMusic
  },
  {
    path: '/playlist',
    name: 'playlist',
    component: Playlist
  },
  {
    path: '/playListDetail/:playlist_id',
    name: 'playListDetail',
    component: SonglistDetail
  },
  {
    path: '/musicFine',
    name: 'musicFine',
    component: MusicFine
  },
  {
    path: '/package',
    name: 'package',
    component: Package
  },
  
  // {
  //   path: '/songlist/pay/:order_id',
  //   name: 'songlistpay',
  //   component: SongListPay
  // },
]