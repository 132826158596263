<template>
  <a-form
    :form="form"
    layout="horizontal"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    class="ng-form"
  >
    <a-form-item :label="$t('userSet.rpt_name')">
      <a-input
        size="large"
        :placeholder="$t('template.typeSth', {name: $t('userSet.rpt_name')})"
        v-decorator="['name', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>
  </a-form>
</template>

<script>

  export default {
    data() {
      return {
      }
    },

    props: {
      defaultData: {
        type: Object,
        default() {
          return {}
        }
      },
      wrapperCol: Object,
      labelCol: Object,
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'linkman'
      })
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
</style>
