import Vue from 'vue'
import store from './store/store'
import router from './router/router'
import i18n from './langs/index';

Vue.config.productionTip = false;

// const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)


// console.log('isMobile1: ', isMobile);
// if(isMobile){
require('./utils/rem.js')
// }

import './utils/comp'

import '@/scss/main.scss';
import '@/scss/ant.less';

import GlobalFilter from '~/filter/global'
import Filter from '@/filter/index.js'

Vue.use(GlobalFilter);
Vue.use(Filter);

// ============= plugins
import GlobalFunction from './plugins/globalFunction';
import GlobalVariables from './plugins/globalVariables';

Vue.use(GlobalFunction);
Vue.use(GlobalVariables);
// ============= plugins end

import * as RootFunc from '@/utils/rootFunc'
import * as $comm from '@/utils/from-common'
import * as $var from '@/utils/variables'

import App from './App.vue'
// import FastClick from 'fastclick'

import {
  ScreenWidthMd
} from '@/config/config';

// const ScreenWidth = window.innerWidth;

// // 移动端
// if (ScreenWidth < ScreenWidthMd) {
//   FastClick.attach(document.body);
// }

new Vue({
  render: h => h(App),
  data() {
    return {
      lang: this.$storage.get(this.$var.STORAGE.LANG),
      $transferData: {}, // 全局传送数据
      contentHeight: document.body.clientHeight,
      $commonSceneAndMode: [], // 全局通用授权场景和使用方式
      isMobile: false,
    }
  },
  i18n,
  store,
  router,
  created() {
    const ScreenWidth = window.innerWidth;

    if (ScreenWidth < ScreenWidthMd) {
      this.isMobile = true;
    }
  },
  methods: {
    ...RootFunc,

    // 查看登录状态
    // 一些操作执行前需要查看是否登录，如果没有登录那么需要跳转到登录页
    // 如：分享，下载，加入购物车...
    checkLoginStatus() {
      let login = this.$store.state.login;
      // let path = this.$route.path;

      if (!login) {
        // this.$router.replace(`/login?to=${path}`);
        this.$store.commit('changeLoginAndRegisterDialog', true);
      }

      return login;
    },

    getDefaultArray(num = 0, defaultValue = {}) {
      return (new Array(num)).fill('').map(() => (defaultValue))
    },

    // 获取 音乐供应商 展示名称
    getShowName(scope) {
      let type = scope.type;
      let {
        MUSICIAN,
        COMPANY,
        TEAM
      } = this.$val.SupplierTypeValue;

      return type === MUSICIAN ? scope.stage_name : type === COMPANY ? scope.foreign_name : type === TEAM ? scope.brand_name : scope.name;
    },

    // 跳转锚点
    jumpAnchor() {
      let query = this.$route.query;
      let anchor = query.anchor;

      if (anchor) {
        let ele = document.getElementById(anchor);

        if (ele && typeof ele.scrollIntoView === 'function') ele.scrollIntoView(true);
      }
    },

    /**
     * 
     * @param {*} scope 
     * @param {*} type 1 高品质  2 试听
     */
    // eslint-disable-next-line
    onDownLoadMusic(scope, type) {
      this.$message.success('下载音乐');
    },

    getCommonSceneAndMode() {
      let commonSceneAndMode = this.$commonSceneAndMode;

      return new Promise((resolve, reject) => {
        if (commonSceneAndMode) {
          resolve(this.$commonSceneAndMode);
        } else {
          this.$axios.GetSceneWay().then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.$commonSceneAndMode = data.data ? data.data.list : [];

              resolve(this.$commonSceneAndMode);
            } else {
              reject();
            }
          })
        }
      })
    }
  }
}).$mount('#app')

router.beforeEach((to, from, next) => {
  let token = $comm.$storage.get($var.STORAGE.TOKEN);

  if (to.meta && to.meta.isLogin && !token) {
    store.commit('changeLoginAndRegisterDialog', true);

    next(false);
  } else {
    next();
  }
})

router.afterEach(() => {
  if (document.documentElement) {
    document.documentElement.scrollTop = 0;
  } else {
    document.body.scrollTop = 0;
  }
})